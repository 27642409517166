<script setup>
import DOMPurify from 'dompurify';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  mode: {
    type: String,
    default: 'add',
    validator: value => ['add', 'edit'].includes(value),
  },
  label: {
    type: String,
  },
  view_data: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close']);

const $toast = inject('$toast');

const project_management_store = useProjectManagementStore();
const { active_view, is_fullscreen } = storeToRefs(project_management_store);
const { create_view, update_view, delete_view, set_view_dirtiness } = project_management_store;

const state = reactive({
  data: null,
  is_saving: false,
});

onMounted(() => {
  state.data = { label: props.label };
});

async function saveView() {
  state.is_saving = true;
  if (props.mode === 'add') {
    await create_view(DOMPurify.sanitize(state.data.label, { ALLOWED_TAGS: [] }));

    $toast({
      text: 'Custom view has been created successfully.',
      type: 'success', // one of 'success', 'warning', 'error', and 'info' (default 'info')
      title: 'Successfully created view', // (optional)
      timeout: 2000, // timeout in ms (default 2 seconds)
      has_close_button: true,
    });
  }
  else {
    let body;
    if (active_view.value.uid === props.view_data.uid)
      body = active_view.value;
    else
      body = props.view_data;
    body.data.label = DOMPurify.sanitize(state.data.label, { ALLOWED_TAGS: [] });
    await update_view(body);
    set_view_dirtiness(false);

    $toast({
      text: 'Custom view has been saved successfully.',
      type: 'success', // one of 'success', 'warning', 'error', and 'info' (default 'info')
      title: 'Successfully saved view', // (optional)
      timeout: 2000, // timeout in ms (default 2 seconds)
      has_close_button: true,
    });
  }
  state.is_saving = false;
  emit('close');
}

async function deleteView() {
  await delete_view(props.view_data.uid);
  emit('close');
}

function onCancel() {
  emit('close');
}
</script>

<template>
  <HawkModalContainer :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      v-model="state.data"
      sync
      size="sm"
      :columns="{
        default: {
          container: 12,
          label: 3,
          wrapper: 9,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :display-errors="false"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ props.mode === 'add' ? $t('Add View') : $t('Edit View') }}
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <TextElement
            name="label"
            :placeholder="$t('Enter view name')"
            :rules="['required']"
            :label="$t('Name')"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-between w-full col-span-full">
              <div
                v-if="props.mode === 'edit'"
                class="w-full"
              >
                <HawkButton type="plain" color="error" @click="deleteView">
                  {{ $t('Delete View') }}
                </HawkButton>
              </div>
              <div class="flex flex-row-reverse justify-start w-full col-span-full">
                <ButtonElement :loading="state.is_saving" :submits="true" name="save" @click="saveView">
                  {{ $t('Save') }}
                </ButtonElement>
                <ButtonElement name="cancel" class="mr-3" :secondary="true" @click="onCancel">
                  {{ $t('Cancel') }}
                </ButtonElement>
              </div>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
