import { inject, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18nStore } from '~/common/stores/i18n.store';
import { getGanttColumns } from '~/project-management/constants/pm-gantt-column';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

export function useI18n() {
  const $t = inject('$t');

  const i18n_store = useI18nStore();
  const project_management_store = useProjectManagementStore();

  const { current_lang } = storeToRefs(i18n_store);
  const { $g, active_schedule, is_schedule_editable, is_refresh_required } = storeToRefs(project_management_store);

  function getLocaleObj() {
    const columns = getGanttColumns($t, !is_schedule_editable.value, active_schedule.value.track_resources, active_schedule.value.track_costs);

    const column_names_map = {};

    for (const column of columns)
      column_names_map[`column_${column.name}`] = column.label;

    const date = {
      month_full: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ].map(text => $t(text)),
      month_short: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ].map(text => $t(text)),
      day_full: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ].map(text => $t(text)),
      day_short: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
      ].map(text => $t(text)),
    };

    const labels = {
      ...column_names_map,
      new_task: $t('New task'),
      icon_save: $t('Save'),
      icon_cancel: $t('Cancel'),
      icon_details: $t('Details'),
      icon_edit: $t('Edit'),
      icon_delete: $t('Delete'),
      gantt_save_btn: $t('Save'),
      gantt_cancel_btn: $t('Cancel'),
      gantt_delete_btn: $t('Delete'),
      confirm_closing: $t('Your changes will be lost, are you sure?'),
      confirm_deleting: $t('Task will be deleted permanently, are you sure?'),
      section_description: $t('Description'),
      section_time: $t('Time period'),
      section_type: $t('Type'),
      section_resources: $t('Resources'),

      link: $t('Link'),
      confirm_link_deleting: $t('will be deleted'),
      link_start: ` (${$t('start')})`,
      link_end: ` (${$t('end')})`,

      type_task: $t('Task'),
      type_wbs: $t('WBS'),
      type_activity: $t('Activity'),
      type_project: $t('Project'),
      type_milestone: $t('Milestone'),

      minutes: $t('Minutes'),
      hours: $t('Hours'),
      days: $t('Days'),
      weeks: $t('Week'),
      months: $t('Months'),
      years: $t('Years'),

      message_ok: $t('OK'),
      message_cancel: $t('Cancel'),

      section_parent: $t('Parent'),
      section_constraint: $t('Constraint'),
      constraint_type: $t('Constraint type'),
      constraint_date: $t('Constraint date'),
      asap: $t('As Soon As Possible'),
      alap: $t('As Late As Possible'),
      snet: $t('Start No Earlier Than'),
      snlt: $t('Start No Later Than'),
      fnet: $t('Finish No Earlier Than'),
      fnlt: $t('Finish No Later Than'),
      mso: $t('Must Start On'),
      mfo: $t('Must Finish On'),

      /* resource control */
      resources_filter_placeholder: $t('Type to filter'),
      resources_filter_label: $t('Hide empty'),
    };

    return {
      date,
      labels,
    };
  }

  function setupLocale() {
    const obj = getLocaleObj();
    $g.value.i18n.setLocale(obj);
  }

  watch(current_lang, () => {
    window.SH_DEBUG && logger.info('[DEBUG] pm-i18n.composable.js::141\nLanguage change detected. Reloading...');
    is_refresh_required.value = true;
  });

  return {
    setupLocale,
  };
}
