<script setup>
import RichTextIcon from '~icons/solar/document-text-linear';

const props = defineProps({
  task_uid: {
    type: String,
    required: true,
  },
  fields_list: {
    type: Array,
    default: () => [],
  },
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  attachment_config: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close']);

const form = ref({});
const form$ = ref({});
const uploading = ref(null);

const state = reactive({
  is_submitting: false,
  is_upload_enabled: false,
});

function onFormMounted() {
  form$.value.elements$.File.input.click();
}

function onFormUpdated(event) {
  if (event.type === 'upload_started') {
    uploading.value = true;
    state.is_upload_enabled = false;
  }
  else if (event.type === 'upload_completed') {
    uploading.value = false;
    state.is_upload_enabled = true;
  }
}

function cancelUpload() {
  form$.value.uppy.cancelAll();
  emit('close');
}

async function onSubmit() {
  state.is_submitting = true;
  await props.submit(form$.value);
  state.is_submitting = false;
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false"
      :should_validate_on_mount="false"
      :attachment_config="props.attachment_config || { meta: { service: 'tickets', id: task_uid } }"
      :presets="['disable_error_messages']"
      :loading="uploading"
      @mounted="onFormMounted"
      @updated="onFormUpdated"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #icon>
            <RichTextIcon />
          </template>
          <template #title>
            {{ $t("File Upload") }}
          </template>
          <template #subtitle>
            <div class="text-gray-600">
              {{ $t("files-uploaded-to-project") }}
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content class="!py-0">
          <component
            :is="component.component" v-for="component in fields_list" :key="component.name" class="pt-4 mb-8"
            v-bind="component"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <!-- Footer -->
          <template #right>
            <div class="flex justify-end w-full">
              <HawkButton type="outlined" text="Cancel" class="mr-2" @click="cancelUpload">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement
                size="sm"
                name="submit"
                :button-label="$t('Upload')"
                :loading="state.is_submitting"
                :disabled="!state.is_upload_enabled"
                button-class="vf-btn-primary"
                @click="onSubmit"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
