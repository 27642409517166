<script setup>
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import PmRenameModal from '~/project-management/components/menus/pm-rename-modal.vue';
import PmScheduleSettingsModal from '~/project-management/components/pm-schedule-settings-modal.vue';

const $t = inject('$t');
const $toast = inject('$toast');
const $date = inject('$date');

const router = useRouter();
const route = useRoute();
const auth_store = useAuthStore();
const dashboard_store = useDashboardStore();
const project_management_store = useProjectManagementStore();

const { create_schedule, delete_schedule, v2_set_schedules } = project_management_store;
const { schedules } = storeToRefs(project_management_store);
const { current_organization } = storeToRefs(auth_store);

const rename_modal = useModal({
  component: PmRenameModal,
  attrs: {
    onClose() {
      rename_modal.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
});

const form$ = ref(null);

const state = reactive({
  is_loading: false,
  search: '',
});

const filtered_schedules = computed(() => {
  const searched_schedules = [];
  schedules.value.forEach((x) => {
    if (x.name.toLowerCase().includes(state.search.toLowerCase()))
      searched_schedules.push(x);
  });
  return searched_schedules;
});

const is_boarding = computed(() => !schedules.value.length && !filtered_schedules?.length);

watch(() => [current_organization.value?.uid, router?.currentRoute.value.params?.asset_id], async () => {
  if (!current_organization.value?.uid || state.is_loading)
    return;
  try {
    state.is_loading = true;
    await dashboard_store.set_fusion_chart();
    await v2_set_schedules({
      asset: router?.currentRoute.value.params?.asset_id,
    });
    state.is_loading = false;
  }
  catch (error) {
    logger.error(error);
  }
}, { immediate: true });

function progress_success_width(planned, current) {
  return {
    width:
      planned < current
        ? `${planned * 100}%`
        : `${current * 100}%`,
  };
}

function progress_width(planned, current) {
  return {
    width:
      planned > current
        ? `${planned * 100 - current * 100}%`
        : `${current * 100 - planned * 100}%`,
  };
}

const schedule_settings_modal = useModal({
  component: PmScheduleSettingsModal,
  attrs: {
    onClose() {
      schedule_settings_modal.close();
    },
  },
});

function getScheduleData(uid) {
  return {
    data: {
      name: 'S curve',
      type: 's_curve',
      schedule: uid,
      s_curve_type: 'progress',
      s_curve_interval: 'month',
      chart_type: null,
      chart_field: null,
      chart_value: null,
      chart_breakdown: null,
      number_chart_field: null,
      module: 'project management',
    },
  };
}

function getPIData(uid, type) {
  return {
    data: {
      name: null,
      type,
      schedule: uid,
      s_curve_type: null,
      s_curve_interval: null,
      chart_type: null,
      chart_field: null,
      chart_value: null,
      chart_breakdown: null,
      history_compare: null,
      history_duration: 1,
      number_chart_field: null,
      module: 'project management',
    },
  };
}

function onRowClicked(schedule) {
  router.push({ name: 'schedule-details', params: { schedule_uid: schedule.uid } });
}

function handleRename(schedule) {
  rename_modal.patchOptions({
    attrs: {
      active_schedule: schedule,
    },
  });
  rename_modal.open();
}

function handleDelete(schedule) {
  delete_popup.patchOptions(
    {
      attrs: {
        header: $t('Delete'),
        content: `${$t('Are you sure you want to delete this schedule')}?`,
        match_text: `${schedule.name || ''}`,
        match_text_input_placeholder: `${$t('Enter the name of the schedule to delete')}`,
        button_text: $t('Delete'),
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          try {
            await delete_schedule(schedule.uid);
            delete_popup.close();
          }
          catch (err) {
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
            });
          }
        },
      },
    },
  );
  delete_popup.open();
}

onMounted(() => {
  project_management_store.$reset();
});
</script>

<template>
  <div>
    <HawkPageHeader
      :title="`${$t('Project management')} - ${$t('Schedules')}`"
      :display_onboarding="{ for: 'project-management' }"
    />
    <Vueform ref="form$" size="sm" class="px-4 pb-5">
      <div class="flex w-full col-span-full" :class="[is_boarding ? 'justify-end' : 'justify-between']">
        <HawkSearchInput v-if="!is_boarding" v-model="state.search" :placeholder="$t('Search')" />
        <!-- TODO: test in Safari -->
        <ButtonElement
          name="new_schedule"
          @click="router.push({ name: 'new-schedule' })"
        >
          <IconHawkPlus class="w-5 h-5 text-white inline -mt-0.5 mr-2" />
          <span class="w-full">{{ $t('New Schedule') }}</span>
        </ButtonElement>
      </div>
    </Vueform>
    <HawkLoader v-if="state.is_loading" />
    <div v-else-if="is_boarding">
      <hawk-illustrations type="on-boarding" for="project-management" />
    </div>
    <div v-else-if="!filtered_schedules?.length">
      <hawk-illustrations type="no-results" />
    </div>
    <div v-else-if="filtered_schedules?.length">
      <HawkTable
        :data="filtered_schedules"
        :columns="[
          {
            header: $t('Name'),
            accessorKey: 'name',
            id: 'name',
            cell: info => info.getValue(),
          },
          {
            header: $t('Milestones'),
            accessorKey: 'milestones',
            id: 'milestones',
            cell: info => info.getValue(),
          },
          {
            header: $t('Progress'),
            accessorKey: 'progress',
            id: 'progress',
            cell: info => info.getValue(),
          },
          {
            header: $t('Last updated'),
            accessorKey: 'updated_at',
            id: 'updated_at',
            cell: info => info.getValue(),
          },
          {
            accessorKey: 'context_menu',
            header: '',
            id: 'context_menu',
            size: '5',
            show_on_hover: 'true',
          },
        ]"
        :is_gapless="true"
        :non_sortable_columns="['milestones', 'context_menu']"
        :show_menu_header="false"
        :disable_resize="true"
        cell_height="48px"
        class="px-4"
        additional_table_classes="shadow-sm"
        @row-clicked="$event => onRowClicked($event)"
      >
        <template #name="data">
          <span class="text-sm font-medium text-gray-900">{{ data.data.row.original.name }}</span>
        </template>
        <template #milestones="data">
          <IconHawkTrophyFilled class="inline mr-1 text-warning-400" />
          <span class="text-sm font-medium text-gray-900">
            {{ data.data.row.original.scheduled_milestones || 0 }}/{{ data.data.row.original.total_milestones || 0 }}
          </span>
        </template>
        <template #progress="data">
          <div class="inline-flex items-start bg-gray-300 rounded w-[200px] overflow-hidden">
            <div
              class="h-[8px] bg-primary-500 rounded-l"
              :style="progress_success_width(data.data.row.original.percent_schedule_complete, data.data.row.original.progress)"
            />
            <div
              class="h-[8px] rounded-r"
              :class="{
                'rounded-l': progress_success_width(data.data.row.original.percent_schedule_complete, data.data.row.original.progress).width === '0%',
                'bg-error-500': data.data.row.original.percent_schedule_complete > data.data.row.original.progress,
                'bg-primary-500': data.data.row.original.percent_schedule_complete <= data.data.row.original.progress,
              }"
              :style="progress_width(data.data.row.original.percent_schedule_complete, data.data.row.original.progress)"
            />
          </div>
          <div class="text-sm font-medium text-gray-900 mx-2 w-[38px] text-center">
            {{ Math.round((data.data.row.original.progress || 0) * 100) }}%
          </div>
          <HawkBadge v-if="data.data.row.original.progress < data.data.row.original.percent_schedule_complete" color="yellow">
            {{ $t("Delayed by") }} {{ Math.round((data.data.row.original.percent_schedule_complete - data.data.row.original.progress || 0) * 100) }}%
          </HawkBadge>
          <HawkBadge v-if="data.data.row.original.progress >= data.data.row.original.percent_schedule_complete" color="blue">
            {{ $t("On track") }}
          </HawkBadge>
        </template>
        <template #updated_at="data">
          <div class="text-sm font-normal text-gray-600">
            {{ $date(data.data.row.original.updated_at, 'MMM D, YYYY hh:mma') }}
          </div>
        </template>
        <template #context_menu="data">
          <HawkMenu
            :items="[
              {
                label: $t('Rename'),
                value: 'rename',
                on_click: () => {
                  handleRename(data.data.row.original);
                },
              },
              {
                label: $t('Delete'),
                value: 'delete',
                on_click: () => {
                  handleDelete(data.data.row.original);
                },
              },
            ]"
            additional_trigger_classes="!ring-0 !flex !items-center"
            @click.stop=""
          >
            <template #trigger>
              <IconHawkDotsVertical class="flex items-center text-gray-600" />
            </template>
          </HawkMenu>
        </template>
      </HawkTable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep() {
  th {
    @apply text-xs font-medium text-gray-600;
  }
}

:deep() .no_pagination :last-child  tr:last-child {
  th:first-child,td:first-child{
    border-bottom-left-radius: 8px;
  }
  th:last-child,td:last-child {
    border-bottom-right-radius: 8px;
  }
}
</style>
