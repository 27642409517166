<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import { getCookie } from '~/common/utils/common.utils';
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import PmLeaveModal from '~/project-management/components/pm-leave-modal.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import PmExportModal from '~/project-management/components/menus/pm-export-modal.vue';
import PmSyncNowModal from '~/project-management/components/menus/pm-sync-now-modal.vue';
import PmVersionsModal from '~/project-management/components/menus/pm-versions-modal.vue';
import PmShortcutsModal from '~/project-management/components/menus/pm-shortcuts-modal.vue';
import PmSyncHistoryModal from '~/project-management/components/menus/pm-sync-history-modal.vue';
import PmScheduleSettingsModal from '~/project-management/components/pm-schedule-settings-modal.vue';
import PmConfigureAutoSyncModal from '~/project-management/components/menus/pm-configure-auto-sync-modal.vue';
import PmEditView from '~/project-management/components/pm-edit-view.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useExportProgressReport } from '~/project-management/composables/pm-export-progress-report.composable';

const $t = inject('$t');
const $date = inject('$date');
const $toast = inject('$toast');
const router = useRouter();

const project_management_store = useProjectManagementStore();
const common_store = useCommonStore();

const {
  $g,
  flags,
  active_tab,
  active_view,
  is_pm_loading,
  view_dirtiness,
  active_schedule,
  schedule_dirtiness,
  is_refresh_required,
  is_schedule_editable,
} = storeToRefs(project_management_store);

const {
  update_view,
  delete_schedule,
  publish_schedule,
  set_view_dirtiness,
  unpublish_schedule,
  save_active_schedule,
  set_schedule_dirtiness,
  update_active_schedule,
} = project_management_store;

const {
  setExporting,
  is_report_exporting,
  exportScheduleReports,
  cancelExportingScheduleReports,
} = useExportProgressReport();

const state = reactive({
  is_saving: false,
  calendar_type: 'regular',
});

const leave_modal = useModal({
  component: PmLeaveModal,
  attrs: {
    onClose() {
      leave_modal.close();
      router.push({ name: 'project-management' });
    },
    save: onSave,
  },
});

const auto_sync_modal = useModal({
  component: PmConfigureAutoSyncModal,
  attrs: {
    onClose() {
      auto_sync_modal.close();
    },
  },
});

const sync_now_modal = useModal({
  component: PmSyncNowModal,
  attrs: {
    onClose() {
      sync_now_modal.close();
    },
  },
});

const sync_history_modal = useModal({
  component: PmSyncHistoryModal,
  attrs: {
    onClose() {
      sync_history_modal.close();
    },
  },
});

const schedule_settings_modal = useModal({
  component: PmScheduleSettingsModal,
  attrs: {
    mode: 'edit',
    onClose() {
      schedule_settings_modal.close();
    },
  },
});

const versions_modal = useModal({
  component: PmVersionsModal,
  attrs: {
    onClose() {
      versions_modal.close();
    },
  },
});

const share_modal = useModal({
  component: HawkShare,
});

const export_modal = useModal({
  component: PmExportModal,
  attrs: {
    onClose() {
      export_modal.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
});

const pm_shortcuts_modal = useModal({
  component: PmShortcutsModal,
  attrs: {
    onClose() {
      pm_shortcuts_modal.close();
    },
  },
});

const new_view_modal = useModal({
  component: PmEditView,
  attrs: {
    onClose() {
      new_view_modal.close();
    },
  },
});

const menu_items = computed(() => {
  const items = [
    [
      'sync_progress',
      IconHawkRefreshCcwFive,
      'Sync progress',
      null,
      [
        [
          'sync_now',
          IconHawkZap,
          'Sync Now',
          () => {
            sync_now_modal.open();
          },
        ],
        [
          'configure_auto_sync',
          IconHawkToolOne,
          'Configure auto sync',
          () => {
            auto_sync_modal.open();
          },
        ],
        [
          'history',
          IconHawkClockFastForward,
          'History',
          () => {
            sync_history_modal.open();
          },
        ],
      ].map((item) => {
        return {
          name: item[0],
          left_icon: item[1],
          label: $t(item[2]),
          on_click: item[3],
        };
      }),
    ],
    [
      'export',
      IconHawkDownloadOne,
      'Export',
      null,
      [
        [
          'export_schedule',
          IconHawkFileDownloadTwo,
          'Schedule',
          () => export_modal.open(),
        ],
        [
          'export_progress_history',
          IconHawkBarChartSquareDown,
          'Progress history',
          () => setExporting(true),
        ],
      ].map((item) => {
        return {
          name: item[0],
          left_icon: item[1],
          label: $t(item[2]),
          on_click: item[3],
        };
      }),
    ],
    [
      'versions',
      IconHawkUserOne,
      'Versions',
      () => versions_modal.open(),
      [],
    ],
    [
      'share',
      IconHawkShareSeven,
      'Share',
      () => openShareModal(),
    ],
    [
      'unpublish',
      IconHawkRollback,
      'Unpublish',
      () => onSubmit('unpublish'),
    ],
    [
      'delete',
      IconHawkTrashThree,
      'Delete',
      () => scheduleDeleteHandler(),
    ],
    [
      'keyboard_shortcuts',
      IconHawkKeyMac,
      'Keyboard Shortcuts',
      () => pm_shortcuts_modal.open(),
    ],
  ];

  let filtered_items;

  if (is_schedule_editable.value)
    filtered_items = items.filter(i => ['edit_schedule_details', 'share', 'delete', 'keyboard_shortcuts'].includes(i[0]));

  else
    filtered_items = items.filter(i => !['keyboard_shortcuts'].includes(i[0]));

  if (active_schedule.value.is_manual)
    filtered_items = items.filter(i => !['versions'].includes(i[0]));

  return filtered_items.map((item) => {
    return {
      name: item[0],
      left_icon: item[1],
      label: $t(item[2]),
      on_click: item[3],
      submenu_items: item?.[4],
      disabled: !!item?.[5],
    };
  });
});

const users_and_teams = computed(() => {
  const users = [];
  const teams = [];
  const all_members = Object.entries(active_schedule.value?.members);
  all_members.forEach((element) => {
    const new_el = {
      uid: element[0],
      access: element[1],
    };
    const teams_uids = Object.keys(common_store.teams_map);
    if (teams_uids?.includes(new_el.uid))
      teams.push(new_el);
    else users.push(new_el);
  });

  return {
    users,
    teams,
  };
});

function scheduleDeleteHandler() {
  delete_popup.patchOptions(
    {
      attrs: {
        header: $t('Delete'),
        content: `${$t('Are you sure you want to delete this schedule')}?`,
        match_text: `${active_schedule.value.name || ''}`,
        match_text_input_placeholder: `${$t('Enter the name of the schedule to delete')}`,
        button_text: $t('Delete'),
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          try {
            const active_schedule_uid = active_schedule.value.uid;
            await delete_schedule(active_schedule_uid);
            for (const key of Object.keys(common_store.assets_custom_fields_map))
              if (common_store.assets_custom_fields_map[key].properties.schedule_uid === active_schedule_uid) {
                delete common_store.assets_custom_fields_map[key].properties.schedule_uid;
                delete common_store.assets_custom_fields_map[key].properties.activity_uid;
              }
            delete_popup.close();
            router.push({ name: 'project-management' });
          }
          catch (err) {
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
            });
          }
        },
      },
    },
  );
  delete_popup.open();
}

async function onShare(data) {
  const members = {};
  let public_access = false;
  if (data.global_access_level === 'read')
    public_access = true;
  data.users.forEach((element) => {
    members[element.uid] = element.access;
  });
  data.teams.forEach((element) => {
    members[element.uid] = element.access;
  });

  await update_active_schedule(
    active_schedule.value.uid,
    {
      members,
      public: public_access,
    },
    true,
  );
}

async function toggleDynamicness() {
  await update_active_schedule(
    active_schedule.value.uid,
    {
      is_dynamic_loading: !active_schedule.value.is_dynamic_loading,
    },
    true,
  );
}

function openShareModal() {
  let global_access_level;
  if (active_schedule.value?.public)
    global_access_level = 'read';

  share_modal.patchOptions({
    attrs: {
      onClose() {
        share_modal.close();
      },
      is_modal: true,
      is_global_access_visible: true,
      owner_uid: active_schedule.value?.owner?.uid,
      teams: users_and_teams.value.teams,
      members: users_and_teams.value.users,
      global_access_level,
      global_access_levels: [
        { name: 'no_access', label: $t('No access') },
        { name: 'read', label: $t('Can view'), description: $t('Can view and download this file') },
      ],
      access_levels: [
        { name: 'read', label: $t('Can view'), description: $t('Can view and download this file') },
        { name: 'manage', label: $t('Can manage'), description: 'TODO: Placeholder text' },
        // $t('Can edit, publish, unpublish, modify activities and manage the schedule')
        { name: 'write', label: $t('Can edit'), description: $t('pm-hawk-share-edit-description') },
      ],
      get_share_data(data) {
        onShare(data);
      },
    },
  });
  share_modal.open();
}

async function onSubmit(type = 'publish') {
  if (state.is_saving)
    return;
  try {
    is_pm_loading.value = true;
    state.is_saving = true;
    if (type === 'publish')
      await publish_schedule();
    else if (type === 'unpublish')
      await unpublish_schedule();
    router.go();
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: error.data.title ?? $t('Something went wrong'),
      text: error.data.description ?? $t('Please try again'),
      type: 'error',
      timeout: 4000,
    });
  }
  finally {
    state.is_saving = false;
    is_pm_loading.value = false;
  }
}

async function onSave() {
  if (state.is_saving)
    return;
  is_pm_loading.value = true;
  state.is_saving = true;
  const activities = [];
  $g.value.eachTask((task) => {
    task.is_backend_save_pending = false;
    if ([
      $g.value.config.types.project,
      $g.value.config.types.task,
      $g.value.config.types.milestone,
    ].includes(task.type))
      activities.push({
        ...task,
        name: task.text,
        weight: (task.weight || 0) / 100,
        planned_start: dayjs(task.start_date).format('YYYY-MM-DD'),
        planned_finish: !dayjs(task.start_date).isSame(dayjs(task.end_date), 'day') ? dayjs(task.end_date).subtract(1, 'day').format('YYYY-MM-DD') : dayjs(task.end_date).format('YYYY-MM-DD'),
        planned_duration: task.duration,
        is_critical: task.progress !== 1 && ($g.value.isCriticalTask(task) || active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false),
        free_slack: $g.value.getFreeSlack(task),
        total_slack: $g.value.getTotalSlack(task),
        type: (task.type === 'PROJECT' && !task.is_root)
          ? $g.value.config.types.wbs
          : task.type,
      });
  });

  await save_active_schedule({
    activities,
    relations: $g.value.getLinks(),
  });
  is_pm_loading.value = false;
  state.is_saving = false;
  set_schedule_dirtiness(false);
}

async function updateView() {
  if (active_view.value?.uid === '__default') {
    new_view_modal.patchOptions({ attrs: { mode: 'add' } });
    new_view_modal.open();
  }
  else {
    state.is_saving = true;
    await update_view(active_view.value);
    state.is_saving = false;

    $toast({
      text: 'Custom view has been saved successfully.',
      type: 'success', // one of 'success', 'warning', 'error', and 'info' (default 'info')
      title: 'Successfully saved view', // (optional)
      timeout: 2000, // timeout in ms (default 2 seconds)
      has_close_button: true,
    });
  }

  set_view_dirtiness(false);
}

function backToScheduleList() {
  if (is_schedule_editable.value && schedule_dirtiness.value)
    leave_modal.open();
  else
    router.push({ name: 'project-management' });
}
</script>

<template>
  <HawkExportToast
    v-if="is_report_exporting"
    :submit="exportScheduleReports"
    :progress_text="$t('Exporting progress report')"
    :completed_text="$t('Progress report exported successfully')"
    @cancel="cancelExportingScheduleReports"
    @close="() => setExporting(false)"
  />
  <div class="flex items-center justify-between px-4 pt-2.5 pb-1.5">
    <div class="flex items-center">
      <HawkButton
        icon
        type="text"
        @click="backToScheduleList"
      >
        <IconHawkChevronLeft class="w-5 h-5" />
      </HawkButton>
      <div class="flex flex-col ml-2">
        <div class="flex items-center font-semibold text-md">
          <span class="mr-2">{{ active_schedule.name }}</span>
          <HawkButton v-if="getCookie('__PM_ENABLE_TOGGLE_BUTTON_FOR_DYNAMIC_LOADING')" size="xs" type="outlined" class="ml-4" @click="toggleDynamicness">
            Set as {{ active_schedule?.is_dynamic_loading ? 'not dynamic' : 'dynamic' }}
          </HawkButton>
        </div>
        <div class="flex items-center gap-2 text-xs">
          <div class="text-xs font-normal text-gray-900">
            <span class="text-gray-500">
              {{ $t('Last modified') }}:
            </span>
            <span class="text-gray-600">
              {{ $date(active_schedule.updated_at, 'MMM D, YYYY, h:mm a') }}
            </span>
          </div>
          <div v-if="is_schedule_editable && schedule_dirtiness" class="flex items-center gap-2 text-xs font-medium">
            •
            <IconHawkAlertTriangle class="!w-4 !h-4 text-warning-600" />
            <span class="text-warning-700">
              {{ $t('Unpublished schedule') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <template v-if="is_schedule_editable && !flags.hide_save_and_submit && active_tab === 'gantt-chart'">
        <HawkBadge v-if="schedule_dirtiness" size="lg" color="blue" :custom_color_background_opacity="1" custom_classes="!h-9 !pl-3.5 !pr-1.5" class="mr-2">
          <IconHawkSaveOne class="!w-4 !h-4 text-primary-600" />
          <div class="text-sm font-medium text-primary-700 mx-1">
            {{ $t('You have unsaved changes') }}
          </div>
          <HawkButton
            size="xxs"
            class="!p-3"
            :rounded="true"
            :loading="state.is_saving"
            @click="onSave"
          >
            {{ $t('Save') }}
          </HawkButton>
        </HawkBadge>
        <HawkBadge v-else size="lg" custom_color="#FFFAEB" :custom_color_background_opacity="1" custom_classes="!h-9 !pl-3.5 !pr-1.5" class="mr-2">
          <IconHawkAlertTriangle class="!w-4 !h-4 text-warning-600" />
          <div class="text-sm font-medium text-warning-700 mx-1">
            {{ $t('Schedule is not published') }}
          </div>
          <HawkButton
            size="xxs"
            class="!p-3"
            color="warning"
            :rounded="true"
            :loading="state.is_saving"
            @click="onSubmit('publish')"
          >
            {{ $t('Publish') }}
          </HawkButton>
        </HawkBadge>
      </template>
      <HawkBadge v-if="!is_schedule_editable && active_tab === 'gantt-chart' && view_dirtiness" size="lg" color="blue" :custom_color_background_opacity="1" custom_classes="!h-9 !pl-3.5 !pr-1.5" class="mr-2">
        <IconHawkSaveOne class="!w-4 !h-4 text-primary-600" />
        <div class="text-sm font-medium text-primary-700 mx-1">
          <template v-if="active_view?.uid === '__default'">
            {{ $t('Your view has been modified') }}
          </template>
          <template v-else>
            {{ $t('This view has unsaved changes') }}
          </template>
        </div>
        <HawkButton
          size="xxs"
          class="!p-3"
          :rounded="true"
          :loading="state.is_saving"
          @click="updateView"
        >
          <template v-if="active_view?.uid === '__default'">
            {{ $t('Create view') }}
          </template>
          <template v-else>
            {{ $t('Save view') }}
          </template>
        </HawkButton>
      </HawkBadge>
      <div class="cursor-pointer" @click="openShareModal">
        <div v-if="Object.values(active_schedule.members).length">
          <HawkMembers
            :members="users_and_teams.users.concat(users_and_teams.teams)"
            :max_badges_to_display="5"
            :has_avatar="true"
            popover_position="right"
            size="xs"
            type="group"
          />
        </div>
        <HawkButton v-else type="outlined">
          <IconHawkShareSeven />
          {{ $t("Share") }}
        </HawkButton>
      </div>
      <HawkMenu
        v-if="active_schedule.can_modify"
        :items="menu_items"
        class="ml-2"
        additional_trigger_classes="ring-gray-100"
        additional_dropdown_classes="!mt-1"
        additional_item_classes="w-[208px] h-10 hover:bg-gray-50 cursor-pointer rounded-lg !px-2 mb-1"
      >
        <template #trigger>
          <HawkButton icon type="outlined">
            <IconHawkDotsVertical class="w-5 h-5" />
          </HawkButton>
        </template>
        <template #item="{ item }">
          <div v-if="!['sync_progress', 'export'].includes(item.name)" class="flex items-center text-sm font-medium text-gray-700">
            <component :is="item.left_icon" class="inline mr-2 text-gray-500" />
            {{ item.label }}
          </div>
          <HawkMenu
            v-else
            :items="item.submenu_items"
            class="flex items-center w-full"
            additional_trigger_classes="w-full !ring-0"
            additional_dropdown_classes="right-[108%] !-top-[11px] !bottom-auto"
            additional_item_classes="w-[232px] h-10 hover:bg-gray-50 cursor-pointer rounded-lg !px-2 mb-1"
            position="bottom-left"
          >
            <template #trigger>
              <div class="flex justify-between w-full py-3">
                <div class="flex items-center text-sm font-medium text-gray-700">
                  <component :is="item.left_icon" class="inline mr-2 text-gray-500" />
                  {{ item.label }}
                </div>
                <IconHawkChevronRight class="text-gray-600" />
              </div>
            </template>
            <template #item="{ item: sync_item }">
              <div class="flex items-center text-sm font-medium text-gray-700" :class="{ 'pointer-events-none': item.disabled }">
                <component :is="sync_item.left_icon" class="inline mr-2 text-gray-500" />
                {{ sync_item.label }}
              </div>
            </template>
          </HawkMenu>
        </template>
      </HawkMenu>
    </div>
  </div>
</template>
