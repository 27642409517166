import { storeToRefs } from 'pinia';
import { watchDebounced } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

export function useSearch() {
  const project_management_store = useProjectManagementStore();

  const { set_search_config, search_tasks, set_active_task_uid, set_all_tasks_open_state } = project_management_store;
  const { $g, flags, active_schedule, is_searching, search_config, active_schedule_data } = storeToRefs(project_management_store);

  const search_string = computed(() => search_config.value.search_string);
  const current_match_index = computed(() => search_config.value.current_match_index);

  const search_results = ref([]);
  const parents_of_search_results = ref([]);

  watchDebounced(
    search_string,
    async (value) => {
      set_active_task_uid(null);
      set_all_tasks_open_state(true);
      search_results.value = [];
      parents_of_search_results.value = [];

      const search_string_lower_case = value.trim().toLowerCase();

      if (search_string_lower_case && active_schedule.value?.is_dynamic_loading) {
        is_searching.value = true;
        await search_tasks(search_string_lower_case);
        $g.value.clearAll();
        flags.value.set_wbs_to_max = true;
        flags.value.expand_all_after_parse = true;
        $g.value.parse(active_schedule_data.value);
        flags.value.resources_section_reload_count++;
        is_searching.value = false;
      }

      if (search_string_lower_case)
        $g.value.eachTask((task) => {
          if (!task?.text)
            return;
          if (task.text.toLowerCase().includes(search_string_lower_case) || task.id.toLowerCase().includes(search_string_lower_case)) {
            search_results.value.push(task.id);
            $g.value.eachParent((task) => {
              parents_of_search_results.value.push(task.id);
            }, task.id);
          }
        });

      const match_index = search_results.value.length ? 0 : -1;

      set_search_config({
        current_match_index: match_index,
        match_count: search_results.value.length,
        search_results: search_results.value,
      });
      $g.value.refreshData();

      if (match_index >= 0)
        $g.value.showTask(search_results.value?.[match_index]);
    },
    { debounce: 500 },
  );

  watch(current_match_index, (value) => {
    if (value >= 0)
      $g.value.showTask(search_results.value?.[value]);
  });

  return {
    search_results,
    parents_of_search_results,
    search_string,
    current_match_index,
  };
}
