<script setup>
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import PmNewResourceModal from '~/project-management/components/pm-new-resource-modal.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { getUserFullName } from '~/common/utils/common.utils';

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { is_type_team } = common_store;
const { v2_save_resources } = project_management_store;
const { active_schedule } = storeToRefs(project_management_store);

const new_resource_modal = useModal({
  component: PmNewResourceModal,
  attrs: {
    onClose: () => {
      new_resource_modal.close();
    },
  },
});

const table_columns = computed(() => {
  return [
    {
      header: $t('Resource'),
      accessorKey: 'resource',
      id: 'resource',
      cell: info => info.getValue(),
    },
    {
      header: $t('Type'),
      accessorKey: 'type',
      id: 'type',
      cell: info => info.getValue(),
    },
    {
      header: $t('Access level'),
      accessorKey: 'access_level',
      id: 'access_level',
      cell: info => info.getValue(),
    },
    ...(active_schedule.value.track_costs
      ? [
          {
            header: $t('Cost'),
            accessorKey: 'cost',
            id: 'cost',
            cell: info => info.getValue(),
          },
          {
            // TODO: i18n
            header: 'Unit',
            accessorKey: 'unit',
            id: 'unit',
            cell: info => info.getValue(),
          },
        ]
      : []),
    {
      accessorKey: 'context_menu',
      header: '',
      id: 'context_menu',
      size: '5',
      show_on_hover: 'true',
    },
  ];
});

const delete_popup = useModal({
  component: HawkDeletePopup,
});

const state = reactive({
  active_item: 'members',
  resource_members: [],
  is_saving: false,
  reload_count: 0,
});

function onAddResources() {
  new_resource_modal.patchOptions({
    attrs: {
      mode: 'create',
      prefill_data: {},
      existing_items: cloneDeep(active_schedule.value.resources).filter(member => member.type === 'member').map(item => item.external_id),
      save: async (payload, add_another) => {
        const added_resource_payload = {
          ...(payload.resource_name?.name === payload.resource_name?.uid ? { name: payload.resource_name.name } : {}),
          ...(payload.resource_name?.name !== payload.resource_name?.uid ? { external_id: payload.resource_name.uid } : {}),
          type: payload.resource_name?.name === payload.resource_name?.uid ? 'custom' : 'member',
          cost: Number.parseFloat(payload.cost),
          cost_type: payload.cost_type,
        };
        await v2_save_resources([added_resource_payload], [], []);
        if (!add_another)
          new_resource_modal.close();
      },
    },
  });
  new_resource_modal.open();
}

function onEditResource(data) {
  const prefill_data = {
    resource_name: {
      name: data.type === 'custom' ? data.name : getUserFullName(data.external_id),
      uid: data.type === 'custom' ? data.name : data.external_id,
    },
    cost: data.cost,
    cost_type: data.cost_type,
  };
  new_resource_modal.patchOptions({
    attrs: {
      mode: 'edit',
      prefill_data,
      existing_items: [],
      onSave: async (payload) => {
        const updated_resource_payload = {
          uid: data.uid,
          ...(payload.resource_name?.name === payload.resource_name?.uid ? { name: payload.resource_name.name } : {}),
          ...(payload.resource_name?.name !== payload.resource_name?.uid ? { external_id: payload.resource_name.uid } : {}),
          type: payload.resource_name?.name === payload.resource_name?.uid ? 'custom' : 'member',
          cost: Number.parseFloat(payload.cost),
          cost_type: payload.cost_type,
        };
        await v2_save_resources([], [updated_resource_payload], []);
        new_resource_modal.close();
      },
    },
  });
  new_resource_modal.open();
}

async function handleDelete(data) {
  delete_popup.patchOptions(
    {
      attrs: {
        header: $t('Delete resource'),
        button_text: $t('Delete'),
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          try {
            const delete_payload = {
              uid: data.uid,
              clear_tracking: false,
            };
            await v2_save_resources([], [], [delete_payload]);
            active_schedule.value.resources = active_schedule.value.resources.filter(resource => resource.uid !== data.uid);
          }
          catch (err) {
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
            });
          }
          finally {
            delete_popup.close();
          }
        },
      },
    },
  );
  delete_popup.open();
}

watch(() => active_schedule.value.track_costs, () => {
  state.reload_count++;
});
</script>

<template>
  <div class="px-4 pb-6">
    <div class="flex items-center justify-between mt-2 mb-4">
      <div class="w-2/3 text-sm font-normal text-gray-600">
        {{ $t('resources-tab-description') }}
      </div>
      <HawkButton type="text" @click="onAddResources">
        <IconHawkPlus />
        {{ $t('Add resources') }}
      </HawkButton>
    </div>
    <HawkTable
      :key="active_schedule.resources.length + state.reload_count"
      :data="active_schedule.resources"
      :columns="table_columns"
      :is_gapless="true"
      :non_sortable_columns="['resource', 'access_level', 'unit']"
      :show_menu_header="false"
      :disable_resize="true"
      :header_grid_lines="{
        horizontal: true,
        vertical: true,
      }"
      :data_grid_lines="{
        horizontal: true,
        vertical: true,
      }"
      cell_height="32px"
      additional_table_classes="shadow-sm"
    >
      <template #resource="data">
        <template v-if="data.data.row.original.type === 'custom'">
          <div class="w-6 h-6 flex items-center justify-center bg-gray-100 text-md font-medium text-gray-600 rounded-full mr-1.5">
            {{ data.data.row.original.name.charAt(0) }}
          </div>
          <span class="text-sm font-medium text-gray-700">
            {{ data.data.row.original.name }}
          </span>
        </template>
        <template v-else-if="data.data.row.original.type === 'member'">
          <HawkMembers :members="data.data.row.original.external_id" type="label" size="xs" />
        </template>
      </template>
      <template #type="data">
        <div class="text-xs font-normal text-gray-700">
          <template v-if="data.data.row.original.type === 'member'">
            {{ $t('Member') }}
          </template>
          <template v-else>
            {{ $t('Custom') }}
          </template>
        </div>
      </template>
      <template #access_level="data">
        <div class="text-xs font-normal text-gray-700">
          <template v-if="data.data.row.original.type === 'member'">
            HARD-CODED
          </template>
          <template v-else>
            &ndash;
          </template>
        </div>
      </template>
      <template #cost="data">
        <div v-if="data.data.row.original.cost" class="text-xs font-normal text-gray-700">
          {{ active_schedule?.currency?.symbol }}
          {{ data.data.row.original.cost }}
        </div>
        <div v-else>
          &ndash;
        </div>
      </template>
      <template #unit="data">
        <div class="text-xs font-normal text-gray-600">
          <template v-if="data.data.row.original.cost_type === 'per_hour'">
            {{ $t('Per hour') }}
          </template>
          <template v-else-if="data.data.row.original.cost_type === 'fixed'">
            {{ $t('Fixed') }}
          </template>
          <template v-else-if="data.data.row.original.cost_type === 'per_item'">
            {{ $t('Per item') }}
          </template>
        </div>
      </template>
      <template #context_menu="data">
        <HawkMenu
          :items="[
            {
              label: $t('Edit'),
              value: 'edit',
              on_click: () => {
                onEditResource(data.data.row.original);
              },
            },
            {
              label: $t('Delete'),
              value: 'delete',
              on_click: () => {
                handleDelete(data.data.row.original);
              },
            },
          ]"
          additional_trigger_classes="!ring-0 !flex !items-center"
          @click.stop=""
        >
          <template #trigger>
            <IconHawkDotsVertical class="flex items-center text-gray-600" />
          </template>
        </HawkMenu>
      </template>
    </HawkTable>
  </div>
</template>
