<script setup>
import { flatten, uniq } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '~/therm/store/therm.store';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';
import { getUserFullName } from '~/common/utils/common.utils.js';

const props = defineProps({
  filters_map: {
    type: Object,
    default: () => ({}),
  },
});
const { $t, common_store } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const menu_items = [
  { uid: 'assignees', label: $t('Assignees') },
  { uid: 'defect_type', label: $t('Defect type') },
];
const group_by = ref(menu_items[0]);

const defect_resolution_graph_details = computed(() => {
  return {
    type: 'mscolumn2d',
    title: $t('Defects resolution'),
    description: $t('Detailed tracking of the defects assigned and it\'s resolution progress'),
    id: 'defect_resolution_container',
    yAxisName: $t('Defects count'),
    xAxisName: group_by.value.label,
  };
});
const defect_resolution_graph_data = computed(() => {
  const features_count = { Resolved: {}, UnResolved: {} };
  therm_store.report_defects.forEach((f) => {
    const is_resolved = [null, '3004', '3005'].includes(f.status);
    const status_type = is_resolved ? 'Resolved' : 'UnResolved';
    f.status_type = status_type;

    if (!features_count[status_type][f.type_id])
      features_count[status_type][f.type_id] = 0;
    features_count[status_type][f.type_id]++;

    (f.assignees || []).forEach((uid) => {
      if (!features_count[status_type][uid])
        features_count[status_type][uid] = 0;
      features_count[status_type][uid]++;
    });
    return f;
  });

  let category = [];
  if (group_by.value.uid === 'assignees')
    category = getAssigneesData();
  else
    category = getDefectTypeData();

  const dataset = [
    {
      seriesname: group_by.value.uid === 'assignees' ? 'Assigned' : 'Total',
      allowDrag: '0',
      data: category.map(val => ({
        value: features_count.UnResolved[val.uid] || 0,
      })),
      color: '#53B1FD',
    },
    {
      seriesname: 'Resolved',
      dashed: '1',
      data: category.map(val => ({
        value: features_count.Resolved[val.uid] || 0,
      })),
      color: '#32D583',
    },
  ];
  return {
    categories: [{ category }],
    dataset,
  };
});

function getAssigneesData() {
  const assignees = therm_store.report_defects.map(f => f.assignees || []);
  const assignees_details = uniq(flatten(assignees));
  const category = assignees_details.reduce((acc, uid) => {
    const user = common_store.get_user(uid);
    const team = common_store.get_team(uid);
    const label = user ? getUserFullName(user) : team.name;
    const assignees_filter = (props.filters_map?.assignees || []);
    if (label && (!assignees_filter.length || assignees_filter.includes(uid)))
      acc.push({ label, uid });
    return acc;
  }, []);
  return category;
}

function getDefectTypeData() {
  const feature_types_ids = therm_store.report_defects.map(f => f.type_id || []);
  const unique_feature_types_ids = uniq(feature_types_ids);
  const category = unique_feature_types_ids.reduce((acc, uid) => {
    const label = therm_store.feature_types[uid]?.name;
    if (label)
      acc.push({ label, uid });
    return acc;
  }, []);
  return category;
}
</script>

<template>
  <div class="border rounded-xl h-[520px] p-4 my-4">
    <ThermReportGraph
      :data="defect_resolution_graph_data"
      :graph_details="defect_resolution_graph_details"
      :has_borders="false"
      height="420"
    >
      <template #header-right>
        <HawkMenu
          :items="menu_items.map(item => ({ ...item, on_click: () => (group_by = item) }))"
          :has_bordered_trigger="false"
          additional_trigger_classes="!ring-0 p-0 -mt-1"
          additional_dropdown_classes="max-h-60 scrollbar"
          position="fixed"
        >
          <template #trigger="{ open }">
            <hawk-button type="outlined">
              {{ group_by.label }}
              <IconHawkChevronDown
                class="text-white transition-transform"
                :class="{ 'rotate-180': open }"
              />
            </hawk-button>
          </template>
        </HawkMenu>
      </template>
    </ThermReportGraph>
  </div>
</template>
