<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import { isEmpty, uniqBy } from 'lodash-es';
import DOMPurify from 'dompurify';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { getUserFullName } from '~/common/utils/common.utils.js';

const props = defineProps({
  comment_type: {
    type: String,
    required: true,
  },
  enable_editor: {
    type: Boolean,
    required: true,
  },
  current_comment: {
    type: Object,
  },
  autofocus: {
    type: Boolean,
    default: true,
  },
  has_custom_mentions: {
    type: Boolean,
    default: false,
  },
  custom_mentions: {
    type: Array,
    default: () => [],
  },
  show_current_user: {
    type: Boolean,
    default: false,
  },
  show_cta_buttons: {
    type: Boolean,
    default: false,
  },
  is_editing_comment_or_reply: {
    type: Boolean,
    default: false,
  },
  is_send_allowed: {
    type: Boolean,
    default: true,
  },
  append_mentions_to: {
    type: String,
    default: null,
  },
  is_assign_controls_visible: {
    type: Boolean,
    default: false,
  },
  show_assignee_selection: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['input', 'initialized', 'blur', 'focus', 'send', 'delete', 'reply', 'close', 'clickOutside', 'cancel']);

const route = useRoute();

const { logged_in_user_details } = useAuthStore();
const common_store = useCommonStore();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];

const wysiwyg_ctx = ref(null);

const state = reactive({
  tagged_users: [],
  selected_assignee_uid: null,
  form_data: {
    assignee_checkbox: true,
  },
  editor_contents: props.current_comment?.text,
});

const editor_block = ref(null);

// All users
const mention_items = computed(() => {
  const mention_obj = (user) => {
    let display_name = user.email ? user.email?.split('@')[0] : '';
    if (user.first_name)
      display_name = `${user.first_name} ${user.last_name || ''}`;

    return {
      bg_color: stringToNumber(user.first_name || user.email),
      label: DOMPurify.sanitize(display_name.trim(), { ALLOWED_TAGS: [] }),
      id: user.uid,
      avatar: user?.display_picture ?? '',
      email: DOMPurify.sanitize(user.email, { ALLOWED_TAGS: [] }),
    };
  };

  const result = common_store.scope_users(route?.params?.asset_id)
    .filter(user => user.uid !== logged_in_user_details.user_id)
    .map(mention_obj);

  const custom_mentions_list = props.custom_mentions
    .filter(user_id => user_id !== logged_in_user_details.user_id)
    .map((user_id) => {
      const user = common_store.get_user(user_id);
      return mention_obj(user);
    });

  return props.has_custom_mentions ? custom_mentions_list : result;
});

const organization_user = computed(() => (common_store.get_user(props?.current_comment?.user?.id)));
const internal_user = computed(() => common_store?.internal_users_map?.[props?.current_comment?.user?.id]);
const sanitized_editor_content = computed(() => {
  if ('<p></p>'.includes(state.editor_contents))
    return '';
  return DOMPurify.sanitize(state.editor_contents);
});

function stringToNumber(string) {
  let total = 0;
  for (const char of string)
    total += char.charCodeAt(0);

  return color_set[total % color_set.length];
}

function onInitialized(ctx) {
  wysiwyg_ctx.value = ctx;
  emit('initialized', ctx);
}

function onInput(input) {
  state.tagged_users = input.mentions;
  if (state.tagged_users.length > 1)
    state.selected_assignee_uid = state.tagged_users[0].uid;
  emit('input', input);
}

function onSend() {
  // emit('send');
  let comment_data = {
    text: sanitized_editor_content.value,
    mentions: state.tagged_users,
  };
  if (props.is_assign_controls_visible && state.form_data.assignee_checkbox) {
    const assignee = state.tagged_users.length === 1 ? state.tagged_users[0].uid : state.selected_assignee_uid;
    comment_data = { ...comment_data, assignee };
  }
  emit('send', comment_data);
  wysiwyg_ctx.value.setEditorContent('');
  state.editor_contents = '';
}

function onCancel() {
  wysiwyg_ctx.value.setEditorContent('');
  state.tagged_users = [];
  state.editor_contents = '';
  emit('cancel');
}

function sanitizeHtml(html_string) {
  return DOMPurify.sanitize(html_string);
}

onClickOutside(editor_block, () => {
  emit('clickOutside');
});
</script>

<template>
  <div class="group/comment" @click.stop>
    <div v-if="props.current_comment" class="text-sm mb-3">
      <div class="flex justify-between">
        <div class="flex gap-3">
          <HawkMembers :members="props.current_comment?.user?.id" />
          <div class="flex flex-col w-full">
            <div class="flex justify-between">
              <div v-if="getUserFullName(organization_user || internal_user || null)" class="text-sm font-medium">
                {{ getUserFullName(organization_user || internal_user || null) }}
              </div>
              <div v-else class="text-sm font-medium">
                {{ $t('Unknown') }}
              </div>
              <slot name="comment-menu" />
            </div>
            <span v-if="props.current_comment?.created_at" class="text-xs text-gray-500">
              {{ $date(props.current_comment?.created_at, 'DATETIME_LONG') }}
            </span>
          </div>
        </div>
        <slot name="comment-actions" />
      </div>
    </div>
    <div v-if="enable_editor" ref="editor_block">
      <!-- Comment input box -->
      <template v-if="props.show_current_user">
        <div class="flex items-center gap-3 mb-3">
          <HawkMembers :members="logged_in_user_details?.user_id" />
          <div class="flex flex-col w-full">
            <div class="flex justify-between">
              <div v-if="getUserFullName(logged_in_user_details)" class="text-sm font-medium">
                {{ getUserFullName(logged_in_user_details) }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <HawkWysiwygEditorComponent
        :key="props.current_comment?.text"
        v-model="state.editor_contents"
        :autofocus="autofocus"
        :plugins="['mentions']"
        :placeholder_text="comment_type === 'reply' ? $t('Type your reply') : $t('Type something to comment')"
        :mention_items="mention_items"
        :initial_content="props.current_comment?.text"
        :editor_enabled="true"
        :append_mentions_to="props.append_mentions_to"
        class="border rounded-md"
        editor_classes="min-h-[36px]"
        @input="onInput"
        @ctrlEnter="onSend"
        @initialized="onInitialized"
        @focus="emit('focus')"
      />
      <div v-if="is_assign_controls_visible && state.tagged_users.length" class="mt-2">
        <div v-if="show_assignee_selection" class="flex items-center mb-2 gap-1">
          <Vueform v-model="state.form_data" size="sm">
            <CheckboxElement
              name="assignee_checkbox"
            >
              {{ props.current_comment?.assignee ? $t('Reassign to') : $t('Assign to') }}
            </CheckboxElement>
          </Vueform>
          <span v-if="uniqBy(state.tagged_users, 'uid').length === 1" class="text-sm">
            <HawkMembers
              :members="state.tagged_users[0].uid"
              :name_truncate_length="20"
              type="badge"
            />
          </span>
          <HawkMenu
            v-else
            class="ml-1"
            additional_trigger_classes="ring-gray-100 w-48"
            :items="uniqBy(state.tagged_users, 'uid')"
            @click.stop
            @select="(selected_user) => state.selected_assignee_uid = selected_user.uid"
          >
            <template #trigger>
              <div class="h-9 border rounded-lg flex items-center justify-between px-3 text-sm">
                <HawkMembers
                  :members="state.selected_assignee_uid ?? state.tagged_users[0]"
                  size="badge"
                  :name_truncate_length="15"
                  type="label"
                />
                <div>
                  <IconHawkChevronDown class="text-gray-500" />
                </div>
              </div>
            </template>
            <template #item="{ item }">
              <span class="text-sm w-40">
                <HawkMembers :members="item.uid" size="badge" :name_truncate_length="15" type="label" />
              </span>
            </template>
          </HawkMenu>
        </div>
        <hr>
        <div class="mt-2 text-sm font-medium text-gray-700">
          <span
            v-if="show_assignee_selection && state.form_data.assignee_checkbox"
          >
            {{ $t('Assignees will receive a notification and will be responsible for marking it as done') }}
          </span>
          <span v-else>
            {{ $t('Your @mention will add people to this discussion and send an email') }}
          </span>
        </div>
      </div>
      <div v-if="props.show_cta_buttons" class="mt-2 flex justify-end">
        <HawkButton
          color="primary"
          type="text"
          class="mr-2"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </HawkButton>
        <HawkButton
          v-if="props.is_editing_comment_or_reply"
          @click="onSend"
        >
          {{ $t('Done') }}
        </HawkButton>
        <HawkButton
          v-else
          :disabled="!props.is_send_allowed || isEmpty(sanitized_editor_content)"
          @click="onSend"
        >
          {{ !isEmpty(current_comment) ? $t('Reply') : $t('Comment') }}
        </HawkButton>
      </div>
    </div>
    <div v-else-if="current_comment.type !== 'deleted'">
      <div class="mt-3 text-sm" v-html="sanitizeHtml(current_comment.text)" />
    </div>
    <div v-else>
      <slot name="deleted-comment">
        <div
          class="mt-3 text-xs font-normal text-gray-500"
          v-html="sanitizeHtml(current_comment.text)"
        />
      </slot>
    </div>
  </div>
</template>
