<script setup>
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { parseDuration } from '~/project-management/utils/pm-helper.utils';
import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import PmUpdateAutoProgress from '~/project-management/components/activity-details/pm-update-auto-progress.vue';
import useEmitter from '~/common/composables/useEmitter';

const $t = inject('$t');
const $toast = inject('$toast');
const $date = inject('$date');

const route = useRoute();
const emitter = useEmitter();
const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const {
  // setupOverloadedResources,
  openResourcesEditor,
} = useHelpers();
const { active_task, active_task_uid, active_schedule, $g, flags, is_schedule_editable, resources_tippy, tippy_target } = storeToRefs(project_management_store);
const { update_activity_progress, update_activity_backend, update_resource_assignments } = project_management_store;
const { get_user_or_team_name } = common_store;

const update_progress_auto_modal = useModal({
  component: PmUpdateAutoProgress,
  attrs: {
    onClose() {
      update_progress_auto_modal.close();
    },
  },
});

const form$ = ref(null);

const state = reactive({
  form_data: {},
  show_vueform: false,
  is_duration_active: false,
  is_resources_input_active: !active_task.value.resources?.length,
  is_resources_loading: false,
  is_tags_input_active: false,
  is_editing_progress: false,
  is_updating_progress: false,
  is_editing_activity_id: false,
  resource_items: [],
  free_slack: 0,
});

const disabled = computed(() => {
  return !is_schedule_editable.value;
});

const can_create = computed(() => {
  return true;
});

const percent_progress = computed(() => {
  return Math.round(active_task.value?.progress * 100) || 0;
});

const progress_text = computed(() => {
  if (percent_progress.value === 0)
    return {
      status: 'not started',
      label: $t('Not started'),
    };
  else if (percent_progress.value === 100)
    return {
      status: 'completed',
      label: $t('Completed'),
    };
  else
    return {
      status: 'in progress',
      label: $t('In progress'),
    };
});

const percent_schedule_complete = computed(() => {
  return Math.round(active_task.value?.percent_schedule_complete * 100) || 0;
});

const spi_class = computed(() => {
  if (active_task.value?.spi !== null)
    if (active_task.value?.spi < 1)
      return '!text-error-700';
    else
      return 'text-success-700';
  return '';
});

const cpi_class = computed(() => {
  if (active_task.value?.cpi !== null && active_schedule.value.track_costs)
    if (active_task.value?.cpi < 1)
      return '!text-error-700';
    else
      return 'text-success-700';
  return '';
});

const active_task_info = computed(() => {
  return [
    [$t('Activity ID'), () => {
      return active_task.value?.id;
    }],
    ...(active_schedule.value?.baseline
      ? [
          [$t('Baseline Start'), () => {
            return active_task.value?.bl_start ? $date(active_task.value?.bl_start, 'DATE_MED') : '-';
          }],
          [$t('Baseline Duration'), () => {
            return active_task.value?.bl_duration ? $date(active_task.value?.bl_duration, 'DATE_MED') : '-';
          }],
          [$t('Baseline Finish'), () => {
            return active_task.value?.bl_finish ? $date(active_task.value?.bl_finish, 'DATE_MED') : '-';
          }],
        ]
      : []),
    [$t('Actual Start'), () => {
      return active_task.value?.actual_start ? $date(active_task.value?.actual_start, 'DATE_MED') : '-';
    }],
    [$t('Total Slack'), () => {
      return active_task.value?.total_slack ? `${active_task.value?.total_slack} ${$t('Days')}` : '-';
    }],
    [$t('Actual Finish'), () => {
      return active_task.value?.actual_finish ? $date(active_task.value?.actual_finish, 'DATE_MED') : '-';
    }],
    [$t('SPI'), () => {
      return active_task.value?.spi?.toFixed?.(2) || '-';
    }, spi_class.value],
    ...(active_schedule.value?.track_costs
      ? [
          [$t('CPI'), () => {
            return active_task.value?.cpi?.toFixed?.(2) || '-';
          }, cpi_class.value],
          [$t('Planned Cost'), () => {
            return !isNil(active_task.value?.planned_cost) ? `${active_schedule.value.currency?.symbol}${active_task.value?.planned_cost}` : '-';
          }],
          [$t('Actual Cost'), () => {
            return !isNil(active_task.value?.actual_cost) ? `${active_schedule.value.currency?.symbol}${active_task.value?.actual_cost}` : '-';
          }],
        ]
      : []),
    ...(active_task.value?.activity_code_values
      ? active_task.value.activity_code_values.map((activity_code) => {
        return [
          activity_code?.name,
          () => {
            return activity_code?.value?.description;
          },
        ];
      })
      : []),
  ].map((i) => {
    return {
      label: i[0],
      property: i[0].toLowerCase().replace(/ /g, '_'),
      value: i[1],
      value_class: i[2],
    };
  });
});

const custom_fields = computed(() => {
  return active_schedule.value?.custom_fields || {};
});

const active_task_custom_field_values = computed(() => {
  return active_task.value?.custom_field_values || {};
});

const display_progress_first_section = computed(() => {
  const width = percent_schedule_complete.value < percent_progress.value
    ? percent_schedule_complete.value
    : percent_progress.value;
  return width > 10;
});

const progress_success_width = computed(() => {
  return {
    width:
      percent_schedule_complete.value < percent_progress.value
        ? `${percent_schedule_complete.value}%`
        : `${percent_progress.value}%`,
  };
});

const progress_width = computed(() => {
  return {
    width:
      percent_schedule_complete.value > percent_progress.value
        ? `${percent_schedule_complete.value - percent_progress.value}%`
        : `${percent_progress.value - percent_schedule_complete.value}%`,
  };
});

const progress_info_scheduled_to_start = computed(() => {
  const a = dayjs(active_task.value?.start_date);
  const b = dayjs(new Date());
  const diff = a.diff(b, 'days');

  if (diff < 0)
    return {
      icon: 'warning',
      text: `${$t('The activity was scheduled to start')} ${a.fromNow()} ${$t('i.e.')} ${a.format('ll')}`,
    };

  else
    return {
      icon: 'info',
      text: `${$t('The activity is scheduled to start')} ${a.fromNow()} ${$t('i.e.')} ${a.format('ll')}`,
    };
});

const progress_info_completed = computed(() => {
  if (
    active_task.value?.actual_finish > active_task.value?.planned_finish
  ) {
    const a = dayjs(active_task.value.actual_finish);
    const b = dayjs(active_task.value.planned_finish);
    const diff = a.diff(b, 'days');
    return {
      icon: 'checked',
      text: `${$t('The activity was completed with a delay of')} ${diff} ${
                diff === 1
                  ? $t('day')
                  : $t('days')
              }`,
    };
  }
  else {
    return {
      icon: 'checked',
      text: $t('The activity is complete'),
    };
  }
});

const progress_info_lagging_behind = computed(() => {
  const dur = is_schedule_editable.value ? 'duration' : 'planned_duration';
  const x = active_task.value?.percent_schedule_complete - active_task.value?.progress;
  const y = Math.round(active_task.value?.[dur] * x) || 0;
  return {
    icon: 'warning',
    text: `${$t('The activity is lagging behind the schedule by')} ${Math.round(x * 100) || 0}% (${y} ${
              y === 1
                ? $t('day')
                : $t('days')
            })`,
  };
});

const progress_info_ahead_of_schedule = computed(() => {
  const dur = is_schedule_editable.value ? 'duration' : 'planned_duration';
  const x = active_task.value?.progress - active_task.value?.percent_schedule_complete;
  const y = Math.round(active_task.value?.[dur] * x) || 0;
  return {
    icon: 'info',
    text: `${$t('The activity is ahead of schedule by')} ${Math.round(x * 100) || 0}% (${y} ${
              y === 1
                ? $t('day')
                : $t('days')
            })`,
  };
});

const progress_info = computed(() => {
  if (active_task.value?.progress === 0 && active_task.value?.progress === active_task.value?.percent_schedule_complete)
    return progress_info_scheduled_to_start.value;

  else if (active_task.value?.progress === 1)
    return progress_info_completed.value;

  else if (active_task.value?.progress < active_task.value?.percent_schedule_complete)
    return progress_info_lagging_behind.value;

  else if (active_task.value?.progress > active_task.value?.percent_schedule_complete)
    return progress_info_ahead_of_schedule.value;

  else if (active_task.value?.progress === active_task.value?.percent_schedule_complete)
    return {
      icon: 'info',
      text: $t('The activity is on schedule'),
    };
});

function onLoadNewTask() {
  state.form_data.resources = active_schedule.value.resource_assignments
    .filter(item => item.activity === active_task.value.uid)
    .map(item => item.resource)
    .map((item) => {
      const id = active_schedule.value?.resources?.find?.(el => el.uid === item);
      return id?.uid;
    });
  state.form_data.priority = active_task.value?.priority || '5';
  state.form_data.category = active_task.value?.category;
  state.form_data.tags = active_task.value?.tags;
  state.form_data.start_date = active_task.value?.start_date;
  state.form_data.duration = `${Math.ceil(active_task.value?.duration)} days`;
  state.form_data.id = active_task.value?.id;

  setTimeout(() => {
    state.show_vueform = true;
  }, 10);
  state.free_slack = $g.value.getFreeSlack($g.value.getTask(active_task.value.id));
}

function onProgressIconClick() {
  if (active_task.value.auto_progress_sync?.is_enabled) {
    update_progress_auto_modal.open();
  }
  else {
    state.is_editing_progress = true;
    state.form_data.progress = cloneDeep(active_task.value?.progress * 100);
  }
}

async function onProgressChange() {
  state.is_updating_progress = true;
  const payload = {};
  payload.progress = Number.parseInt(state.form_data.progress) / 100;
  if (active_task.value.progress === 0)
    payload.actual_start_date = active_task.value?.planned_start || new Date();
  if (state.form_data.progress === 100)
    payload.actual_finish_date = active_task.value?.planned_finish || new Date();
  const task = $g.value.getTask(active_task.value.id);
  task.progress = Number.parseInt(state.form_data.progress) / 100;
  await update_activity_progress(payload);
  $g.value.updateTask(active_task.value.id);

  $g.value.render();
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
  state.is_editing_progress = false;
  state.is_updating_progress = false;
}

async function onStartDateChange(new_start_date, old_start_date) {
  const task = $g.value.getTask(active_task.value.id);
  task.start_date = dayjs(new_start_date).toDate();
  task.end_date = $g.value.calculateEndDate(task.start_date, task.duration);
  $g.value.updateTask(active_task.value.id);

  $g.value.autoSchedule();
  $g.value.render();
  await nextTick();
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
  state.free_slack = $g.value.getFreeSlack($g.value.getTask(active_task.value.id));
  if (
    !dayjs(state.form_data.start_date).isSame(dayjs(active_task.value.start_date), 'day')
      || Number.parseInt(state.form_data.duration) !== active_task.value.duration
  ) {
    $toast({
      title: $t('Failed to update the field value'),
      type: 'error',
    });
    state.form_data.start_date = active_task.value?.start_date;
    state.form_data.duration = active_task.value?.duration;
  }
}

async function activateDurationEditor() {
  state.is_duration_active = true;
  await nextTick();
  form$.value.elements$.duration.input.focus();
}

async function deactivateDurationEditor(form) {
  state.is_duration_active = false;
  const task = $g.value.getTask(active_task.value.id);
  const duration = parseDuration(form.data.duration);
  if (task.duration === duration || !duration)
    return;
  state.form_data.duration = `${duration} days`;
  task.duration = duration;
  task.end_date = $g.value.calculateEndDate(task.start_date, task.duration);
  $g.value.updateTask(active_task.value.id);

  $g.value.autoSchedule();
  $g.value.render();
  await nextTick();
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
  state.free_slack = $g.value.getFreeSlack($g.value.getTask(active_task.value.id));
  if (
    !dayjs(state.form_data.start_date).isSame(dayjs(active_task.value.start_date), 'day')
      || Number.parseInt(duration) !== active_task.value.duration
  ) {
    $toast({
      title: $t('Failed to update the field value'),
      type: 'error',
    });
    state.form_data.start_date = active_task.value?.start_date;
    state.form_data.duration = active_task.value?.duration;
  }
}

async function activateResourcesEditor() {
  if (resources_tippy.value)
    return;
  openResourcesEditor(null, active_task.value.id);
}

async function onResourcesChange() {
  state.is_resources_loading = true;
  if (isEqual(active_task.value.resources, state.form_data.resources)) {
    state.is_resources_loading = false;
    return;
  }
  // let union_resources = union(active_task.value.resources, state.form_data.resources);
  // union_resources = union_resources.map((resource_uid) => {
  //   return active_schedule.value.resources.find(resource => resource.uid === resource_uid);
  // });
  $g.value.getTask(active_task.value.id).resources = state.form_data.resources;
  $g.value.updateTask(active_task.value.id);
  const all_resources = state.form_data.resources.map((item) => {
    return state.resource_items.find(resource => resource.uid === item);
  });

  update_resource_assignments(all_resources, active_task.value.uid);

  const body = {
    resources: all_resources.map((item) => {
      return {
        uid: item.uid,
        units_required: active_schedule.value.resource_assignments.find((resource_assignment) => {
          return resource_assignment.resource === item.uid && resource_assignment.activity === active_task.value.uid;
        })?.units_required || 0,
      };
    }),
  };
  await update_activity_backend(active_task.value.uid, body);
  state.is_resources_loading = false;
  // setupOverloadedResources(union_resources);
  $g.value.render();
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
}

function onResourcesClickOutside() {
  if (active_task.value?.resources?.length)
    state.is_resources_input_active = false;
}

async function activateTagsEditor() {
  state.is_tags_input_active = true;
  state.form_data.tags = active_task.value?.tags;
  await nextTick();
  form$.value.elements$.tags.input.focus();
  form$.value.elements$.tags.input.activate();
}

function setCreatedTags(e) {
  form$.value.elements$.tags.load([...form$.value.data.tags, e.uid]);
}

async function onTagsChange() {
  if (isEqual(active_task.value.tags, state.form_data.tags)) {
    state.is_tags_input_active = false;
    return;
  }
  $g.value.getTask(active_task.value.id).tags = state.form_data.tags;
  $g.value.updateTask(active_task.value.id);
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
  if (!is_schedule_editable.value)
    update_activity_backend(active_task.value.uid, { tags: state.form_data.tags });
  state.is_tags_input_active = false;
}

function onPriorityChange(new_priority_value) {
  $g.value.getTask(active_task.value.id).priority = new_priority_value;
  $g.value.updateTask(active_task.value.id);
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
  if (!is_schedule_editable.value)
    update_activity_backend(active_task.value.uid, { priority: new_priority_value });
}

function onCategoryChange(new_category_value) {
  $g.value.getTask(active_task.value.id).category = new_category_value;
  $g.value.updateTask(active_task.value.id);
  active_schedule.value.activities[active_task.value.uid] = $g.value.getTask(active_task.value.id);
  if (!is_schedule_editable.value)
    update_activity_backend(active_task.value.uid, { category: new_category_value });
}

function onActivityIdChange() {
  if (!state.form_data.id || active_task.value.id === state.form_data.id) {
    state.is_editing_activity_id = false;
    return;
  }
  if ($g.value.isTaskExists(state.form_data.id)) {
    $toast({
      title: $t('Failed to update the Activity ID'),
      text: $t('The entered Activity ID already exists'),
      type: 'error',
    });
    state.is_editing_activity_id = false;
    return;
  }
  $g.value.changeTaskId(active_task.value.id, state.form_data.id);
  if (active_task.value)
    active_task.value.id = state.form_data.id;
  state.is_editing_activity_id = false;
}

async function activateActivityIdEditor() {
  state.is_editing_activity_id = true;
  await nextTick();
  form$.value.elements$.id.input.focus();
}

function onKeydown(event) {
  if (!['Enter', 'Escape'].includes(event.key))
    return;
  event.preventDefault();
  if (event.key === 'Enter')
    onActivityIdChange();
  else
    state.is_editing_activity_id = false;
}

watch(() => active_task.value?.uid, () => {
  onLoadNewTask();
}, { immediate: true });

watch(() => state.is_editing_activity_id, () => {
  if (state.is_editing_activity_id)
    state.form_data.id = active_task.value?.id;
});

onMounted(async () => {
  state.resource_items = active_schedule.value.resources.map((item) => {
    let label;
    if (item.type === 'member')
      label = get_user_or_team_name(item.external_id);
    else label = item.name;
    return {
      ...item,
      value: item.uid,
      label,
    };
  });
});
</script>

<template>
  <div class="flex mt-4 flex-col">
    <Vueform
      v-if="state.show_vueform"
      ref="form$"
      v-model="state.form_data"
      size="sm"
      sync
      :display-errors="false"
      :display-messages="false"
    >
      <div class="col-span-12">
        <div class="grid grid-cols-11 min-h-[112px]">
          <div class="col-span-5">
            <div class="flex flex-col flex-grow gap-3">
              <div class="flex justify-between">
                <div class="text-xs !font-normal text-gray-600 mb-0.5 flex items-center gap-1">
                  {{ $t('Progress') }}
                </div>
                <div v-if="!state.is_editing_progress">
                  <template
                    v-if="
                      (active_task.type === $g.config.types.task
                        && active_schedule.can_modify)
                        && !is_schedule_editable"
                  >
                    <HawkButton
                      v-if="active_task.auto_progress_sync?.is_enabled"
                      size="xs"
                      icon
                      type="text"
                      @click="onProgressIconClick"
                    >
                      <IconHawkRefreshCcwFive
                        class="inline w-4 h-4 text-gray-500"
                      />
                    </HawkButton>
                    <HawkButton
                      :disabled="active_task.auto_progress_sync?.is_enabled"
                      size="xs"
                      icon type="text"
                      @click="onProgressIconClick"
                    >
                      <IconHawkPencilOne
                        v-tippy="{
                          content: active_task.auto_progress_sync?.is_enabled ? $t('The progress is configured to sync automatically') : '',
                          placement: 'top',
                        }"
                        class="inline w-4 h-4 text-gray-500"
                      />
                    </HawkButton>
                  </template>
                </div>
                <div v-else>
                  <HawkButton
                    v-if="!state.is_updating_progress"
                    icon
                    size="xs"
                    type="text"
                    @click="state.is_editing_progress = false"
                  >
                    <IconHawkXClose class="w-4 h-4" />
                  </HawkButton>
                  <HawkButton
                    icon
                    size="xs"
                    type="text"
                    :loading="state.is_updating_progress"
                    @click="onProgressChange"
                  >
                    <IconHawkCheck class="w-4 h-4 !text-primary-600" />
                  </HawkButton>
                </div>
              </div>
              <div class="flex gap-1 items-center h-[24px]">
                <SliderElement
                  v-if="state.is_editing_progress"
                  class="w-full"
                  name="progress"
                  :add-class="{
                    slider: {
                      base: '-mt-5 !bg-transparent',
                      connects: '!bg-gray-50 !h-6 !border !border-gray-200 !rounded-none',
                      connect: '!rounded-none',
                      tooltip: '!bottom-6',
                    },
                  }"
                  :override-class="{
                    slider: {
                      handle: 'absolute rounded-full transition-shadow border-0 cursor-grab txt-rtl-h:-left-2 txt-rtl-h:right-auto disabled:cursor-not-allowed disabled:pointer-events-none form-bg-slider-handle form-shadow-handles hover:form-shadow-handles-hover focus:form-shadow-handles-focus focused-hover:form-shadow-handles-hover focus:outline-zero !top-0 !h-5 !cursor-col-resize !bg-transparent ',
                    },
                  }"
                />
                <div v-else class="w-full border border-gray-200 h-6">
                  <div
                    class="flex items-start bg-gray-50 h-full"
                    :class="percent_progress === 100 ? '' : 'rounded-r-sm'"
                  >
                    <div
                      class="h-full transition-all duration-300"
                      :class="[
                        percent_schedule_complete > percent_progress ? 'bg-error-500' : 'bg-primary-600',
                        percent_progress === 100 ? 'rounded-sm' : 'rounded-l-sm',
                      ]"
                      :style="progress_success_width"
                    >
                      <div v-if="display_progress_first_section" class="flex items-center justify-end h-full mx-1 text-xs font-medium text-white">
                        {{ percent_progress }}%
                      </div>
                    </div>
                    <div
                      class="h-full transition-all duration-300"
                      :class="percent_schedule_complete > percent_progress ? 'bg-gray-50' : 'bg-primary-100'"
                      :style="progress_width"
                    >
                      <div v-if="!display_progress_first_section" class="flex items-center w-full h-full mx-1 text-xs font-medium text-black">
                        {{ percent_progress }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center text-xs font-medium rounded-lg"
                :class="{
                  'text-primary-700': progress_info?.icon === 'info' || progress_info?.icon === 'checked',
                  'text-warning-700': progress_info?.icon === 'warning',
                }"
              >
                {{ progress_info?.text || '' }}
              </div>
            </div>
          </div>
          <div class="col-span-1 flex justify-center items-center">
            <div class="w-px h-full bg-gray-100" />
          </div>
          <div class="col-span-5 flex flex-col gap-3">
            <div class="flex justify-between">
              <div class="flex flex-col mb-1">
                <div class="text-xs !font-normal text-gray-600 mb-2 flex items-center">
                  {{ $t('Planned Start') }}
                </div>
                <div class="flex items-center h-[24px] text-sm font-semibold text-gray-900 -mt-[3px]">
                  <DateTimeElement
                    name="start_date"
                    :options="{
                      clearable: false,
                      format: 'dd MMM, yyyy',
                      disabled,
                      disabledDates: (date) => {
                        return !$g.isWorkTime(date);
                      },
                      teleport: false,
                      hideInputIcon: true,
                    }"
                    class="date_time date-time-underline w-[110px]"
                    :class="{
                      'date-time-overdue': active_task.actual_start && dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') > 0,
                      'date-time-ahead': active_task.actual_start && dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') < 0,
                    }"
                    :add-classes="{
                      ElementLayout: {
                        container: 'relative',
                      },
                    }"
                    :override-classes=" {
                      ElementLayout: {
                        container_sm: '',
                        innerContainer: '',
                        innerWrapper: '',
                        outerWrapper: '',
                      },
                      ElementLabel: {
                        container: 'text-gray-600 !text-xs !font-normal',
                      },
                    }"
                    @change="onStartDateChange"
                  />
                  <span
                    v-if="active_task.actual_start && dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') !== 0"
                    v-tippy="{
                      content: `
                        ${dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') > 0
                          ? `${$t('Started')} ${dayjs(active_task.actual_start).diff(active_task.planned_start, 'd')} ${dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') === 1 ? $t('day late') : $t('days late')}`
                          : `${$t('Started')} ${dayjs(active_task.planned_start).diff(active_task.actual_start, 'd')} ${dayjs(active_task.planned_start).diff(active_task.actual_start, 'd') === 1 ? $t('day early') : $t('days early')}`
                      }`,
                    }"
                    class="!rounded-sm px-1.5 py-0.5 flex items-center text-xs font-medium -ml-2.5 mt-0.5"
                    :class="{
                      'bg-error-50 text-error-700': dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') > 0,
                      'bg-primary-50 text-primary-700': dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') < 0,
                    }"
                  >
                    {{ dayjs(active_task.actual_start).diff(active_task.planned_start, 'd') }}d
                  </span>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="text-xs !font-normal text-gray-600 mb-2 flex items-center">
                  {{ $t('Planned Finish') }}
                </div>
                <div
                  class="flex items-center -mt-0.5 gap-2 h-[24px] text-sm font-semibold text-gray-900"
                  :class="{
                    '!text-error-600': active_task.actual_finish && dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') > 0,
                    '!text-primary-600': active_task.actual_finish && dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') < 0,
                  }"
                >
                  <template v-if="!dayjs(active_task.start_date).isSame(dayjs(active_task.end_date), 'day')">
                    {{ $date(dayjs(active_task.end_date).subtract(1, 'day'), 'DD MMM, YYYY') }}
                  </template>
                  <template v-else>
                    {{ $date(active_task.end_date, 'DD MMM, YYYY') }}
                  </template>
                  <span
                    v-if="active_task.actual_finish && dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') !== 0"
                    v-tippy="{
                      content: `
                        ${dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') > 0
                        ? `${$t('Finished')} ${dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd')} ${dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') === 1 ? $t('day late') : $t('days late')}`
                        : `${$t('Finished')} ${dayjs(active_task.planned_finish).diff(active_task.actual_finish, 'd')} ${dayjs(active_task.planned_finish).diff(active_task.actual_finish, 'd') === 1 ? $t('day early') : $t('days early')}`
                      }`,
                    }"
                    class="!rounded-sm px-1.5 py-0.5 flex items-center text-xs font-medium"
                    :class="{
                      'bg-error-50 text-error-700': dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') > 0,
                      'bg-primary-50 text-primary-700': dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') < 0,
                    }"
                  >
                    {{ dayjs(active_task.actual_finish).diff(active_task.planned_finish, 'd') }}d
                  </span>
                </div>
              </div>
            </div>
            <div class="flex justify-between">
              <div>
                <div class="text-xs !font-normal text-gray-600 flex items-center cursor-default">
                  {{ $t('Duration') }}
                </div>
                <div class="flex gap-2 items-center mt-0.5">
                  <TextElement
                    v-show="state.is_duration_active && !disabled"
                    name="duration"
                    class="cursor-pointer w-[100px]"
                    :presets="['focused_underline']"
                    :add-classes="{
                      ElementLayout: {
                        container: 'relative',
                      },
                      TextElement: {
                        inputContainer: '!h-8 !font-semibold',
                      },
                    }"
                    :override-classes=" {
                      ElementLayout: {
                        container_sm: '',
                        innerContainer: '',
                        innerWrapper: '',
                        outerWrapper: '!-ml-[9px]',
                      },
                    }"
                    @blur="deactivateDurationEditor"
                    @keydown.enter="state.is_duration_active = false"
                  />
                  <div
                    v-show="!(state.is_duration_active && !disabled)"
                    class="flex mt-[7px] select-none"
                    :class="{
                      'cursor-pointer': is_schedule_editable,
                    }"
                    @click="activateDurationEditor"
                  >
                    <div class="text-sm font-semibold text-gray-900">
                      {{ Math.ceil(active_task.duration) }} {{ $t('days') }}
                      <span
                        v-if="active_task.progress !== 1 && state.free_slack > 0"
                        class="!bg-error-50 !rounded-sm !text-error-700 ml-1 p-1"
                      >
                        +{{ state.free_slack }}d
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="border flex items-center gap-3 rounded-lg px-2 py-[6px] h-fit !mt-[19.2px]"
                :class="{
                  'border-primary-300 bg-primary-25': dayjs(active_task.end_date).diff(dayjs(), 'day') > 0,
                  'border-error-300 bg-error-25': dayjs(active_task.end_date).diff(dayjs(), 'day') <= 0,
                  'border-success-300 bg-success-25': active_task.progress === 1,
                }"
              >
                <IconHawkClockStopwatch
                  v-if="active_task.progress !== 1"
                  :class="{
                    'text-primary-600': dayjs(active_task.end_date).diff(dayjs(), 'day') > 0,
                    'text-error-600': dayjs(active_task.end_date).diff(dayjs(), 'day') <= 0,
                  }"
                />
                <IconHawkCheckCircle
                  v-else
                  class="text-success-600"
                />
                <div
                  v-if="active_task.progress !== 1"
                  class="text-sm font-semibold"
                  :class="{
                    'text-primary-700': dayjs(active_task.end_date).diff(dayjs(), 'day') > 0,
                    'text-error-700': dayjs(active_task.end_date).diff(dayjs(), 'day') <= 0,
                  }"
                >
                  <!-- TODO: i18n -->
                  <template v-if="dayjs(active_task.end_date).diff(dayjs(), 'day') > 0">
                    {{ dayjs(active_task.end_date).diff(dayjs(), 'day') }} {{ $t('days left to finish') }}.
                  </template>
                  <template v-else>
                    {{ dayjs().diff(active_task.end_date, 'day') }} {{ $t('days overdue') }}.
                  </template>
                </div>
                <div
                  v-else
                  class="text-sm font-semibold text-success-700"
                >
                  {{ $t('Completed') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="-mx-6 my-4">
        <div class="grid grid-cols-11">
          <template v-if="active_schedule.track_resources">
            <!-- TODO: Add task check -->
            <div
              id="activity-details-resources-editor"
              class="col-span-5 grid grid-cols-12 h-fit mb-2.5"
              @click="activateResourcesEditor"
            >
              <div class="text-xs !font-normal text-gray-600 col-span-4 mt-2.5 h-fit">
                {{ $t('Resources') }}
              </div>
              <div
                v-if="active_task.is_backend_save_pending"
                class="flex items-end"
              >
                <IconHawkInfoCircle

                  v-tippy="{
                    content: $t('Note: Please save your progress to be able to add resources.'),
                    placement: 'bottom',
                    appendTo: tippy_target,
                  }"
                  class="flex items-center w-4 h-4"
                />
              </div>
              <template v-else>
                <PmResourcesTags
                  v-if="active_task.resources?.length"
                  :resources="active_task.resources"
                  :all_resources="state.resource_items"
                  :max_tags_to_display="1"
                  popover_position="left"
                  class="col-span-8 h-fit mt-[6px] -ml-[1px]"
                />
                <div v-else class="mt-[6px] ml-0.5 !font-normal text-gray-600">
                  &ndash;
                </div>
              </template>
            </div>
            <div class="col-span-1" />
          </template>
          <div
            v-if="!state.is_tags_input_active && active_task.tags?.length"
            class="col-span-5 grid grid-cols-12 h-fit mb-2.5"
            @click="activateTagsEditor"
          >
            <div class="text-xs !font-normal text-gray-600 col-span-4 mt-2.5 h-fit">
              {{ $t('Tags') }}
            </div>
            <HawkTagsName
              v-if="active_task.tags?.length"
              :tags="active_task.tags"
              :max_tags_to_display="2"
              popover_position="right"
              class="col-span-8 h-fit mt-[6px] -ml-[1px]"
              additional_tag_classes="!text-xs !py-[3px] !h-fit"
            />
            <div v-else class="mt-[6px] ml-0.5 !font-normal text-gray-600">
              &ndash;
            </div>
          </div>
          <HawkTagsInput
            v-else
            v-click-outside="() => { onTagsChange() }"
            class="mb-1"
            :options="{
              name: 'tags',
              label: `${$t('Tags')}`,
              placeholder: $t('Select Tags'),
              canClear: false,
              create: can_create,
              disabled: false,
              caret: true,
              presets: ['focused_underline', 'xs_variant'],
              addClasses: {
                TagsElement: {
                  select: {
                    container: '!border-transparent -ml-2',
                    wrapper: '',
                    placeholder: '!text-xs !font-normal',
                  },
                },
              },
              overrideClasses: {
                ElementLabel: {
                  container: 'mt-2.5 !text-gray-600 !text-xs !font-normal',
                },
                TagsElement: {
                  select: {
                    container_disabled: '',
                  },
                },
              },
            }"
            :create_inline="true"
            :tags_removable="true"
            :setter_function="setCreatedTags"
            :columns="{
              default: { container: 5, label: 4, wrapper: 12 },
              sm: { container: 5, label: 4, wrapper: 12 },
              md: { container: 5, label: 4, wrapper: 12 },
            }"
          >
            <template #tag="{ option, handleTagRemove }">
              <div class="flex items-center whitespace-nowrap text-xs rounded-lg border border-gray-300 py-[3px] px-1.5 mr-1 mb-1">
                <p v-tippy="option.name.length > 15 ? option.name : ''" class="text-gray-700">
                  {{ $filters.truncate(option.name, 15) }}
                </p>
                <div class="hover:bg-gray-50 rounded ml-2" @mousedown.prevent="handleTagRemove(option, $event)">
                  <IconHawkX class="w-4 h-4 text-gray-400 hover:text-gray-600" />
                </div>
              </div>
            </template>
          </HawkTagsInput>
          <div v-if="!active_schedule.track_resources" class="col-span-1" />
          <SelectElement
            name="priority"
            :label="$t('Priority')"
            :placeholder="$t('Select Priority')"
            :items="[
              {
                name: 'critical',
                label: $t('Critical'),
                value: 1,
              },
              {
                name: 'high',
                label: $t('High'),
                value: 2,
              },
              {
                name: 'medium',
                label: $t('Medium'),
                value: 3,
              },
              {
                name: 'low',
                label: $t('Low'),
                value: 4,
              },
              {
                name: 'not_set',
                label: $t('Not set'),
                value: 5,
              },
            ]"
            :can-clear="false"
            :can-deselect="false"
            :native="false"
            :disabled="false"
            :caret="true"
            :add-classes="{
              SelectElement: {
                select: {
                  container: '!border-transparent -ml-2',
                  wrapper: '',
                  placeholder: '!text-xs !font-normal',
                },
              },
            }"
            :presets="['focused_underline', 'xs_variant']"
            :override-classes="{
              ElementLabel: {
                container: 'mt-2.5 !text-gray-600 !text-xs !font-normal',
              },
              SelectElement: {
                select: {
                  container_disabled: '',
                },
              },
            }"
            :columns="{
              default: { container: 5, label: 4, wrapper: 12 },
              sm: { container: 5, label: 4, wrapper: 12 },
              md: { container: 5, label: 4, wrapper: 12 },
            }"
            @change="onPriorityChange"
          >
            <template #single-label="{ value }">
              <div class="absolute top-0 left-0 flex items-center h-full pl-3 leading-snug bg-transparent pointer-events-none">
                <PmPriorityTag :priority="value.value" />
              </div>
            </template>
            <template #option="{ option }">
              <!-- <TaskPriority :priority="option.value" /> -->
              <PmPriorityTag :priority="option.value" />
            </template>
          </SelectElement>
          <div v-if="active_schedule.track_resources" class="col-span-1" />
          <HawkCategoryInput
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Category'),
              multi: false,
              canClear: false,
              create: can_create,
              disabled: false,
              caret: true,
              presets: ['focused_underline', 'xs_variant'],
              addClasses: {
                SelectElement: {
                  select: {
                    search: '!text-xs',
                    container: '!border-transparent -ml-2',
                    wrapper: '',
                    placeholder: '!text-xs !font-normal',
                  },
                },
              },
              overrideClasses: {
                ElementLabel: {
                  container: 'mt-2.5 !text-gray-600 !text-xs !font-normal',
                },
                SelectElement: {
                  select: {
                    container_disabled: '',
                  },
                },
              },
              onChange: onCategoryChange,
            }"
            :columns="{
              default: { container: 5, label: 4, wrapper: 12 },
              sm: { container: 5, label: 4, wrapper: 12 },
              md: { container: 5, label: 4, wrapper: 12 },
            }"
            create_classes="text-xs"
          >
            <template #single-label="{ value }">
              <div class="w-full pl-2 mt-0.5 text-xs truncate">
                {{ value.name }}
              </div>
            </template>
          </HawkCategoryInput>
        </div>
        <div
          class="mt-3"
          :class="{
            'mb-2': !flags.is_activity_details_showing_more,
          }"
        >
          <div
            v-if="flags.is_activity_details_showing_more"
            class="w-fit flex items-center text-xs font-semibold text-gray-900 cursor-pointer"
            @click="flags.is_activity_details_showing_more = false"
          >
            {{ $t('Show less') }}
            <IconHawkChevronUp class="inline ml-1" />
          </div>
          <div
            v-else
            class="w-fit flex items-center text-xs font-semibold text-gray-900 cursor-pointer"
            @click="flags.is_activity_details_showing_more = true"
          >
            {{ $t('Show more') }}
            <IconHawkChevronDown class="inline ml-1" />
          </div>
          <div v-if="flags.is_activity_details_showing_more" class="grid grid-cols-11 mb-2">
            <template v-for="(item, index) in active_task_info" :key="item.label">
              <div
                class="col-span-5 pt-2.5 pb-2.5 mb-1 -mx-1 px-1"
                :class="{
                  'group hover:bg-gray-50 rounded-lg': item.property === 'activity_id' && is_schedule_editable,
                  '!mt-0 !mb-1 !pt-0 !pb-0': state.is_editing_activity_id && item.property === 'activity_id',
                }"
              >
                <div class="flex text-xs items-center">
                  <div
                    v-tippy="item?.label?.length > 13 ? item.label : ''"
                    class="flex items-center w-1/3 h-full text-gray-600 !font-normal"
                  >
                    <span class="truncate">
                      {{ item.label }}
                    </span>
                  </div>
                  <div class="w-2/3 font-medium text-gray-900 flex justify-between" :class="item.value_class">
                    <template v-if="state.is_editing_activity_id && item.property === 'activity_id'">
                      <TextElement
                        name="id"
                        :presets="['focused_underline', 'xs_variant']"
                        :add-classes="{
                          TextElement: {
                            input: '!text-xs !font-medium !text-gray-900',
                            inputContainer: '-ml-[9px] !bg-transparent',
                          },
                        }"
                        class="h-9"
                        @keydown="onKeydown"
                      >
                        <template #addon-after>
                          <div class="flex gap-3">
                            <IconHawkXClose
                              class="w-4 h-4 text-gray-600 cursor-pointer"
                              @click="state.is_editing_activity_id = false"
                            />
                            <IconHawkCheck
                              class="w-4 h-4 text-primary-600 cursor-pointer"
                              @click="onActivityIdChange"
                            />
                          </div>
                        </template>
                      </TextElement>
                    </template>
                    <template v-else>
                      {{ item.value() }}
                      <IconHawkPencilOne
                        v-if="item.property === 'activity_id' && is_schedule_editable"
                        class="w-4 h-4 text-gray-600 cursor-pointer group-hover:visible invisible mr-1"
                        @click="activateActivityIdEditor"
                      />
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="index % 2 === 0" class="col-span-1" />
            </template>
            <template v-for="(item, index) in Object.keys(custom_fields)" :key="item.label">
              <div class="col-span-5 mb-1">
                <div class="flex text-xs items-center hover:bg-gray-50 rounded-lg group -mx-1 px-1">
                  <div
                    v-tippy="item?.length > 13 ? item : ''"
                    class="flex items-center w-1/3 h-full text-gray-600 !font-normal"
                  >
                    <span class="truncate">
                      {{ item }}
                    </span>
                  </div>
                  <div class="w-2/3 font-medium text-gray-900">
                    <PmCustomFieldEditor
                      :name="item"
                      :type="custom_fields[item].type"
                      :value="active_task_custom_field_values[item]"
                    />
                  </div>
                </div>
              </div>
              <div v-if="(active_task_info.length + index) % 2 === 0" class="col-span-1" />
            </template>
          </div>
        </div>
      </div>
    </Vueform>
  </div>
</template>

<style lang="scss" scoped>
.date_time {
  :deep(.dp__main) {
    @apply -ml-2;
  }

  :deep(.dp__input) {
    border: 0 solid #3b82f6;
    background-color: transparent;
    @apply px-2;
    @apply py-2;
    @apply h-8;
    @apply text-sm;
    @apply font-semibold;
  }

  :deep(.dp__disabled) {
    @apply text-gray-900 cursor-text;
  }
}

.date-time-overdue {
  :deep(.dp__input) {
    @apply text-error-600;
  }
  :deep(.dp__disabled) {
    @apply text-error-600 cursor-text;
  }
}

.date-time-ahead {
  :deep(.dp__input) {
    @apply text-primary-600;
  }
  :deep(.dp__disabled) {
    @apply text-primary-600 cursor-text;
  }
}
</style>
