<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useI18nStore } from '~/common/stores/i18n.store.js';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useAttachments } from '~/project-management/composables/pm-attachments.composable.js';
import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import PmReferencesModal from '~/project-management/components/activity-details/pm-references-modal.vue';
import PmNewDependencyModal from '~/project-management/components/activity-details/pm-new-dependency-modal.vue';
import PmFieldMappingsModal from '~/project-management/components/activity-details/pm-field-mappings-modal.vue';
import PmActivityTrackingModal from '~/project-management/components/activity-details/pm-activity-tracking-modal.vue';
import PmUpdateProgress from '~/project-management/components/activity-details/pm-update-progress.vue';
import PmUpdateAutoProgress from '~/project-management/components/activity-details/pm-update-auto-progress.vue';

provide('location', 'pm-activity-details');

const $t = inject('$t');
const $date = inject('$date');
const $services = inject('$services');

const route = useRoute();

const auth_store = useAuthStore();
const chat_store = useChatStore();
const i18n_store = useI18nStore();
const task_store = useTasksStore();
const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();

const { files_to_attach, attachment_upload_modal } = useAttachments();
const { openDeleteTaskPopup } = useHelpers();

const user = auth_store?.logged_in_user_details?.user_id;

const {
  set_active_task_uid,
  update_activity,
  set_references,
  set_schedule_dirtiness,
  set_pm_comments_and_attachments,
} = project_management_store;

const {
  $g,
  flags,
  active_task,
  active_task_uid,
  active_view,
  active_schedule,
  schedule_dirtiness,
  is_schedule_editable,
  is_fullscreen,
  references,
  triggered_by,
  activity_details_page,
} = storeToRefs(project_management_store);
const { current_lang } = storeToRefs(i18n_store);

const state = reactive({
  is_visible: true,
  is_add_button_open: false,
  is_loading: true,
});

const update_progress_modal = useModal({
  component: PmUpdateProgress,
  attrs: {
    onClose() {
      update_progress_modal.close();
    },
  },
});

const references_modal = useModal({
  component: PmReferencesModal,
  attrs: {
    onClose: () => {
      references_modal.close();
    },
  },
});

const new_dependency_modal = useModal({
  component: PmNewDependencyModal,
  attrs: {
    onClose: () => {
      new_dependency_modal.close();
    },
  },
});

const field_mappings_modal = useModal({
  component: PmFieldMappingsModal,
  attrs: {
    onClose: () => {
      field_mappings_modal.close();
    },
  },
});

const update_progress_auto_modal = useModal({
  component: PmUpdateAutoProgress,
  attrs: {
    onClose() {
      update_progress_auto_modal.close();
    },
  },
});

const activity_tracking_modal = useModal({
  component: PmActivityTrackingModal,
  attrs: {
    onClose: () => {
      activity_tracking_modal.close();
    },
  },
});

const task_status_color_map = {
  'Not Started': 'gray',
  'In Progress': 'blue',
  'Completed': 'green',
};

const is_group_by_length_zero = computed(() => active_view.value.data.group_by.length === 0);

const add_button_items = computed(() => {
  return [
    [
      ...(is_schedule_editable.value
        ? [
            {
              label: $t('Dependency'),
              uid: 'dependency',
              on_click: () => {
                onAddDependency();
              },
            },
          ]
        : []),
      ...(!active_task.value.is_backend_save_pending
        ? [{
            label: $t('Attachment'),
            uid: 'attachment',
            on_click: () => {
              onAddAttachmentsClicked();
            },
          },
          ]
        : []),
      ...(!is_schedule_editable.value
        ? [
            {
              label: $t('Reference'),
              uid: 'reference',
              on_click: () => {
                references_modal.open();
              },
            },
          ]
        : []),
      ...(!is_schedule_editable.value && active_schedule.value.track_resources
        ? [
            {
              label: $t('Activity tracking'),
              uid: 'activity_tracking',
              on_click: () => {
                activity_tracking_modal.open();
              },
            },
          ]
        : []),
    ],
  ];
});

const three_dot_items = computed(() => {
  const items = [];

  if (
    !is_schedule_editable.value
    && active_task.value.type === $g.value.config.types.task
    && active_schedule.value.can_modify
  ) {
    items.push([
      'Setup progress sync',
      'setup_progress_sync',
      () => {
        update_progress_auto_modal.open();
      },
    ]);
    if (!active_task.value.auto_progress_sync?.is_enabled)
      items.push([
        'Update progress',
        'update_progress',
        () => {
          update_progress_modal.open();
        },
      ]);
  }

  if (is_schedule_editable.value)
    items.push([
      'Delete',
      'delete',
      () => {
        openDeleteTaskPopup(active_task.value, () => {
          $g.value.deleteTask(active_task.value.id);
          closeDetails();
        });
      },
    ]);

  else
    items.push(
      [
        'Sync properties',
        'field_mappings',
        () => {
          field_mappings_modal.open();
        },
      ],
      [
        'Progress history',
        'progress_history',
        () => {
          activity_details_page.value = 'progress-history';
        },
      ],
    );

  return items.map((item) => {
    return {
      label: $t(item[0]),
      uid: item[1],
      on_click: item[2],
    };
  });
});

const status_items = computed(() => {
  return [
    {
      label: $t('Not Started'),
      value: 'Not Started',
      on_click: () => {
        active_task.value.status = 'Not Started';
        $g.value.getTask(active_task.value.id).status = 'Not Started';
      },
    },
    {
      label: $t('In Progress'),
      value: 'In Progress',
      on_click: () => {
        active_task.value.status = 'In Progress';
        $g.value.getTask(active_task.value.id).status = 'In Progress';
      },
    },
    {
      label: $t('Completed'),
      value: 'Completed',
      on_click: () => {
        active_task.value.status = 'Completed';
        $g.value.getTask(active_task.value.id).status = 'Completed';
      },
    },
  ];
});

const dependencies = computed(() => {
  // NOTE: The next line is added to only re-compute the dependencies when active_schedule.value.relations is changed.
  const relations = active_schedule.value.relations;

  const active_task_obj = $g.value.getTask(active_task.value?.id);
  const sourceDeps = active_task_obj.$source.map(link_id => $g.value.getLink(link_id));
  const targetDeps = active_task_obj.$target.map(link_id => $g.value.getLink(link_id));

  const mapDeps = (deps, sourceOrTarget) => deps.map((dep) => {
    const task = $g.value.getTask(
      dep[sourceOrTarget === 'source' ? 'target' : 'source'],
    );

    if (!task)
      return null;

    const lag = Math.ceil(dep.lag ?? 0);

    return {
      link_id: dep.id,
      link_uid: dep.uid,
      id: task.id,
      uid: task.uid,
      wbs: $g.value.getWBSCode(task),
      name: task.text,
      type: dep.type,
      lag: lag === 1 ? `1 ${$t('day')}` : `${lag} ${$t('days')}`,
      status: task.status ? $t(task.status) : task.status,
    };
  });

  return [
    ...mapDeps(sourceDeps, 'source'),
    // TODO: Remove the comment when you want to display the predecessors of task from its details page
    // ...mapDeps(targetDeps, 'target'),
  ];
});

const attachments = computed(() => {
  const result = active_task.value?.attachments || [];
  return result.map(item => ({
    ...item,
    notes: item.note || null,
  }));
});

const is_empty_page = computed(() => {
  return !dependencies.value.length
  && !attachments.value.length
  && !references.value.length;
});

function closeDetails() {
  activity_details_page.value = '';
  set_active_task_uid(null);
}

function onAddDependency() {
  new_dependency_modal.patchOptions({
    attrs: {
      mode: 'add',
      initial_data: {
        type: 'FS',
        lag: 0,
      },
      dependencies: dependencies.value,
    },
  });
  new_dependency_modal.open();
}

function onEditDependency(item) {
  const link = $g.value.getLink(item.link_id);

  new_dependency_modal.patchOptions({
    attrs: {
      mode: 'edit',
      initial_data: {
        ...item,
        lag: Number.parseInt(item.lag, 10),
        activities: active_task_uid.value === link.target ? [link.source] : [link.target],
        source_or_target: active_task_uid.value === link.target ? 'target' : 'source',
        dependencies: [],
      },
    },
  });
  new_dependency_modal.open();
}

function getHierarchy(uid) {
  const parents = [];
  const id = active_schedule.value.activities[uid];
  $g.value.eachParent((task) => {
    parents.push({
      value: task.uid,
      label: task.text,
      uid: task.id,
      has_children: true,
      truncate_length: Math.min(task.text?.length, 20),
    });
  }, id);
  return parents.reverse();
}

async function onAddAttachmentsClicked() {
  if (is_fullscreen.value)
    await document.exitFullscreen();

  setTimeout(() => {
    attachment_upload_modal.open();
  }, 10);
}

watchEffect(() => {
  if (state.is_visible === true) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);
  }
});

watch(active_task_uid, async () => {
  state.is_loading = true;
  if (triggered_by.value === 'row-attachments' && !attachments.value?.length)
    onAddAttachmentsClicked();

  const dirtiness_stored = schedule_dirtiness.value;
  if (!is_schedule_editable.value || !active_task.value.is_backend_save_pending)
    await update_activity({ uid: active_task_uid.value }, true, ['attachments']);
  await set_pm_comments_and_attachments(false, true);
  set_schedule_dirtiness(dirtiness_stored);

  if (!is_schedule_editable.value || !active_task.value.is_backend_save_pending)
    await set_references($t);

  state.is_loading = false;
}, { immediate: true });

watch(() => flags.value.attachments_trigger_count, () => {
  if (triggered_by.value === 'row-attachments' && !attachments.value?.length)
    onAddAttachmentsClicked();
});
</script>

<template>
  <div class="px-6 pt-6 top-0 left-0 bg-white z-[2001]">
    <div class="flex">
      <div class="flex flex-col gap-1">
        <HawkBreadcrumbs
          v-if="is_group_by_length_zero"
          :items="getHierarchy(active_task?.uid)"
          :show_active_color="false"
          :max_tags_to_display="3"
          @crumbClicked="activity => set_active_task_uid(activity.value)"
        />
        <div v-else />
        <div class="flex items-center gap-1 font-semibold text-gray-900 text-md w-[500px]">
          {{ active_task?.text }} ({{ active_task?.id }})
        </div>
      </div>
      <div class="flex items-center h-10 gap-1 ml-auto">
        <div v-if="active_task.status" class="cursor-text">
          <HawkBadge
            size="md"
            class="pointer-events-none"
            custom_classes="whitespace-nowrap"
            :color="task_status_color_map[active_task.status]"
          >
            {{ active_task.status }}
          </HawkBadge>
        </div>
        <HawkMenu
          class="!border-0"
          :items="three_dot_items"
          additional_trigger_classes="!ring-0"
          additional_dropdown_classes="!top-8 w-[200px]"
        >
          <template #trigger>
            <HawkButton icon type="text" class="!h-10 !w-10">
              <IconHawkDotsVertical class="text-gray-500" />
            </HawkButton>
          </template>
        </HawkMenu>
        <HawkButton id="activity-details-close-button" icon type="text" class="!h-10 !w-10" @click="closeDetails">
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
    </div>
    <hr class="border-b border-gray-200 mt-2.5 -mx-6">
  </div>
  <div
    id="pm-activity-details-block"
    :key="`${active_task?.uid} ${current_lang}`"
    class="h-full bg-white scrollbar"
  >
    <div v-show="activity_details_page === ''" class="px-6">
      <PmActivityDetailsOverview />
      <hr class="my-3 -mx-6">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2 font-semibold text-gray-900 text-md">
          {{ $t('Additional details') }}
          <HawkLoader v-if="state.is_loading" container_class="" :height="4" :width="4" />
        </div>
        <HawkMenu
          :items="add_button_items"
          additional_trigger_classes="!ring-0"
          @open="state.is_add_button_open = true"
          @close="state.is_add_button_open = false"
        >
          <template #trigger>
            <HawkButton type="text">
              {{ $t('Add') }}
              <IconHawkChevronUp v-if="state.is_add_button_open" class="text-gray-700" />
              <IconHawkChevronDown v-else class="text-gray-700" />
            </HawkButton>
          </template>
        </HawkMenu>
      </div>
      <template v-if="is_empty_page && !active_task.is_backend_save_pending">
        <p class="my-10 text-sm italic text-gray-400">
          {{ $t('Add dependencies, attachments, etc to this activity.') }}
        </p>
      </template>
      <template v-else>
        <PmDependenciesSection
          :dependencies="dependencies"
          @add-dependency="onAddDependency"
          @edit-dependency="onEditDependency"
        />
        <template v-if="!active_task.is_backend_save_pending">
          <PmAttachmentsSection
            :attachments="attachments"
            @add-attachment="onAddAttachmentsClicked"
          />
          <PmReferencesSection
            v-if="!is_schedule_editable"
            :is_loading="state.is_loading"
            @add-reference="references_modal.open()"
          />
          <PmActivityTrackingSection
            v-if="!is_schedule_editable"
            @add-tracking="activity_tracking_modal.open"
          />
        </template>
      </template>
      <template v-if="active_task.is_backend_save_pending">
        <p class="2xl:min-h-[calc(100vh-575px)] min-h-[calc(100vh-690px)] my-10 text-sm text-gray-400 italic">
          <!-- TODO: Finalize this text before release -->
          {{ $t('Note: Please save your progress to be able to add attachments, automations, and references.') }}
        </p>
      </template>
      <template v-else>
        <PmCommentsSection :class="state.is_loading && triggered_by !== 'row-comments' ? 'hidden' : ''" />
        <div
          v-if="!is_schedule_editable || chat_store?.comments?.length"
          :class="!(state.is_loading && triggered_by !== 'row-comments') ? 'hidden' : ''"
          class="pt-2 pb-2 pr-2 mt-2 -mb-2"
        >
          <div class="flex items-center gap-2 font-semibold text-gray-900 text-md">
            {{ $t('Comments') }}
            <HawkLoader container_class="" :height="4" :width="4" />
          </div>
        </div>
      </template>
    </div>
    <PmProgressHistory v-if="activity_details_page === 'progress-history'" />
  </div>
</template>
