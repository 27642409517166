/* eslint-disable no-undef */
import tippy from 'tippy.js';
import { storeToRefs } from 'pinia';
import { useTippy } from 'vue-tippy';
import { useModal } from 'vue-final-modal';
import { computed, h, inject, nextTick, onUnmounted, ref } from 'vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { useFilters } from '~/project-management/composables/pm-filters.composable';
import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { highlightElement, waitForElement } from '~/common/utils/common.utils';
import useEmitter from '~/common/composables/useEmitter';
import PmCustomizeColumns from '~/project-management/components/pm-customize-columns.vue';
import PmActivityContextMenu from '~/project-management/components/pm-activity-context-menu.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

export function useEvents() {
  const $t = inject('$t');

  const { $toast } = useCommonImports();
  const project_management_store = useProjectManagementStore();
  const { filterTasks } = useFilters();
  const { openDeleteTaskPopup } = useHelpers();
  const emitter = useEmitter();

  const {
    modify_config,
    reload_data,
    set_view_dirtiness,
    set_active_task_uid,
    set_schedule_dirtiness,
    set_all_tasks_open_state,
    set_children_tasks,
    set_pm_comments_and_attachments,
    update_activity_backend,
  } = project_management_store;
  const {
    $g,
    flags,
    markers,
    active_tab,
    active_view,
    groups_cache,
    triggered_by,
    tippy_target,
    resource_mode,
    is_pm_loading,
    active_task_uid,
    is_initializing,
    is_mini_loading,
    active_schedule,
    datepicker_tippy,
    selected_task_id,
    filtered_task_ids,
    pm_loading_message,
    schedule_dirtiness,
    is_schedule_dynamic,
    active_schedule_data,
    is_schedule_editable,
    activity_details_page,
    is_recalculation_enabled,
  } = storeToRefs(project_management_store);

  const wbs_level = computed(() => active_view.value.data.wbs_level);

  let radio_els = [];

  const attached_events = ref([]);
  const gantt_sort_field = ref(null);

  const state = reactive({
    before_task_move_parent: null,
    is_data_initialized: true,
  });

  const delete_popup = useModal({
    component: HawkDeletePopup,
  });

  const customize_columns_modal = useModal({
    component: PmCustomizeColumns,
    attrs: {
      onClose() {
        customize_columns_modal.close();
      },
    },
  });

  function handleSubTaskAddition(parent_task) {
    if (parent_task?.resources?.length) {
      parent_task.resources = [];
      update_activity_backend(
        parent_task.id,
        {
          resources: [],
        },
      );
    }
  }

  window.gantt.$outdentByMove = outdentByMove;
  async function outdentByMove(id) {
    $g.value.ext.inlineEditors.hide();

    await nextTick();

    const task = $g.value.getTask(id);
    const parent_task = $g.value.getTask(task.parent);

    if (!parent_task?.parent)
      return;

    const index = $g.value.getTaskIndex(task.parent);
    $g.value.moveTask(id, index, parent_task.parent);

    const children = $g.value.getChildren(parent_task.id);

    if (children.length === 1 && $g.value.getTask(children[0]).type === $g.value.config.types.surrogate) {
      $g.value.deleteTask(children[0]);
      $g.value.getTask(parent_task.id).type = $g.value.config.types.task;
      $g.value.getTask(parent_task.id)._original_type = $g.value.config.types.task;
      $g.value.updateTask(parent_task.id);
    }
  }

  window.gantt.$indentOrOutdentTask = indentOrOutdentTask;
  function indentOrOutdentTask(action, e, force_task_id) {
    const active_node = $g.value.ext.keyboardNavigation.getActiveNode();
    const task_id = force_task_id ?? active_node?.id;

    if (!$g.value.isTaskExists(task_id))
      return;

    const task = $g.value.getTask(task_id);

    if (task.type === $g.value.config.types.surrogate && typeof e !== 'undefined') {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return;
    }

    if (action === 'indent') {
      if (typeof e !== 'undefined')
        e.stopImmediatePropagation();
      const prev_id = $g.value.getPrevSibling(task_id);
      if (prev_id) {
        const new_parent = $g.value.getTask(prev_id);
        new_parent.type = $g.value.config.types.project;
        new_parent._original_type = $g.value.config.types.wbs;
        $g.value.moveTask(task_id, $g.value.getChildren(new_parent.id).length, new_parent.id);
        new_parent.$open = true;
        handleSubTaskAddition(new_parent);
        $g.value.updateTask(task_id);
        $g.value.updateTask(new_parent.id);

        const children = $g.value.getChildren(new_parent.id);
        for (const child of children) {
          const c = $g.value.getTask(child);
          if (c.type === $g.value.config.types.surrogate) {
            $g.value.deleteTask(child);
            break;
          }
        }

        createSurrogateTask(new_parent.id);
      }
    }
    else if (action === 'outdent') {
      const parent = $g.value.getTask(task.parent);

      if (parent) {
        let only_surrogate = true;

        const children = $g.value.getChildren(parent.id);
        for (const child of children) {
          const c = $g.value.getTask(child);
          if (c.id !== task_id && c.type !== $g.value.config.types.surrogate) {
            only_surrogate = false;
            break;
          }
        }

        if (only_surrogate) {
          for (const child of children) {
            const c = $g.value.getTask(child);
            if (c.type === $g.value.config.types.surrogate)
              $g.value.deleteTask(child);
          }
          parent.type = $g.value.config.types.task;
          parent._original_type = $g.value.config.types.task;
        }
      }
    }

    modify_config({ key: 'wbs_level', value: Number.MAX_SAFE_INTEGER });
    modify_config({ key: 'wbs_level_max', value: Number.MAX_SAFE_INTEGER });
    $g.value.render();
    $g.value.ext.keyboardNavigation.focus(active_node);
  }

  function beforeUnloadHandler(event) {
    if (is_schedule_editable.value && schedule_dirtiness.value) {
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
    }
  }

  function keydownHandler(e) {
    // The below if condition is added to cause no disruption to the keyboard navigation in the datepicker
    if (
      document.querySelector('.dp__main')
      && ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.code)
    )
      return;

    // The below if condition doesn't allow the following when one of the these is active - modal, slide-over (with that class), resources-editor, datepicker or the gantt-chart is not active:
    // ArrowLeft or ArrowRight - For preventing gantt's indent or outdent task
    // Tab - For preventing gantt's tab navigation
    // Delete - For preventing gantt's delete task popup
    // Space - For preventing gantt's open/close task
    if (
      (
        document.querySelector('.vfm__content')
        || document.querySelector('.slide-over')
        || document.querySelector('#resources-editor')
        || document.querySelector('.dp__main')
        || active_tab.value !== 'gantt-chart'
      ) && ['ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Space'].includes(e.code)
    // NOTE: Escape is not a part of this list because it prevents closing the modal when escape is pressed
    ) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      return;
    }

    // The below if condition doesn't allow up/down keyboard navigation when the inline editor is active
    if ($g.value.ext.inlineEditors?.isVisible?.() && (e.code === 'ArrowUp' || e.code === 'ArrowDown')) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      return;
    }

    const active_node = $g.value?.ext?.keyboardNavigation?.getActiveNode?.();

    if (e.code === 'ArrowLeft' || e.code === 'ArrowRight')
      handleIndentOrOutdent(e);
    else if (e.code === 'Escape')
      handleEscapeKey();
    else if (e.code === 'Tab')
      handleTabKey(e, active_node);
    else if (e.code === 'Delete')
      handleDeleteKey(e, active_node);
    else if (e.code === 'Space')
      handleSpaceKey(e, active_node);
  }

  function handleIndentOrOutdent(e) {
    if (!$g.value.ext.inlineEditors.isVisible() && is_schedule_editable.value && e.shiftKey) {
      const action = e.code === 'ArrowLeft' ? 'outdent' : 'indent';
      indentOrOutdentTask(action, e);
    }
  }

  function handleEscapeKey() {
    if (datepicker_tippy.value)
      $g.value.ext.inlineEditors.hide();
  }

  function handleTabKey(e, active_node) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (!$g.value.isTaskExists(active_node?.id))
      return;

    const grid_columns = $g.value.getGridColumns();
    const column_index = $g.value.getColumnIndex(active_node.column);
    let focus_payload = null;

    if (e.shiftKey)
      focus_payload = shiftTabLogic(column_index, active_node, grid_columns);
    else
      focus_payload = tabLogic(column_index, active_node, grid_columns);

    if (focus_payload)
      $g.value.ext.keyboardNavigation.focus(focus_payload);
  }

  function shiftTabLogic(column_index, active_node, grid_columns) {
    if (column_index === 0) {
      const prev = $g.value.getPrev(active_node.id);
      if (!prev)
        return null;
      return { type: 'taskCell', id: prev, column: grid_columns[grid_columns.length - 1].name };
    }
    else {
      return { type: 'taskCell', id: active_node.id, column: grid_columns[column_index - 1].name };
    }
  }

  function tabLogic(column_index, active_node, grid_columns) {
    if (column_index === grid_columns.length - 1) {
      const next = $g.value.getNext(active_node.id);
      if (!next)
        return null;
      return { type: 'taskCell', id: next, column: grid_columns[0].name };
    }
    else {
      return { type: 'taskCell', id: active_node.id, column: grid_columns[column_index + 1].name };
    }
  }

  function handleDeleteKey(e, active_node) {
    if ($g.value.ext.inlineEditors.isVisible())
      return;
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (is_schedule_editable.value)
      openDeleteTaskPopup($g.value.getTask(active_node.id));
  }

  function handleSpaceKey(e, active_node) {
    if ($g.value.ext.inlineEditors.isVisible() || flags.value.is_search_visible)
      return;
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (!$g.value.isTaskExists(active_node?.id))
      return;
    const task = $g.value.getTask(active_node?.id);
    if (task.$open)
      $g.value.close(active_node?.id);
    else
      $g.value.open(active_node?.id);
  }

  window.addEventListener('beforeunload', beforeUnloadHandler);

  window.addEventListener('keydown', keydownHandler, true);

  function clickHandler(event) {
    let target = event.target.closest('#resources-editor');
    if (!target)
      emitter.emit('hide_resources_editor');
    target = event.target.closest('#select-columns-button');
    if (target)
      gantt.$onGanttAddClick(event);
  }

  window.addEventListener('click', clickHandler, true);

  function checkAllChildren(parent) {
    const parent_task = $g.value.getTask(parent);

    if (parent_task.type !== $g.value.config.types.project)
      return;

    let only_surrogate = true;

    const children = $g.value.getChildren(parent);
    for (const child of children) {
      const c = $g.value.getTask(child);
      if (c.type !== $g.value.config.types.surrogate) {
        only_surrogate = false;
        break;
      }
    }

    if (only_surrogate) {
      for (const child of children) {
        const c = $g.value.getTask(child);
        if (c.type === $g.value.config.types.surrogate)
          $g.value.deleteTask(child);
      }
      parent_task.type = $g.value.config.types.task;
      parent_task._original_type = $g.value.config.types.task;
      $g.value.updateTask(parent);
    }
  }

  function createSurrogateTask(parent) {
    const uid = crypto.randomUUID().substring(0, 8);
    $g.value.addTask({
      type: $g.value.config.types.surrogate,
      unscheduled: true,
      readonly: true,
      duration: 0,
      text: '',
      id: uid,
      parent,
      uid,
    });
  }

  function handleLinkAdditionAndDeletion(operation, link) {
    const source_task = $g.value.getTask(link.source);
    if (operation === 'add') {
      if (!source_task.successors)
        source_task.successors = [];
      source_task.successors.push(link.target);
    }
    else if (operation === 'delete') {
      if (source_task.successors?.includes?.(link.target))
        source_task.successors = source_task.successors.filter((s, index, self) =>
          s !== link.target || index !== self.indexOf(link.target));

      $g.value.refreshTask(link.source);
    }
  }

  function onRadioChange(e) {
    radio_els.forEach((item) => {
      item.parentNode.classList.toggle('active', e.target === item || e.target.value === item.value);
    });
    // eslint-disable-next-line @typescript-eslint/no-invalid-this
    if (this.checked) {
      // eslint-disable-next-line @typescript-eslint/no-invalid-this
      resource_mode.value = this.value;
      $g.value.getDatastore($g.value.config.resource_store).refresh();
    }
  }

  function setupGanttEvents(layer_ids, setupTaskLayers) {
    // let delay = false;
    let idParentBeforeDeleteTask = 0;

    // $g.value.attachAll((...args) => {
    //   const eventName = args[0];
    //   const eventArguments = [].slice.call(args, 1);
    //   logger.log(eventName, eventArguments);
    // });

    attached_events.value.push(
      $g.value.attachEvent('onBeforeLightbox', () => false),
      // $g.value.attachEvent('onBeforeParse', () => $g.value.clearAll()), // TODO: this might not be needed, but check anyways
      $g.value.attachEvent('onEmptyClick', (e) => {
        $g.value.ext.inlineEditors.hide();

        const domHelpers = $g.value.utils.dom;
        const closest = domHelpers.closest(e.target, `[${$g.value.config.link_attribute}]`);

        if (closest) {
          const id = closest.getAttribute($g.value.config.link_attribute);
          const link = $g.value.getLink(id);
          const source = $g.value.getTask(link.source);
          const target = $g.value.getTask(link.target);

          delete_popup.patchOptions(
            {
              attrs: {
                header: $t('Delete link'),
                content: `${$t('Are you sure you want to delete the link between')} "${source.text}" ${$t('and')} "${target.text}"?`,
                button_text: $t('Delete'),
                onClose() {
                  delete_popup.close();
                },
                confirm: () => {
                  $g.value.deleteLink(id);
                  delete_popup.close();
                },
              },
            },
          );
          if (is_schedule_editable.value)
            delete_popup.open();
        }
      }),
      $g.value.attachEvent('onBeforeTaskDisplay', (id, task) => {
        task.bar_height = [$g.value.config.types.project].includes(task.type) ? 10 : 'full';

        if (groups_cache.value?.length)
          return true;

        return filtered_task_ids.value.includes(id);
      }),
      $g.value.attachEvent('onLinkDblClick', () => {
        return false;
      }),
      $g.value.attachEvent('onBeforeRowResize', () => {
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onBeforeRowDragMove', () => {
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onBeforeTaskDrag', () => {
        flags.value.is_task_being_dragged = true;
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onBeforeTaskDelete', (id) => {
        idParentBeforeDeleteTask = $g.value.getParent(id);
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onBeforeLinkAdd', () => {
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onBeforeLinkDelete', () => {
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onBeforeLinkUpdate', () => {
        return is_schedule_editable.value;
      }),
      $g.value.attachEvent('onParse', () => {
        gantt.$addMarkers();

        // TODO: Check dynamic schedules
        if (is_recalculation_enabled.value) {
          let max_level = 0;
          $g.value.eachTask((task) => {
            const task_level = $g.value.calculateTaskLevel(task);
            if (!flags.value.set_wbs_to_max)
              task.$open = task_level < 1;
            if (task_level > max_level)
              max_level = task_level;
          });

          if (flags.value.set_wbs_to_max || wbs_level.value === Number.MAX_SAFE_INTEGER) {
            modify_config({ key: 'wbs_level', value: max_level });
            flags.value.set_wbs_to_max = false;
          }

          modify_config({ key: 'wbs_level_max', value: max_level });
        }
        if (flags.value.expand_all_after_parse) {
          flags.value.expand_all_after_parse = false;
          set_all_tasks_open_state(true);
        }

        if (is_schedule_editable.value)
          $g.value.batchUpdate(() => {
            $g.value.eachTask((item) => {
              if (!item.type || ![$g.value.config.types.project].includes(item.type))
                return;

              let no_surrogate = true;
              $g.value.eachTask((child) => {
                if (child.type === $g.value.config.types.surrogate)
                  no_surrogate = false;
              }, item.id);

              if (no_surrogate)
                createSurrogateTask(item.id);
            });
          });
      }),
      $g.value.attachEvent('onGanttReady', () => {
        const tooltips = $g.value.ext.tooltips;
        tooltips.tooltipFor({
          selector: '.gantt_grid_head_cell',
          html: (_event, node) => node.innerText,
        });
        const grid = $g.value.$ui.getView('grid');
        if (grid)
          grid.attachEvent('onBeforeColumnDragStart', column => !['wbs', 'select-columns'].includes(column.draggedColumn.name));

        for (const layer_id of layer_ids.value)
          $g.value.removeTaskLayer(layer_id);

        layer_ids.value = [];
        setupTaskLayers();
        is_pm_loading.value = false;
        pm_loading_message.value = '';
      }),
      $g.value.attachEvent('onBeforeGanttRender', () => {
        try {
          filterTasks();
        }
        catch (error) {
          filtered_task_ids.value = [];
          logger.error(error);
        }
      }),
      // $g.value.attachEvent('onDataRender', () => {
      //   if (state.is_data_initialized && $g.value.isTaskExists(active_schedule_data.value?.data?.[0]?.id)) {
      //     state.is_data_initialized = false;
      //     setupOverloadedResources();
      //   }
      // }),
      $g.value.attachEvent('onGanttRender', () => {
        if (is_initializing.value) {
          is_initializing.value = false;
          $g.value.showDate(new Date());
          set_pm_comments_and_attachments(true, true);
        }

        const tooltips = $g.value.ext.tooltips;
        tooltips.tooltipFor({
          selector: '.resource-overloaded',
          html: () => `${$t('Resource overloaded')}`,
        });

        tippy('.weight-problem[data-tippy-content]');

        radio_els = [].slice.call($g.value.$container.querySelectorAll('[name=\'resource-mode\']'));
        radio_els.forEach((radio) => {
          $g.value.eventRemove(radio, 'change', onRadioChange);
          $g.value.event(radio, 'change', onRadioChange);
        });
      }),
      $g.value.attachEvent('onColumnResizeEnd', () => {
        set_view_dirtiness(true);
        return true;
      }),
      $g.value.attachEvent('onGridResizeEnd', () => {
        set_view_dirtiness(true);
        return true;
      }),
      $g.value.attachEvent('onGanttScroll', () => {
        tippy('.weight-problem[data-tippy-content]');
      }),
      $g.value.attachEvent('onTaskDrag', () => {
        return true;
      }),
      $g.value.attachEvent('onTaskRowClick', (_id, row_el) => {
        const classes = row_el.getAttribute('class');

        if (classes?.includes('gantt-context-menu'))
          gantt.$showTaskThreeDotMenu(row_el, row_el.getBoundingClientRect());

        const closest_comments_el = row_el.closest('.gantt-comments');
        if (closest_comments_el)
          gantt.$onGanttComments(closest_comments_el);

        const closest_attachments_el = row_el.closest('.gantt-attachments');
        if (closest_attachments_el)
          gantt.$onGanttAttachments(closest_attachments_el);
      }),
      $g.value.attachEvent('onTaskClick', async (id, e) => {
        const el_target = e?.target;
        const task = $g.value.getTask(id);

        const is_chart_clicked = $g.value.utils.dom.closest(el_target, '.gantt_task_line');
        if (is_chart_clicked) {
          set_active_task_uid(task.uid);
          await nextTick();
          triggered_by.value = 'task-bar';
        }

        function findTargetWithClass(element, class_name) {
          if (element?.classList?.contains(class_name))
            return element;
          if (element?.parentElement?.classList?.contains(class_name))
            return element.parentElement;
          if (element?.parentElement?.parentElement?.classList?.contains(class_name))
            return element.parentElement.parentElement;
          return null;
        }

        const actions = [
          { class_name: 'add-task', action: gantt.$addTask },
          { class_name: 'add-milestone', action: gantt.$addMilestone },
          { class_name: 'trigger-activity-details', action: () => gantt.$triggerActivityDetails(task) },
        ];

        for (const { class_name, action } of actions) {
          const class_found = findTargetWithClass(el_target, class_name);
          if (class_found)
            return action(class_found);
        }

        if (!is_schedule_dynamic.value)
          return true;

        if (!el_target?.classList?.contains?.('gantt_tree_icon') || !task?.$has_child)
          return true;

        const children = $g.value.getChildren(id);
        if (children.length || task?.is_loading_children)
          return true;

        task.is_loading_children = true;
        const newly_added_activities = await set_children_tasks(id);
        task.is_loading_children = false;
        $g.value.clearAll();
        flags.value.set_wbs_to_max = true;
        $g.value.parse(active_schedule_data.value);
        flags.value.resources_section_reload_count++;

        $g.value.eachParent((t) => {
          t.$open = true;
          $g.value.open(t.id);
        }, id);
        $g.value.open(id);
        $g.value.eachTask((t) => {
          t.$open = true;
          $g.value.open(t.id);
        }, id);
        $g.value.showTask(id);
        await set_pm_comments_and_attachments(true, true, true, true, newly_added_activities);

        return true;
      }),
      $g.value.attachEvent('onAfterSort', (field, direction) => {
        if (gantt_sort_field.value !== field) {
          gantt_sort_field.value = field;
          return;
        }
        if (direction === false) {
          set_schedule_dirtiness();
          gantt_sort_field.value = '';
          $g.value._sort.name = null;
          reload_data(true);
          window.gantt.$setupColumns();
        }
      }),
      $g.value.attachEvent('onAfterTaskDrag', (id, _mode) => {
        flags.value.is_task_being_dragged = false;
        $g.value.updateTask(id);
      }),
      $g.value.attachEvent('onAfterTaskUpdate', (_id, task) => {
        active_schedule.value.activities[task.uid] = task;
        set_schedule_dirtiness();
      }),
      $g.value.attachEvent('onBeforeTaskMove', (_id, parent) => {
        if (!is_schedule_editable.value)
          return false;
        const parent_task = $g.value.getTask(parent);
        if (!parent_task)
          return false;
        return [
          $g.value.config.types.task,
          $g.value.config.types.project,
        ].includes(parent_task.type);
      }),
      $g.value.attachEvent('onAfterTaskMove', (id, parent) => {
        const task = $g.value.getTask(id);

        const parent_task = $g.value.getTask(parent);
        if (!parent_task)
          return;
        if (![$g.value.config.types.wbs, $g.value.config.types.project].includes(parent_task._original_type)) {
          parent_task.type = $g.value.config.types.project;
          parent_task._original_type = parent_task.is_root ? $g.value.config.types.project : $g.value.config.types.wbs;
        }
        $g.value.updateTask(parent);
        set_schedule_dirtiness();
      }),
      $g.value.attachEvent('onRowDragStart', (id) => {
        state.before_task_move_parent = $g.value.getTask(id).parent;
        return true;
      }),
      $g.value.attachEvent('onBeforeRowDragEnd', (_id, parent, t_index) => {
        const children = $g.value.getChildren(parent);
        return !(children.length && children.length <= t_index + 1);
      }),
      $g.value.attachEvent('onRowDragEnd', (_id, target) => {
        checkAllChildren(state.before_task_move_parent);
        if ($g.value.getChildren(target).length === 1)
          createSurrogateTask(target);
        $g.value.getTask(target).$open = true;
      }),
      $g.value.attachEvent('onAfterLinkAdd', (_id, link) => {
        handleLinkAdditionAndDeletion('add', link);
        set_schedule_dirtiness();
        active_schedule.value.relations = $g.value.getLinks();
      }),
      $g.value.attachEvent('onAfterLinkDelete', (_id, link) => {
        handleLinkAdditionAndDeletion('delete', link);
        set_schedule_dirtiness();
        active_schedule.value.relations = $g.value.getLinks();
      }),
      $g.value.attachEvent('onAfterLinkUpdate', () => {
        set_schedule_dirtiness();
        active_schedule.value.relations = $g.value.getLinks();
      }),
      $g.value.attachEvent('onTemplatesReady', () => {
        tippy('.weight-problem[data-tippy-content]');
      }),
      $g.value.attachEvent('onAfterTaskAdd', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterAutoSchedule', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterBatchUpdate', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterBranchLoading', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterLightbox', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterQuickInfo', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterRedo', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterRowResize', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterTaskAutoSchedule', set_schedule_dirtiness),
      $g.value.attachEvent('onAfterTaskDelete', set_schedule_dirtiness),
    );
  }

  function setupGanttFunctions() {
    gantt.$showTaskThreeDotMenu = (that, rectangle) => {
      if (!is_schedule_editable.value)
        return;

      const tippy = useTippy(() => document.body, {
        appendTo: tippy_target.value,
        theme: 'pm-date-editor',
        content: h(PmActivityContextMenu, {
          task: $g.value.getTask(that.getAttribute('data-task-id')),
          translate: (key) => {
            return $t(key);
          },
          on_task_created: (task) => {
            active_schedule.value.activities[task.uid] = task;
          },
          on_subtask_added: (parent_task) => {
            handleSubTaskAddition(parent_task);
            $g.value.updateTask(parent_task.id);
          },
          onSelectTask: (id) => {
            selected_task_id.value = id;
          },
          onClose: () => {
            tippy.hide();
            $g.value.ext.inlineEditors.hide();
          },
        }),
        showOnCreate: true,
        arrow: false,
        interactive: true,
        animation: false,
        trigger: 'manual',
        placement: 'bottom-start',
        offset: (Math.abs(rectangle.x - window.innerWidth) < 200) ? [-137, -30] : [0, -30],
        hideOnClick: true,
        maxWidth: 539,
        getReferenceClientRect() {
          return rectangle;
        },
      });
    };

    gantt.$onGanttAttachments = async (element) => {
      activity_details_page.value = '';
      const id = element.getAttribute('data-task-id');
      const task = $g.value.getTask(id);
      set_active_task_uid(task.uid);
      if (active_task_uid.value === task.uid)
        flags.value.attachments_trigger_count++;
      try {
        triggered_by.value = 'row-attachments';
        await waitForElement('attachments').then((element) => {
          highlightElement(element, ['!-ml-3', '!pl-3', '!-mr-3', '!pr-3']);
        });
      }
      catch (error) {
        logger.error('Error while waiting for attachments element', error);
      }
    };
    gantt.$onGanttComments = async (element) => {
      activity_details_page.value = '';
      const id = element.getAttribute('data-task-id');
      const task = $g.value.getTask(id);
      set_active_task_uid(task.uid);
      try {
        triggered_by.value = 'row-comments';
        await waitForElement('comments-done').then(async (element) => {
          setTimeout(() => {
            highlightElement(
              element,
              ['!-ml-3', '!pl-3', '!-mr-3', '!pr-5'],
              { behavior: 'smooth', block: 'end', inline: 'nearest' },
            );
          }, 250);
        });
      }
      catch (error) {
        logger.error('Error while waiting for comments element', error);
      }
    };
    gantt.$addTaskOrMilestone = (type, element, identity, _wbs) => {
      if (is_mini_loading.value)
        return $toast({
          title: $t('Please wait'),
          text: $t('Your current action cannot be completed at this moment.'),
          type: 'warning',
        });
      if (active_task_uid.value)
        return;
      const id = identity || element.getAttribute('data-task-id');
      const task = $g.value.getTask(id);

      task.type = type;
      task.text = '';
      task.not_finalized = true;
      task.unscheduled = false;
      task.readonly = false;
      task.status = 'Not started';
      task.is_backend_save_pending = true;

      active_schedule.value.activities[task.uid] = task;

      $g.value.updateTask(id);
      createSurrogateTask(task.parent);
      $g.value.render();

      setTimeout(() => {
        const inlineEditors = $g.value.ext.inlineEditors;
        inlineEditors.startEdit(id, 'text');
        set_schedule_dirtiness(true);
      }, 15);
    };
    gantt.$addTask = (element) => {
      gantt.$addTaskOrMilestone($g.value.config.types.task, element);
    };
    gantt.$addMilestone = (element) => {
      gantt.$addTaskOrMilestone($g.value.config.types.milestone, element);
    };
    gantt.$onGanttAddClick = (event, _that) => {
      event.stopImmediatePropagation();
      customize_columns_modal.open();
    };
    gantt.$addMarkers = () => {
      markers.value.forEach(marker => $g.value.addMarker(marker));
    };
    // TODO: check if this is needed
    // gantt.$addDateRangeBuffer = () => {
    //   const range = $g.value.getSubtaskDates();
    //   const scale_unit = $g.value.getState().scale_unit;
    //   const date_buffer_amount = 1;

    //   if (range.start_date && range.end_date) {
    //     $g.value.config.start_date = $g.value.calculateEndDate(
    //       range.start_date,
    //       -date_buffer_amount,
    //       scale_unit,
    //     );
    //     $g.value.config.end_date = $g.value.calculateEndDate(
    //       range.end_date,
    //       date_buffer_amount,
    //       scale_unit,
    //     );
    //   }
    // };
  }

  onUnmounted(() => {
    window.removeEventListener('click', clickHandler, true);
    window.removeEventListener('keydown', keydownHandler, true);
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  });

  return {
    setupGanttEvents,
    setupGanttFunctions,
  };
}
