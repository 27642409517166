<script>
import { mapStores } from 'pinia';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { Color } from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Image from 'tiptap-extension-image-freely';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { ImagePlaceholder, ImageUploadExtension } from 'tiptap-extension-image-upload';

import { useAuthStore } from '~/auth/stores/auth.store.js';
import { dataURLtoFile, isFileExtensionAllowed } from '~/common/utils/common.utils.js';
import { uploadToStorage } from '~/common/utils/storage-upload.util.js';
import { makeSuggestionExtension } from '~/common/components/organisms/hawk-wysiwyg-editor/editor-suggestion-plugin.js';
import TableCell from '~/common/components/organisms/hawk-wysiwyg-editor/editor-table-cell-plugin.js';

export default {
  components: {
    EditorContent,
  },
  props: {
    placeholder_text: {
      type: String,
      required: false,
      default: '',
    },
    initial_content: {
      type: String,
      required: false,
      default: '',
    },
    plugins: {
      type: Array,
      required: false,
      default: () => ['tables', 'images'],
      validator: arr => arr.every(item => ['tags', 'mentions', 'tables', 'images', 'paste-files', 'disable-enter', 'formulas'].includes(item)),
    },
    mention_items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    tag_items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    formula_items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    editor_enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    menu_enabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    enable_common_rtf_features: {
      type: Boolean,
      required: false,
      default: true,
    },
    autofocus: {
      type: [String, Boolean],
      default: false,
    },
    // eslint-disable-next-line vue/prop-name-casing
    modelValue: {
      type: String,
      default: '',
    },
    editor_classes: {
      type: String,
      default: '',
    },
    resize_config: {
      type: Object,
      default: () => ({ quality: 1, maxWidth: 800, maxHeight: 600 }),
    },
    append_mentions_to: {
      type: Element,
      default: null,
    },
    display_images_fullwidth: {
      type: Boolean,
      default: false,
    },
    focus_position: {
      default: true, // accepts 'start' | 'end' | 'all' | number | boolean | null (false)
    },
    header_level: {
      type: Number,
      required: false,
    },
  },
  emits: ['update:modelValue', 'ctrlEnter', 'input', 'initialized', 'blur', 'focus', 'filesPaste'],
  data() {
    return {
      editor: null,
      wysiwyg_view: null,
      wysiwyg_schema: null,
      wysiwyg_state: null,
      mention_options: null,
      mentions_plugin: null,
      mentioned_uids: [],
      editor_content: '',
      is_loading: true,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
    logged_in_user_id() {
      return this.authStore?.logged_in_user_details?.user_id;
    },
    mentioned_users() {
      return this.mentioned_uids.map(uid => ({ uid }));
    },
    state_of_plugins() {
      return {
        'tags': this.plugins.includes('tags'),
        'formulas': this.plugins.includes('formulas'),
        'mentions': this.plugins.includes('mentions'),
        'tables': this.plugins.includes('tables'),
        'images': this.plugins.includes('images'),
        'paste-files': this.plugins.includes('paste-files'),
        'disable-enter': this.plugins.includes('disable-enter'),
      };
    },
  },
  watch: {
    modelValue(value) {
      if (this.editor.getHTML() === value)
        return;
      this.editor.commands.setContent(value, false);
    },
    editor_enabled(bool) {
      this.editor.commands.setTextSelection(0);
      if (bool)
        this.focusEditor();
      else
        this.blurEditor();
      this.editor.setEditable(bool);
    },
    logged_in_user_id: {
      handler(uid) {
        if (!uid)
          return;
        const css = `
          [data-id="${uid}"] {
            color: #B54708 !important;
            background-color: #FFFAEB !important;
          }`;
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        head.appendChild(style);
        style.appendChild(document.createTextNode(css));
      },
      immediate: true,
    },
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    this.editor?.destroy();
  },
  methods: {
    async initialize() {
      this.is_loading = true;
      this.editor = new Editor({
        appendMentionsTo: this.append_mentions_to,
        extensions: this.getExtensions(),
        content: await this.preprocessHtml(this.initial_content || this.modelValue),
        autofocus: this.editor_enabled ? this.autofocus : false,
        editable: this.editor_enabled,
        editorProps: {
          handleDOMEvents: {
            keydown: (_view, event) => {
              if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
                this.$emit('ctrlEnter');
                return true;
              }
              return false;
            },
          },
        },
        parseOptions: {
          preserveWhitespace: 'full',
        },
        onUpdate: () => {
          const html = this.editor.getHTML();
          this.mentioned_uids = this.getMentionsFromHtml(html);
          this.$emit('update:modelValue', html);
          if (this.state_of_plugins.mentions)
            this.$emit('input', { html, mentions: this.mentioned_users });
          else
            this.$emit('input', html);
        },
        onFocus: () => (this.$emit('focus')),
        onBlur: () => (this.$emit('blur')),
      });

      this.$emit('initialized', {
        setEditorContent: value => this.editor.commands.setContent(value, false),
        getEditorContent: () => this.editor.getHTML(),
        openSuggestionList: this.openSuggestionList,
        insertContent: this.insertContent,
        focusEditor: this.focusEditor,
        blurEditor: this.blurEditor,
        insertTag: this.insertTag,
      });
      this.is_loading = false;
    },
    getExtensions() {
      const extensions = [
        Document,
        History,
        Paragraph,
        Placeholder.configure({ placeholder: this.placeholder_text }),
        Text,
      ];

      if (this.enable_common_rtf_features)
        extensions.push(
          Bold,
          BulletList,
          Color,
          Dropcursor,
          Gapcursor,
          HardBreak,
          Heading,
          Highlight.configure({ multicolor: true }),
          HorizontalRule,
          Italic,
          Link,
          ListItem,
          OrderedList,
          Strike,
          TextAlign.configure({
            types: ['heading', 'paragraph', 'image'],
          }),
          TextStyle,
          Underline,
        );

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;

      if (this.state_of_plugins.images)
        extensions.push(
          Image.configure({
            inline: true,
            rotate: false,
            allowBase64: false,
          }),
          ImageUploadExtension.configure({
            acceptMimes: ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'],
            upload: async (file) => {
              const stored = await uploadToStorage(file, this.resize_config);
              return stored?.location;
            },
          }),
          ImagePlaceholder.configure({
            inline: true,
          }),
        );

      if (this.state_of_plugins['disable-enter'])
        extensions.push(Extension.create({
          addKeyboardShortcuts() {
            return {
              Enter: () => true,
            };
          },
        }));

      if (this.state_of_plugins['paste-files']) {
        const PasteHandler = Extension.create({
          name: 'pasteHandler',
          addProseMirrorPlugins() {
            return [
              new Plugin({
                key: new PluginKey('pasteHandler'),
                props: {
                  handlePaste(_view, event) {
                    const files = that.getFilesFromPasteEvent(event);

                    if (!files.length || files.some(file => !isFileExtensionAllowed(file?.name)))
                      return;

                    files.forEach((file) => {
                      file.uuid = crypto.randomUUID();
                      file.upload_pending = true;
                      return file;
                    });
                    that.$emit('filesPaste', files);
                  },
                },
              }),
            ];
          },
        });
        extensions.push(PasteHandler);
      }

      if (this.state_of_plugins.tables)
        extensions.push(
          TableCell,
          TableHeader,
          TableRow,
          Table.configure({ resizable: true }),
        );

      if (this.state_of_plugins.mentions)
        extensions.push(makeSuggestionExtension(
          'mention',
          '@',
          this.mention_items,
          items => items.filter(item => !this.mentioned_uids.includes(item.id)),
        ));

      if (this.state_of_plugins.tags)
        extensions.push(makeSuggestionExtension(
          'tag',
          '#',
          this.tag_items,
        ));

      if (this.state_of_plugins.formulas)
        extensions.push(makeSuggestionExtension(
          'formula',
          '=',
          this.formula_items,
        ));

      return extensions;
    },
    async preprocessHtml(html) {
      try {
        let preprocessed_html = html;
        const base64 = preprocessed_html.match(/imageplugin-src="([^"]+)"/)?.[1];
        if (base64) {
          const file = dataURLtoFile(base64, 'image');
          if (file) {
            const stored = await uploadToStorage(file, this.resize_config);
            if (stored)
              preprocessed_html = preprocessed_html.replaceAll(base64, stored.location);
          }
        }
        preprocessed_html = preprocessed_html
          .replaceAll(/<div class="imagePluginRoot"/g, '<img')
          .replaceAll(/imageplugin-src/g, 'src')
          .replaceAll(/data-type="mention"/g, 'data-type="suggestion-plugin-tag"')
          .replaceAll(/imageplugin-alt[^<]+<\/div>/g, '</img>');

        if (!this.editor_enabled && this.display_images_fullwidth)
          preprocessed_html = preprocessed_html.replaceAll(/width="[^"]+"/g, '');

        return preprocessed_html;
      }
      catch (error) {
        return html;
      }
    },
    insertContent(content) {
      this.editor.commands.insertContent(content);
    },
    openSuggestionList() {
      let last_text = ' ';
      try {
        const { view, state } = this.editor;
        const { from, to } = view.state.selection;
        last_text = state.doc.textBetween(from - 1, to, ' ');
        if (!last_text.endsWith(' '))
          this.editor.commands.insertContent(' ');
      }
      catch (_error) {}
      this.editor.commands.insertContent('@');
      this.editor.chain().focus();
    },
    getFilesFromPasteEvent(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      const files = [];
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file')
          files.push(item.getAsFile());
      }
      return files;
    },
    insertTag(attrs, add_space = false, type = 'formula') {
      if (add_space)
        this.editor.chain().focus().insertContent({ type: `suggestion-plugin-${type}`, attrs }).insertContent(' ').run();
      else
        this.editor.chain().focus().insertContent({ type: `suggestion-plugin-${type}`, attrs }).run();
    },
    focusEditor() {
      if (!this.editor.isFocused)
        this.editor.commands.focus(this.focus_position);
    },
    blurEditor() {
      this.editor.commands.blur();
    },
    getMentionsFromHtml(html) {
      const el = document.createElement('div');
      el.innerHTML = html;
      return Array.prototype.map.call(
        el.querySelectorAll('[data-id]'),
        e => e.getAttribute('data-id'),
      );
    },
  },
};
</script>

<template>
  <div class="flex flex-col relative w-full h-full font-sans">
    <HawkLoader v-if="is_loading" />
    <EditorMenuBar
      v-if="editor && editor_enabled && menu_enabled"
      :plugins="plugins"
      :editor="editor"
      :resize_config="resize_config"
      :header_level="header_level"
      class="p-2"
    />
    <EditorContent
      :editor="editor"
      class="cursor-text flex w-full pl-1 h-full text-sm items-center [&>*]:outline-none break-words scrollbar"
      :class="editor_classes"
      @click="focusEditor"
    />
    <input class="h-0 w-0" type="text" @focus="focusEditor">
  </div>
</template>

<style lang="scss">
@import "./editor-styles.scss";
</style>
