<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { currencies } from '~/common/utils/constants.ts';
import { prepareTimeZones } from '~/common/utils/date.util';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  mode: {
    type: String,
    default: 'create',
    validator: value => ['create', 'edit'].includes(value),
  },
  initial_form_data: {
    type: Object,
    default: () => (
      {
        has_activity_weightages: false,
        deadline: null,
        duration: 3,
        currency: 'USD',
        timezone: 'Atlantic/Reykjavik',
        track_resources: true,
        track_costs: true,
        start_date: new Date(),
        working_hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        selected_weekdays: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
      }),
  },
});

const $toast = inject('$toast');
const $t = inject('$t');

const router = useRouter();
const route = useRoute();
const project_management_store = useProjectManagementStore();
const { $g, active_calendar, active_schedule, is_schedule_editable, hidden_focus_input } = storeToRefs(project_management_store);
const { create_schedule, v2_create_empty_schedule, update_active_schedule, save_active_schedule, set_gantt_active_calendar, set_schedule_dirtiness } = project_management_store;

const weekday_items = [
  ['Sun', 'Sunday', 'SUNDAY'],
  ['Mon', 'Monday', 'MONDAY'],
  ['Tue', 'Tuesday', 'TUESDAY'],
  ['Wed', 'Wednesday', 'WEDNESDAY'],
  ['Thu', 'Thursday', 'THURSDAY'],
  ['Fri', 'Friday', 'FRIDAY'],
  ['Sat', 'Saturday', 'SATURDAY'],
].map(day => ({
  label: $t(day[0]),
  name: day[1],
  value: day[2],
}));

const working_hours_items = [];
for (let i = 0; i < 24; i++) {
  let label = '';
  if (i === 0)
    label = `12 ${$t('AM')}`;
  else if (i === 12)
    label = `12 ${$t('PM')}`;
  else if (i < 12)
    label = `${i} ${$t('AM')}`;
  else
    label = `${i - 12} ${$t('PM')}`;

  working_hours_items.push({
    value: i,
    label,
  });
}

const form$ = ref(null);
const file_form$ = ref(null);

const state = reactive({
  is_loading: false,
  duration_unit: 'months',
  are_tags_displayed: false,
  selected_choice: 'start_from_scratch',
  form_data: cloneDeep(props.initial_form_data),
});

const currency_items = Object.values(currencies).map((c) => {
  return {
    ...c,
    label: c.name,
    value: c.code,
  };
});

const working_hours_tags = computed(() => {
  let hours = state.form_data.working_hours;
  hours = hours.sort((a, b) => a - b);
  const ranges = [];
  let start = hours[0];

  for (let i = 1; i < hours.length; i++)
    if (hours[i] !== hours[i - 1] + 1) {
      ranges.push(
        {
          start: working_hours_items[start],
          end: working_hours_items[(hours[i - 1] + 1) % working_hours_items.length],
        });
      start = hours[i];
    }
  ranges.push({
    start: working_hours_items[start],
    end: working_hours_items[(hours[hours.length - 1] + 1) % working_hours_items.length],
  });
  return ranges;
});

const is_calendar_shown = computed(() => {
  return props.mode === 'create' || active_schedule.value.calendar_id;
});

const is_calendar_disabled = computed(() => {
  return props.mode === 'create' ? false : !is_schedule_editable.value;
});

function createPayload() {
  let days;

  if (is_calendar_shown.value) {
    const working_hours = working_hours_tags.value.reduce((acc, curr) => {
      acc.push(`${String(curr.start.value).padStart(2, '0')}:00-${String(curr.end.value).padStart(2, '0')}:00`);
      return acc;
    }, []);

    days = weekday_items.reduce((acc, curr) => {
      acc[curr.value] = {
        is_working: state.form_data.selected_weekdays.includes(curr.value),
        working_hours,
      };
      return acc;
    }, {});
  }

  const multiplier = {
    days: 1,
    weeks: 7,
    months: 30,
    years: 365,
  }[state.duration_unit];

  const payload = {
    name: state.form_data.schedule_name,
    deadline: state.form_data.deadline ? dayjs(state.form_data.deadline).add(1, 'day').startOf('day').utc(true) : null,
    timezone: state.form_data.timezone,
    calendar: {
      name: 'Default',
      days,
      exceptions: [],
    },
    track_resources: state.form_data.track_resources,
    track_costs: state.form_data.track_resources ? state.form_data.track_costs : false,
    currency: {
      code: currencies[state.form_data.currency].code,
      symbol: currencies[state.form_data.currency].symbol,
    },
    has_activity_weightages: state.form_data.has_activity_weightages,
    ...(props.mode === 'edit' && active_schedule.value?.allow_edits
      ? {
          is_editable: state.form_data.is_editable,
        }
      : {}),
    ...(props.mode === 'create'
      ? {
          asset: route.params.asset_id,
          start: dayjs(state.form_data.start_date).format('YYYY-MM-DD'),
          duration: (state.form_data.duration * multiplier) || 1,
        }
      : {}),
  };

  return payload;
}

async function onCreate() {
  let schedule;

  if (state.selected_choice === 'import') {
    const file_data = form$.value.data.file;
    const service_object = {
      fileName: file_data.name,
      fileSize: file_data.size,
      service: file_data.service_object,
    };
    const body = {
      service_object,
      name: service_object.fileName,
      asset: route.params.asset_id,
      imported: true,
      currency: {
        code: currencies[state.form_data.currency].code,
        symbol: currencies[state.form_data.currency].symbol,
      },
      track_resources: state.form_data.track_resources,
      track_costs: state.form_data.track_costs,
      has_activity_weightages: state.form_data.has_activity_weightages,
    };
    schedule = await create_schedule(body);
  }
  else {
    const payload = createPayload();
    schedule = await v2_create_empty_schedule(payload);
  }

  if (!schedule?.uid)
    return;

  router.push({
    name: 'schedule-details',
    params: { schedule_uid: schedule.uid },
  });

  $toast({
    text: $t('Your changes have been saved and your schedule is added'),
    type: 'success',
    title: $t('Schedule created successfully'),
    timeout: 2000,
    has_close_button: true,
  });
}

async function onEdit() {
  hidden_focus_input.value.focus();
  const payload = createPayload();
  if (payload.deadline && active_schedule.value.deadline && payload.deadline.toISOString() !== active_schedule.value.deadline) {
    $g.value.getMarker('deadline-marker').start_date = dayjs(dayjs(payload.deadline).toISOString().slice(0, -1)).tz(active_schedule.value.timezone).toDate();
    $g.value.updateMarker('deadline-marker');
  }
  else if (payload.deadline && payload.deadline.toISOString() !== active_schedule.value.deadline) {
    $g.value.addMarker({
      start_date: dayjs(dayjs(payload.deadline).toISOString().slice(0, -1)).tz(active_schedule.value.timezone).toDate(),
      name: 'deadline',
      text: $t('Deadline'),
      css: 'deadline-marker',
      id: 'deadline-marker',
    });
  }
  else if (!payload.deadline && active_schedule.value.deadline) {
    $g.value.deleteMarker('deadline-marker');
  }
  await update_active_schedule(active_schedule.value.uid, payload, true);
  if (is_schedule_editable.value) {
    const calendars = [{
      ...payload.calendar,
      id: active_calendar.value.id,
    }];
    active_schedule.value.calendars = calendars;
    set_gantt_active_calendar();

    $g.value.batchUpdate(() => {
      $g.value.eachTask((task) => {
        task.start_date = $g.value.getClosestWorkTime({
          dir: 'future',
          date: task.start_date,
          unit: $g.value.config.duration_unit,
          task,
        });
        task.end_date = $g.value.calculateEndDate(task.start_date, task.duration);
        $g.value.updateTask(task.id);
      });
    });
    $g.value.autoSchedule();

    const activities = [];
    $g.value.eachTask((task) => {
      if ([
        $g.value.config.types.project,
        $g.value.config.types.task,
        $g.value.config.types.milestone,
      ].includes(task.type))
        activities.push({
          uid: task.uid,
          id: task.id,
          planned_start: dayjs(task.start_date).format('YYYY-MM-DD'),
          planned_finish: dayjs(task.end_date).subtract(1, 'day').format('YYYY-MM-DD'),
          planned_duration: task.duration,
        });
    });
    set_schedule_dirtiness(false);
    await save_active_schedule({
      activities,
      calendars,
    });
  }

  $toast({
    text: $t('Your changes have been saved'),
    type: 'success',
    title: $t('Schedule settings updated successfully'),
    timeout: 2000,
    has_close_button: true,
  });
}

async function onFormSubmitted() {
  state.is_loading = true;
  if (props.mode === 'create')
    await onCreate();
  else
    await onEdit();
  state.is_loading = false;
}

function removeTagGroup(tag) {
  form$.value.update({
    working_hours: state.form_data.working_hours
      .filter(hour => !(hour >= tag.start.value && hour <= tag.end.value)),
  });
}

watch(() => state.selected_choice, () => {
  if (state.selected_choice === 'start_from_scratch')
    form$.value.update(cloneDeep(props.initial_form_data));
});

onMounted(() => {
  form$.value.update(cloneDeep(props.initial_form_data));
});
</script>

<template>
  <Vueform
    ref="form$"
    v-model="state.form_data"
    size="sm"
    :attachment_config="{
      meta: {
        service: 'pm',
      },
    }"
    :display-errors="false"
    :columns="{
      default: { container: 12, label: 4, wrapper: 12 },
      sm: { container: 12, label: 4, wrapper: 12 },
      md: { container: 12, label: 4, wrapper: 12 },
    }"
    :endpoint="onFormSubmitted"
  >
    <div class="col-span-12">
      <div>
        <div class="w-[700px] pl-4" :class="{ 'pt-4': props.mode === 'create' }">
          <div v-if="props.mode === 'create'" class="flex flex-col justify-start">
            <div class="text-lg font-semibold">
              {{ $t('New schedule') }}
            </div>
            <div class="text-xs font-normal text-gray-600">
              {{ $t('Create a new schedule or import an existing schedule from Primavera P6 or Microsoft projects') }}
            </div>
          </div>

          <div v-if="props.mode === 'create'" class="flex justify-between my-4">
            <div
              class="relative flex justify-start w-1/2 px-4 py-3 mr-3 border cursor-pointer rounded-xl"
              :class="{ 'border-primary-600': state.selected_choice === 'start_from_scratch' }"
              @click="state.selected_choice = 'start_from_scratch'"
            >
              <div class="flex items-center">
                <HawkFeaturedIcon theme="light-circle-outline" size="lg" color="primary" class="mr-4">
                  <IconHawkEditFive />
                </HawkFeaturedIcon>
              </div>
              <div>
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium">
                      {{ $t('Blank schedule') }}
                    </div>
                    <div class="text-xs leading-[18px] pr-3">
                      {{ $t('Create a blank schedule and start from scratch') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="relative flex justify-start w-1/2 px-4 py-3 border cursor-pointer rounded-xl"
              :class="{ 'border-primary-600': state.selected_choice === 'import' }"
              @click="state.selected_choice = 'import'"
            >
              <div class="flex items-center">
                <HawkFeaturedIcon theme="light-circle-outline" size="lg" color="primary" class="mr-4">
                  <IconHawkLogInOne />
                </HawkFeaturedIcon>
              </div>
              <div>
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium">
                      {{ $t('Import schedule') }}
                    </div>
                    <div class="text-xs leading-[18px] pr-3">
                      {{ $t('Import from Primavera P6 or Microsoft Projects') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="state.selected_choice === 'import'" class="flex justify-between w-full col-span-full">
          <div class="flex col-span-full justify-center w-[700px] pl-4">
            <FileElement
              name="file"
              :label="$t('Upload')"
              :description="$t('Supported formats: Primavera P6 (.xer, .xml), Microsoft Projects (.mpp)')"
              class="w-full"
              rules="required"
              :auto="true"
              :presets="['hawk_file_element']"
              :options="{
                button_type: 'text',
                button_classes: '-ml-3.5',
              }"
              :add-classes="{
                FileElement: {
                  button: '!bg-white !border-0 hover:!scale-100 hover:!bg-gray-50 !font-medium !text-sm !text-gray-600 !px-3 !py-2',
                },
              }"
              accept=".xml,.xer,.mpp"
            >
              <template #upload-trigger>
                <IconHawkFileFour class="inline -mt-0.5" />
                <span class="ml-1 text-gray-600">{{ $t("Choose file") }}</span>
              </template>
            </FileElement>
          </div>
        </div>
        <div v-else-if="state.selected_choice === 'start_from_scratch'">
          <div class="w-[700px] pl-4">
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Basic details') }}
            </div>
            <div class="mb-6 text-xs font-normal text-gray-600">
              {{ $t('Basic schedule details') }}
            </div>
            <TextElement
              name="schedule_name"
              :label="$t('Schedule name')"
              :placeholder="$t('Enter a schedule name')"
              :rules="['required']"
              class="mb-4"
              :add-classes="{
                TextElement: {
                  input_sm: 'w-full',
                },
              }"
            />
            <DateTimeElement v-if="props.mode === 'create'" name="start_date" :rules="['required']" :label="$t('Start date')" class="my-4" />
            <QuantityElement
              v-if="props.mode === 'create'"
              :label="$t('Duration')"
              :rules="['required']"
              name="duration"
              class="mb-4"
              :add-class="{ input: '!font-medium !text-gray-900' }"
            >
              <template #addon-text>
                <HawkSelect
                  class="w-40 flex align-center [&>*]:flex [&>*]:align-center"
                  :default_index="2"
                  :listbox_classes="['mt-9', '-ml-3']"
                  :options="[
                    { name: 'days', label: $t('Days') },
                    { name: 'weeks', label: $t('Weeks') },
                    { name: 'months', label: $t('Months') },
                    { name: 'years', label: $t('Years') },
                  ]"
                  @input="state.duration_unit = $event.name"
                />
              </template>
            </QuantityElement>
            <DateTimeElement
              name="deadline"
              :label="$t('Deadline')"
              class="my-4"
            >
              <template #description>
                <div class="mt-2">
                  {{ $t('If specified, activities with dates greater than the deadline will also be considered as critical.') }}
                </div>
              </template>
            </DateTimeElement>
            <SelectElement
              :label="$t('Timezone')"
              name="timezone"
              :search="true"
              :rules="['required']"
              :placeholder="$t('Select your timezone')"
              :items="prepareTimeZones()"
              :can-deselect="false"
              :can-clear="false"
              class="mb-4"
            >
              <template #description>
                <div class="mt-2">
                  {{ $t('All activities, automations and notifications in the schedule will use the selected timezone.') }}
                </div>
              </template>
            </SelectElement>
            <ToggleElement
              v-if="active_schedule?.allow_edits"
              name="is_editable"
              label="Editable"
              class="my-4"
            >
              <template #description>
                <div class="mt-2">
                  Allow users to unpublish, make changes to the schedule, and publish
                </div>
              </template>
            </ToggleElement>
          </div>
          <hr v-if="is_calendar_shown" class="mx-4 my-5">
          <div v-if="is_calendar_shown" class="w-[700px] pl-4">
            <div class="text-lg font-semibold text-gray-900">
              {{ $t('Calendar') }}
            </div>
            <div class="mb-6 text-xs font-normal text-gray-600">
              {{ $t('All activities and resources will use the below working days and hours and the activities are auto-scheduled using the same calendar') }}
            </div>
            <CheckboxgroupElement
              name="selected_weekdays"
              :label="$t('Working days')"
              :items="weekday_items"
              :disabled="is_calendar_disabled"
              rules="required"
              view="tabs"
              class="my-4"
              :add-classes="{
                CheckboxgroupCheckbox: {
                  container: 'h-9',
                  wrapper_selected: '!bg-primary-50 !text-primary-700',
                },
              }"
            />
            <TagsElement
              name="working_hours"
              :close-on-select="false"
              :search="true"
              :items="working_hours_items"
              :label="$t('Working hours')"
              :disabled="is_calendar_disabled"
              input-type="search"
              autocomplete="off"
              rules="required"
              :can-clear="false"
              :hide-selected="false"
              :add-classes="{
                TagsElement: {
                  select: {
                    wrapper: '!py-1',
                    options: '!flex !flex-wrap !flex-row !gap-x-[2%] !gap-y-3 !py-3 !px-[14px]',
                    option: '!py-[2px] !min-h-[24px] w-[15%] hover:!bg-primary-700 hover:!text-white !border !border-gray-300 hover:!border-primary-700 !justify-center',
                    optionSelected: '!bg-primary-600 !text-white !border-primary-600',
                    optionSelectedPointed: '!bg-primary-600 !text-white !border-primary-600',
                    optionPointed: '!bg-white',
                  },
                },
              }"
              :remove-classes="{
                TagsElement: {
                  select: {
                    dropdown: 'max-h-60 overflow-y-scroll',
                    tags_sm: 'form-mt-space-tags-sm',
                    options: '!p-1',
                    option: '!px-2 !min-h-[40px]',
                  },
                },
              }"
            >
              <template #tag="{ el$, option }">
                <div
                  v-if="
                    el$.data.working_hours[0] === state.form_data.working_hours[0]
                      && el$.data.working_hours[0] === option.value
                  "
                  class="flex flex-wrap gap-1"
                >
                  <div
                    v-for="tag in working_hours_tags"
                    :key="tag.start.value"
                    class="border border-gray-300 rounded-lg py-[2px] pr-1 pl-[9px] text-sm font-medium text-gray-700"
                    :class="{
                      'pr-2': is_calendar_disabled,
                    }"
                  >
                    {{ tag.start.label }} - {{ tag.end.label }}
                    <IconHawkXClose
                      v-if="!is_calendar_disabled"
                      class="inline w-3 h-3 text-gray-400 hover:text-gray-600"
                      @click="removeTagGroup(tag)"
                    />
                  </div>
                </div>
                <div v-else />
              </template>
              <template #after-list>
                <div class="ml-3 text-sm font-normal text-gray-400">
                  {{ $t('Unselect to set up non working hours') }}
                </div>
              </template>
            </TagsElement>
          </div>
        </div>
        <hr class="mx-4 my-5">
        <div class="w-[700px] pl-4">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Tracking') }}
          </div>
          <div class="mb-6 text-xs font-normal text-gray-600">
            {{ $t('You can track different type of metrics') }}
          </div>
          <ToggleElement
            name="track_resources"
            :label="$t('Resource tracking')"
            class="my-4"
          >
            <template #description>
              <div class="mt-2">
                {{ $t('Enable this option to define labor, material and other resources, assign them to the activities to track workload efficiently. You can create/manage resources once the schedule is created. You can disable this option anytime.') }}
              </div>
            </template>
          </ToggleElement>
          <ToggleElement
            name="track_costs"
            :label="$t('Cost tracking')"
            class="mb-4"
            :conditions="[
              ['track_resources', '==', true],
            ]"
          >
            <template #description>
              <div class="mt-2">
                {{ $t('Enable this option if you want to track cost/budget for activities, resources and track the activities. You can disable this option anytime.') }}
              </div>
            </template>
          </ToggleElement>
          <SelectElement
            name="currency"
            :rules="['required']"
            :items="currency_items"
            :search="true"
            :native="false"
            :label="$t('Currency')"
            :placeholder="$t('Select currency')"
            input-type="search"
            autocomplete="off"
            :can-clear="false"
            :can-deselect="false"
            class="w-full"
          />
          <ToggleElement
            name="has_activity_weightages"
            :label="$t('Custom weights')"
            class="mt-4"
          >
            <template #description>
              <div class="mt-2">
                {{ $t('Define custom weights for activities in respective hierarchies for more granular control over the progress updates and roll-ups.') }}
              </div>
            </template>
          </ToggleElement>
        </div>
      </div>
      <div class="sticky bottom-0 bg-white">
        <hr class="my-5">
        <div class="flex justify-end gap-3 pb-4 pr-4">
          <ButtonElement v-if="props.mode === 'create'" name="cancel" :secondary="true" @click="router.push({ name: 'project-management' })">
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement v-if="props.mode === 'create'" name="create" :loading="state.is_loading" submits>
            {{ $t('Create') }}
          </ButtonElement>
          <ButtonElement v-else name="save" :loading="state.is_loading" submits>
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </Vueform>
</template>
