export function getTranslationMap($t) {
  return {
    wbs: $t('WBS'),
    status: $t('Status'),
    progress: $t('Progress'),
    total_slack: $t('Total slack'),
    free_slack: $t('Free slack'),
  };
}

export function parseDuration(input) {
  let total_duration = 0;
  // Normalize input: remove spaces and convert to lowercase
  const normalized_input = input.replace(/\s+/g, '').toLowerCase();

  // Define regex pattern to match all duration types
  const pattern = /(?<value>\d+)(d|day|days|w|week|weeks|m|month|months|y|year|years)?/g;

  const conversion_rates = {
    d: 1,
    day: 1,
    days: 1,
    w: 7,
    week: 7,
    weeks: 7,
    m: 30,
    month: 30,
    months: 30,
    y: 365,
    year: 365,
    years: 365,
  };

  // Check if the input contains only a number
  if (/^\d+$/.test(normalized_input))
    return Number.parseInt(normalized_input) * conversion_rates.d;

  let match = pattern.exec(normalized_input);

  while (match !== null) {
    const value = match.groups.value || {};
    const unit = match[0];
    const regex = /[a-z]+/;
    const match_one = regex.exec(unit);
    const base_unit = match_one ? match_one[0] : null; // Default to 'd' if no unit is found
    if (base_unit)
      total_duration += Number.parseInt(value) * conversion_rates[base_unit];

    match = pattern.exec(normalized_input);
  }

  return total_duration || null;
}
