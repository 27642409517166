<script>
export default {
  props: {
    priority: {
      type: Number,
      default: 5,
    },
  },
  setup() {
    const priority_values = {
      1: {
        name: 'critical',
        label: 'Critical',
        value: 1,
      },
      2: {
        name: 'high',
        label: 'High',
        value: 2,
      },
      3: {
        name: 'medium',
        label: 'Medium',
        value: 3,
      },
      4: {
        name: 'low',
        label: 'Low',
        value: 4,
      },
      5: {
        name: 'not_set',
        label: 'Not set',
        value: 5,
      },

    };
    return { priority_values };
  },
};
</script>

<template>
  <div :class="`text-task-priority-${priority}`" class="flex content-center items-center text-xs">
    <span class="pr-2">
      <IconHawkCriticalPriorityFlag v-if="priority_values?.[priority]?.value === 1" class="w-3 h-3" />
      <IconHawkHighPriorityFlag v-else-if="priority_values?.[priority]?.value === 2" class="w-3 h-3" />
      <IconHawkMediumPriorityFlag v-else-if="priority_values?.[priority]?.value === 3" class="w-3 h-3" />
      <IconHawkLowPriorityFlag v-else-if="priority_values?.[priority]?.value === 4" class="w-3 h-3" />
      <IconHawkNoPriorityFlag v-else class="w-3" />
    </span>
    <span class="whitespace-nowrap">
      {{ priority_values?.[priority]?.label }}
    </span>
  </div>
</template>
