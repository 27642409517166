<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const $t = inject('$t');
const $date = inject('$date');
const project_management_store = useProjectManagementStore();
const { active_task, is_fullscreen } = storeToRefs(project_management_store);
</script>

<template>
  <Teleport :to="is_fullscreen ? '#pm-fullscreen-container' : 'body'">
    <Transition name="sidebar-slide">
      <div v-if="!!active_task" class="slide-over fixed bg-white right-0 inset-y-0 z-[1000] w-[800px]">
        <div class="relative h-full flex flex-col bg-white shadow-xl flex-1">
          <PmActivityDetails />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.sidebar-slide-enter-active,
.sidebar-slide-leave-active {
  transition: transform 0.5s ease;
}

.sidebar-slide-enter-from,
.sidebar-slide-leave-to {
  transform: translateX(100%);
}
</style>
