<script setup>
import dayjs from 'dayjs';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useChatStore } from '~/common/stores/chat.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { handleAppUpdate } from '~/common/utils/common.utils';
import PmNewSchedule from '~/project-management/pages/pm-new-schedule.vue';

const $t = inject('$t');
const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const project_management_store = useProjectManagementStore();

const {
  $g,
  active_tab,
  active_task,
  active_view,
  is_fullscreen,
  is_pm_loading,
  active_calendar,
  active_schedule,
  pm_loading_message,
  is_refresh_required,
  is_schedule_editable,
  active_calendar_formatted,
} = storeToRefs(project_management_store);
const { current_organization } = storeToRefs(auth_store);

const {
  set_views,
  set_schedule,
  set_is_fullscreen,
} = project_management_store;

const existing_schedule_details = computed(() => {
  return {
    schedule_name: active_schedule.value?.name || '',
    timezone: active_schedule.value?.timezone || 'Atlantic/Reykjavik',
    deadline: active_schedule.value?.deadline ? dayjs(active_schedule.value?.deadline).subtract(1, 'day') : null,
    selected_weekdays: active_calendar_formatted.value.selected_weekdays,
    working_hours: active_calendar_formatted.value.working_hours,
    track_resources: active_schedule.value?.track_resources,
    track_costs: active_schedule.value?.track_costs,
    currency: active_schedule.value?.currency?.code || 'USD',
    has_activity_weightages: active_schedule.value?.has_activity_weightages,
  };
});

watch(is_refresh_required, async (new_val) => {
  if (new_val) {
    is_refresh_required.value = false;
    is_pm_loading.value = true;
    project_management_store.$reset();
    await loadSchedule();
    is_pm_loading.value = false;
  }
});

watch(() => route.params?.asset_id, () => {
  router.push({ name: 'project-management' });
});

onMounted(() => {
  const interval = setInterval(async () => {
    if (!current_organization.value)
      return;

    clearInterval(interval);
    is_pm_loading.value = true;
    await initialize();
    await loadSchedule();
  }, 500);
});

async function initialize() {
  try {
    const chat_store = useChatStore();
    await chat_store.initialize();
    const { gantt } = await import('dhtmlx-gantt');
    window.gantt = gantt;
    document.addEventListener('fullscreenchange', () => set_is_fullscreen(!!document.fullscreenElement));
  }
  catch (error) {
    logger.error(error);
    if (error.message.includes('import') && error.message.includes('module'))
      handleAppUpdate('package: gantt', true);
  }
}

async function loadSchedule() {
  if (!router.currentRoute.value.params?.schedule_uid)
    return;
  try {
    await set_views(router.currentRoute.value.params.schedule_uid, $t);
    await set_schedule(router.currentRoute.value.params.schedule_uid, $t);
  }
  catch (error) {
    logger.error(error);
  }
}

watch(() => active_schedule.value?.track_resources, () => {
  if (!active_schedule.value?.track_resources && active_tab.value === 'resources')
    active_tab.value = 'gantt-chart';
});

onBeforeUnmount(() => {
  $g.value?.ext?.inlineEditors?.hide?.();
});
</script>

<template>
  <div id="pm-fullscreen-container" class="bg-white" :class="{ 'h-full': is_fullscreen }">
    <div v-if="active_view && active_schedule">
      <PmHeader v-if="!is_fullscreen" />
      <div :class="{ 'h-full': !is_fullscreen }">
        <PmToolbar />
        <PmResources v-if="active_tab === 'resources'" />
        <PmNewSchedule
          v-else-if="active_tab === 'settings'"
          mode="edit"
          :initial_form_data="existing_schedule_details"
        />
        <PmGantt v-show="active_tab === 'gantt-chart'" :class="{ 'h-[95%]': is_fullscreen }" />
      </div>
      <PmActivityDetailsSlideOver />
    </div>
    <div v-if="is_pm_loading" class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full">
      <div v-if="pm_loading_message" class="z-10 text-gray-900">
        {{ pm_loading_message }}
      </div>
      <HawkLoader v-else class="z-10" />
      <div class="absolute top-0 left-0 w-full h-full bg-white opacity-70" />
    </div>
  </div>
</template>

<style>
#pm-fullscreen-container:-webkit-full-screen,
#pm-fullscreen-container:-moz-full-screen,
#pm-fullscreen-container:-ms-fullscreen,
#pm-fullscreen-container:fullscreen {
  background-color: #fff;
}
</style>
