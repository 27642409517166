<script setup>
// --------------------------------- Imports -------------------------------- //
import dayjs from 'dayjs';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
  on_task_created: {
    type: Function,
    required: true,
  },
  on_subtask_added: {
    type: Function,
    required: true,
  },
  translate: {
    type: Function,
    required: true,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['close']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  disabled_controls: [],
});

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
function onActivityDetailsClick() {
  window.gantt.$triggerActivityDetails(props.task);
  emit('close');
}

function createTask(payload, parent, index) {
  const new_index = index ?? window.gantt.$g.getTaskIndex(props.task.id) + 1;
  const uid = crypto.randomUUID().substring(0, 8);
  const new_task_id = window.gantt.$g.addTask({
    id: uid,
    uid,
    start_date: props.task.start_date,
    end_date: dayjs(props.task.start_date).isSame(props.task.end_date, 'day')
      ? dayjs(props.task.start_date).add(1, 'day').toDate()
      : props.task.end_date,
    duration: props.task.duration,
    ...payload,
  }, parent, new_index);
  props.on_task_created(window.gantt.$g.getTask(new_task_id));

  // const task_id = window.gantt.$g.getSelectedId();
  // if (task_id && window.gantt.$g.isTaskExists(task_id))
  //   window.gantt.$g.unselectTask(task_id);

  // window.gantt.$g.selectTask(new_task_id);
  emit('selectTask', new_task_id);

  emit('close');
  return new_task_id;
}

function onAddASubtaskClick() {
  const active_node = window.gantt.$g?.ext?.keyboardNavigation?.getActiveNode?.();
  window.gantt.$g.getTask(props.task.id).type = window.gantt.$g.config.types.project;
  window.gantt.$g.getTask(props.task.id)._original_type = window.gantt.$g.config.types.wbs;

  const new_task_id = createTask({
    text: `Subtask of ${props.task.text}`,
    type: window.gantt.$g.config.types.task,
    unscheduled: false,
    readonly: false,
    status: 'Not started',
    is_backend_save_pending: true,
  }, props.task.id, 0);

  const parent = window.gantt.$g.getParent(new_task_id);
  const children = window.gantt.$g.getChildren(parent);
  if (children.length === 1) {
    const uid = crypto.randomUUID().substring(0, 8);
    window.gantt.$g.addTask({
      type: window.gantt.$g.config.types.surrogate,
      unscheduled: true,
      readonly: true,
      duration: 0,
      text: '',
      id: uid,
      parent,
      uid,
    });
    props.on_subtask_added(window.gantt.$g.getTask(parent));
  }
  window.gantt.$g.ext.keyboardNavigation.focus(active_node);
}

function onAddATaskClick() {
  createTask({
    text: `Sibling task of ${props.task.text}`,
    type: window.gantt.$g.config.types.task,
  }, props.task.parent);
}

function onAddAMilestoneClick() {
  createTask({
    text: `Sibling milestone of ${props.task.text}`,
    type: window.gantt.$g.config.types.milestone,
  }, props.task.parent);
}

function onIndentClick() {
  window.gantt.$indentOrOutdentTask('indent', undefined, props.task.id);
  emit('close');
}

function onOutdentClick() {
  window.gantt.$outdentByMove(props.task.id);
  emit('close');
}

function onConvertToTaskClick() {
  window.gantt.$g.getTask(props.task.id).type = window.gantt.$g.config.types.task;
  window.gantt.$g.getTask(props.task.id)._original_type = window.gantt.$g.config.types.task;
  window.gantt.$g.updateTask(props.task.id);
  toggleMenuItems();
  emit('close');
}

function onConvertToMilestoneClick() {
  if (props.task.progress < 1)
    window.gantt.$g.getTask(props.task.id).progress = 0;
  window.gantt.$g.getTask(props.task.id).type = window.gantt.$g.config.types.milestone;
  window.gantt.$g.updateTask(props.task.id);
  toggleMenuItems();
  emit('close');
}

function onDeleteClick() {
  const parent_task_id = window.gantt.$g.getParent(props.task.id);
  const parent_task = window.gantt.$g.getTask(parent_task_id);
  const sibling_tasks = window.gantt.$g.getSiblings(props.task.id);

  window.gantt.$openDeleteTaskPopup(props.task, () => {
    if (sibling_tasks.length <= 2) {
      parent_task.type = window.gantt.$g.config.types.task;
      parent_task._original_type = window.gantt.$g.config.types.task;

      sibling_tasks.forEach((task) => {
        window.gantt.$g.deleteTask(task);
      });
    }
    else {
      window.gantt.$g.deleteTask(props.task.id);
    }
  });

  emit('close');
}

function toggleMenuItems() {
  state.disabled_controls = [];
  const task = window.gantt.$g.getTask(props.task.id);
  if (task.type === window.gantt.$g.config.types.milestone)
    state.disabled_controls.push('convert_to_milestone');
  else if (task.type === window.gantt.$g.config.types.task)
    state.disabled_controls.push('convert_to_task');
  else if (task.type === window.gantt.$g.config.types.project)
    state.disabled_controls.push(...['add_sibling_task', 'add_sibling_milestone', 'convert_to_task', 'convert_to_milestone']);
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(toggleMenuItems);
</script>

<template>
  <div class="shadow-xl absolute flex flex-col text-xs bg-white border rounded-md cursor-pointer pm_gantt_context_menu min-w-44 p-1">
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onActivityDetailsClick">
      <IconHawkShareFour class="w-3 h-3 mr-1.5" />
      {{ props.translate('Activity details') }}
    </div>
    <div class="h-px my-1 bg-gray-200" />
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddASubtaskClick">
      <IconHawkCornerDownRight class="w-3 h-3 mr-1.5" />
      {{ props.translate('Add subtask') }}
    </div>
    <div v-if="!state.disabled_controls.includes('add_sibling_task')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddATaskClick">
      <IconHawkSquare class="w-3 h-3 mr-1.5" />
      {{ props.translate('Add task below') }}
    </div>
    <div v-if="!state.disabled_controls.includes('add_sibling_milestone')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddAMilestoneClick">
      <IconHawkSquare class="w-3 h-3 mr-1.5 rotate-45" />
      {{ props.translate('Add milestone below') }}
    </div>
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onIndentClick">
      <IconHawkLeftIndentOne class="w-3 h-3 mr-1.5" />
      {{ props.translate('Indent') }}
    </div>
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onOutdentClick">
      <IconHawkRightIndentOne class="w-3 h-3 mr-1.5" />
      {{ props.translate('Outdent') }}
    </div>
    <div v-if="!state.disabled_controls.includes('convert_to_task')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onConvertToTaskClick">
      <IconHawkCryptocurrencyTwo class="w-3 h-3 mr-1.5 rotate-45" />
      {{ props.translate('Convert to task') }}
    </div>
    <div v-if="!state.disabled_controls.includes('convert_to_milestone')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onConvertToMilestoneClick">
      <IconHawkCryptocurrencyTwo class="w-3 h-3 mr-1.5" />
      {{ props.translate('Convert to milestone') }}
    </div>
    <div class="h-px my-1 bg-gray-200" />
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onDeleteClick">
      <IconHawkDeletePopup class="w-3 h-3 mr-1.5" />
      {{ props.translate('Delete') }}
    </div>
  </div>
</template>
