export const properties_general = [
  'activity_code_values',
  'actual_cost',
  'actual_duration',
  'actual_qty',
  'actual_work',
  'resources',
  'attachments',
  'auto_progress_sync',
  'category',
  'cost',
  'cpi',
  'created_at',
  'custom_field_values',
  'data_date',
  'earned_value',
  'editable',
  'field_quantities_percentage',
  'field_quantities',
  'free_slack',
  'intial_progress',
  'is_critical',
  'is_milestone',
  'percent_schedule_complete_progress',
  'percent_schedule_complete',
  'percent_work_complete',
  'planned_cost',
  'planned_duration',
  'planned_qty',
  'planned_value',
  'planned_work',
  'predecessors',
  'priority',
  'progress_type',
  'progress',
  'references',
  'remaining_cost',
  'remaining_duration',
  'remaining_work',
  'spi',
  'status',
  'successors',
  'tags',
  'text',
  'total_slack',
  'units',
  'updated_at',
  'wbs_path',
  'weight',
  'work_rate',
  'work',
];

export const properties_for_dates_start = [
  'start_date',
  'planned_start',
  'actual_start',
  'bl_start',
];

export const properties_for_dates_finish = [
  'end_date',
  'planned_finish',
  'actual_finish',
  'bl_finish',
  'finish',
];
