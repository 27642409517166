<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import HawkAttach from '~/common/components/organisms/hawk-attach/hawk-attach.vue';

const props = defineProps({
  attach_reference: {
    type: Function,
    default: () => {},
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
  additional_queries: {
    type: Object,
    default: () => ({}),
  },
});

const route = useRoute();
const auth_store = useAuthStore();
const task_store = useTasksStore();
const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();

const { is_fullscreen } = storeToRefs(project_management_store);

const $t = inject('$t');

const state = reactive({
  update_menu_open: false,
});

const { open: openTaskForm, close: closeTaskForm } = useModal({
  component: TaskForm,
  attrs: {
    modal_options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
    onClose() {
      closeTaskForm();
    },
    async on_submit(data) {
      const payload = data;
      payload.target_element = await common_store.get_target_element(route.params.asset_id);
      const tasks = await task_store.create_tasks({ tasks: [payload] }, { view: 'Project Management', method: 'Direct' });
      await props.attach_reference(Object.keys(tasks));
    },
  },
});

const attach_modal = useModal({
  component: HawkAttach,
  attrs: {
    onClose() {
      attach_modal.close();
    },
    is_modal: true,
    type: 'Tasks',
  },
});

async function onAttachTaskClicked() {
  attach_modal.patchOptions({
    attrs: {
      asset_id: route.params.asset_id,
      async on_attach(data) {
        await props.attach_reference(data.map(item => item.uid));
      },
      restricted_items: props.restricted_items,
      additional_queries: props.additional_queries,
    },
  });
  attach_modal.open();
}
</script>

<template>
  <div class="flex">
    <HawkButton
      v-if="auth_store.check_permission('create_tasks', route.params.asset_id)"
      type="text"
      @click="openTaskForm"
    >
      <IconHawkPlus />
      {{ $t('New task') }}
    </HawkButton>
    <HawkButton type="text" @click="onAttachTaskClicked();">
      <IconHawkClipboard />
      {{ $t('Choose existing task') }}
    </HawkButton>
  </div>
</template>
