<script setup>
import { slice } from 'lodash-es';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

const props = defineProps({
  resources: {
    required: true,
    default: () => [],
  },
  all_resources: {
    required: true,
    default: () => [],
  },
  max_tags_to_display: {
    type: Number,
    default: Number.MAX_SAFE_INTEGER,
  },
  popover_position: {
    type: String,
    default: 'middle',
    validator(value) {
      return ['left', 'middle', 'right'].includes(value);
    },
  },
  popover_items_wrap: {
    type: Boolean,
    default: true,
  },
  tag_truncate_length: {
    type: Number,
    default: 30,
  },
  popover_tag_truncate_length: {
    type: Number,
    default: 30,
  },
});

const popover_pos_target = ref(null);

const state = reactive({
  open: false,
  popover_position_class: 'right-0 top-0',
});

const tags_to_display = computed(() => {
  return slice(props.resources, 0, props.max_tags_to_display).map((tag) => {
    const resource = props.all_resources.find(r => r.uid === tag);
    return resource;
  });
});

const remaining_tags_to_display = computed(() => {
  return slice(props.resources, props.max_tags_to_display, props.resources?.length).map((tag) => {
    const resource = props.all_resources.find(r => r.uid === tag);
    return resource;
  });
});

const show_popover = computed(() => remaining_tags_to_display.value.length);

const popover_panel_classes = computed(() => {
  const classes = [];
  switch (props.popover_position) {
    case 'left':
      classes.push('');
      break;
    case 'middle':
      classes.push('left-1/2 -translate-x-1/2');
      break;
    case 'right':
      classes.push('right-0');
      break;
  }

  return classes;
});

function togglePopover(value) {
  const current_position = popover_pos_target.value.getBoundingClientRect();
  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const y_class
    = current_position.y < screen_center.y ? 'top-full' : 'bottom-full';
  state.popover_position_class = `${y_class}`;

  state.open = value;
}
</script>

<template>
  <div class="flex flex-wrap gap-1">
    <div v-for="tag in tags_to_display" :key="tag" class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-[3px] px-1.5">
      <template v-if="tag.type === 'custom'">
        <div class="flex items-center text-xs font-medium text-gray-700">
          <div class="w-4 h-4 flex items-center justify-center bg-gray-100 font-medium text-gray-600 rounded-full mr-1.5">
            {{ tag.name.charAt(0) }}
          </div>
          {{ tag.name }}
        </div>
      </template>
      <template v-else-if="tag.type === 'member'">
        <HawkMembers
          :members="tag.external_id"
          :has_avatar="true"
          type="label"
          size="tiny"
        />
      </template>
      <div v-else>
        -
      </div>
    </div>
    <Popover
      v-if="show_popover"
      class="relative flex items-center z-1"
      @mouseenter="togglePopover(true)"
      @mouseleave="togglePopover(false)"
      @click="togglePopover(!state.open)"
    >
      <PopoverButton>
        <div class="flex items-center whitespace-nowrap text-xs rounded-lg border border-gray-300 px-1.5 py-[3px]">
          +{{ remaining_tags_to_display?.length }}
        </div>
      </PopoverButton>
      <span
        ref="popover_pos_target"
        class="absolute top-0 left-0 w-full h-full pointer-events-none"
      />

      <div v-if="state.open">
        <PopoverPanel
          static
          class="absolute z-1 flex w-screen max-w-min"
          :class="[state.popover_position_class, popover_panel_classes]"
        >
          <div
            class="w-56 flex-shrink-0 rounded-xl bg-white p-1.5 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 max-h-60 scrollbar"
            :class="{ 'flex flex-wrap gap-1': popover_items_wrap }"
          >
            <div v-for="tag in remaining_tags_to_display" :key="tag" class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-[3px] px-1.5">
              <template v-if="tag.type === 'custom'">
                <div class="flex items-center text-xs font-medium text-gray-700">
                  <div class="w-4 h-4 flex items-center justify-center bg-gray-100 text-md font-medium text-gray-600 rounded-full mr-1.5">
                    {{ tag.name.charAt(0) }}
                  </div>
                  {{ tag.name }}
                </div>
              </template>
              <template v-else-if="tag.type === 'member'">
                <HawkMembers
                  :members="tag.external_id"
                  :has_avatar="true"
                  type="label"
                  size="tiny"
                />
              </template>
              <div v-else>
                -
              </div>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </Popover>
  </div>
</template>
