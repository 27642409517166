<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import NewForm from '~/forms/components/new-form/new-form-modal.vue';
import HawkAttach from '~/common/components/organisms/hawk-attach/hawk-attach.vue';
import { FORM_PERMISSIONS } from '~/forms/constants';

const props = defineProps({
  attach_reference: {
    type: Function,
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
  additional_queries: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close']);
const route = useRoute();
const auth_store = useAuthStore();
const forms_store = useFormsStore();
const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();

const { is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  update_menu_open: false,
});

const { open: openNewForm, close: closeNewForm } = useModal({
  component: NewForm,
  attrs: {
    modal_options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
    onClose() {
      closeNewForm();
    },
    on_cancel() {
      emit('close');
    },
    async on_submit(data) {
      try {
        data.forms.add[0].target_element = await common_store.get_target_element(route.params.asset_id);
        const response = await forms_store?.create_form({ body: data }, true);
        await props.attach_reference(response.forms.added.map(form => form.uid));
        closeNewForm();
      }
      catch (error) {
        logger.error(error);
      }
    },
  },

});

const attach_modal = useModal({
  component: HawkAttach,
  attrs: {
    onClose() {
      attach_modal.close();
    },
    is_modal: true,
    type: 'Forms',
    additional_queries: props.additional_queries,
  },
});

async function onAttachFormClicked() {
  attach_modal.patchOptions({
    attrs: {
      asset_id: route.params.asset_id,
      async on_attach(data) {
        await props.attach_reference(data.map(item => item.uid));
      },
      restricted_items: props.restricted_items,
    },
  });
  attach_modal.open();
}
</script>

<template>
  <div class="flex">
    <HawkButton
      type="text"
      :disabled="!auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, route.params.asset_id)"
      @click="openNewForm"
    >
      <IconHawkPlus />
      {{ $t('New form') }}
    </HawkButton>
    <HawkButton type="text" @click="onAttachFormClicked();">
      <IconHawkClipboard />
      {{ $t('Choose existing form') }}
    </HawkButton>
  </div>
</template>
