<script setup>
import { markRaw } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useFormsStore } from '~/forms/store/forms.store.js';
import { useDocumentStore } from '~/dms/store/document.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import PmTaskForm from '~/tasks/components/molecule/task-form/pm-task-form.vue';
import PmNewForm from '~/forms/components/new-form/pm-new-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import PmReferencesWidget from '~/project-management/components/activity-details/pm-references-widget.vue';

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');
const $date = inject('$date');

const route = useRoute();
const project_management_store = useProjectManagementStore();
const tasks_store = useTasksStore();
const forms_store = useFormsStore();
const document_store = useDocumentStore();
const common_store = useCommonStore();
const authStore = useAuthStore();

const { active_task, references } = storeToRefs(project_management_store);
const { set_references, update_activity, update_activity_reference } = project_management_store;

const tab_items = computed(() => {
  return [
    {
      uid: 'FILE',
      name: 'FILE',
      label: $t('Files'),
      singular_label: $t('File'),
      create_component: markRaw(PmReferencesWidget),
      service_name: 'documents',
      delete_modal_header: $t('Delete File'),
    },
    {
      uid: 'TASK',
      name: 'TASK',
      label: $t('Tasks'),
      singular_label: $t('Task'),
      create_component: markRaw(PmTaskForm),
      service_name: 'tasks',
      delete_modal_header: $t('Delete Task'),
    },
    {
      uid: 'FORM',
      name: 'FORM',
      label: $t('Forms'),
      singular_label: $t('Form'),
      create_component: markRaw(PmNewForm),
      service_name: 'forms',
      delete_modal_header: $t('Delete Form'),
    },
  ];
});

const state = reactive({
  current_tab: tab_items.value[0],
  refresh_key: 0,
  is_loading: false,
});

const filtered_references = computed(() => {
  return references.value.filter(item => item.type === state.current_tab.uid);
});

const options = {
  is_pm: true,
  query: {
    root: false,
    archived: false,
    asset_uid: route.params.asset_id,
    page_number: 1,
    page_size: 100000000,
    resource_id: active_task.value.uid,
    resource_type: 'PM_ACTIVITY',
  },
  disable_options: {
    is_title: true,
    is_sidebar: true,
    is_breadcrumbs: true,
    is_search_bar: true,
    is_files_filter: true,
    is_internal_button: true,
  },
};

const attachReference = async payload => await onReferencesUpdated(payload, 'add');
const detachReference = async payload => await onReferencesUpdated(payload, 'remove');

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeleteOptions } = useModal({
  component: HawkDeletePopup,
});

const activity_references = computed(() => {
  return active_task.value.references;
});

const additional_queries = computed(() => {
  if (state.current_tab.name === 'FORM')
    return {
      all_access: true,
      is_child: true,
      public: false,
    };
  else if (state.current_tab.name === 'TASK')
    return {
      archived: false,
      asset_uid: route.params.asset_id,
    };

  return {};
});

const restricted_items = computed(() => {
  return activity_references.value
    .filter(item => item.resource_type === state.current_tab.name)
    .map(item => item.resource_id);
});

const additional_props = computed(() => {
  if (state.current_tab.name === 'FILE')
    return {
      attach_reference: attachReference,
      restricted_items: restricted_items.value,
    };
  else if (state.current_tab.name === 'TASK')
    return {
      is_compact_view: true,
      class: '!pt-4',
    };
  else
    return {
      class: '!pt-4 forms-list-container',
      asset_id: route.params.asset_id,
      options: {
        show_no_data: true,
        query: options.query,
      },
    };
});

async function triggerRefetchActivity() {
  await update_activity({ uid: active_task.value.uid }, true);
  await set_references($t);
  gantt.render();
  state.refresh_key++;
}

async function onReferencesUpdated(references, type_of_update) {
  try {
    const references_array = Array.isArray(references) ? references : [references];

    const type_of_update_pm = type_of_update === 'add' ? 'add' : 'delete';
    await update_activity_reference({
      activity: active_task.value,
      body: {
        [type_of_update_pm]: references_array.map(uid => ({
          resource_id: uid,
          resource_type: state.current_tab.name,
        })),
      },
    });
    if (state.current_tab.service_name === 'forms')
      await $services[state.current_tab.service_name].post({
        body: {
          [state.current_tab.service_name]: {
            update: references_array.map(
              uid => ({
                references: {
                  [type_of_update]: [
                    {
                      resource_id: active_task.value.uid,
                      resource_type: 'PM_ACTIVITY',
                    },
                  ],
                },
                uid,
              }),
            ),
          },
        },
      });
    else if (state.current_tab.service_name === 'tasks')
      await $services[state.current_tab.service_name].patch({
        body: {
          [state.current_tab.service_name]: references_array.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_id: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
    else if (state.current_tab.service_name === 'documents')
      await $services[state.current_tab.service_name].post({
        body: {
          files: references_array.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_uid: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
  }
  catch (error) {
    logger.error(error.message);
  }
  finally {
    await triggerRefetchActivity();
  }
}

async function detachHandler(element) {
  await detachReference(element?.data?.row?.original.uid || element?.uid);
}

function deleteHandler(menu_element) {
  const element = menu_element?.data?.row?.original || menu_element || {};
  patchDeleteOptions(
    {
      attrs: {
        header: state.current_tab.delete_modal_header,
        content: `Are you sure you want to delete ${element.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            if (state.current_tab.name === 'FILE' && element.uid)
              await document_store.crud_documents({
                request: {
                  body: {
                    files: {
                      remove: [element.uid],
                    },
                  },
                },
              });

            if (state.current_tab.name === 'TASK')
              await tasks_store.remove_tasks([element.uid]);
            else if (state.current_tab.name === 'FORM')
              await forms_store.remove_forms([element.uid]);
            await triggerRefetchActivity();
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

async function searchTasks(value) {
  tasks_store.set_search_key(value);
  await tasks_store.set_tasks(options.query);
}

async function onTabSelected(item) {
  state.current_tab = item.item;
  if (item.name === 'TASK')
    await tasks_store.set_tasks(options.query);
}

function getStatusBackgroundColor(item) {
  if (item.type === 'TASK')
    return item.bg_color;
  else if (item.color)
    return `${item.color}1a`;
  else return '#6670851a';
}
</script>

<template>
  <HawkModalTemplate @close="$emit('close')">
    <template #header_left>
      {{ $t('Add references') }}
    </template>
    <div :key="state.refresh_key" class="flex flex-col w-[600px] h-[300px]">
      <HawkPageHeaderTabs
        :tabs="tab_items"
        :active_item="state.current_tab.uid"
        :space="4"
        @tabClick="state.current_tab = $event"
      />
      <HawkLoader v-if="state.is_loading" />
      <div v-else class="mt-6  scrollbar">
        <HawkIllustrations
          v-if="!filtered_references.length"
          type="no-data"
          :for="`${state.current_tab.uid.toLowerCase()}s`"
          variant="mini"
        />
        <PmListComponent v-else :items="filtered_references" height_class="h-[70px]">
          <template #left_section="{ item }">
            <div class="text-sm font-medium text-gray-900">
              {{ item.name }}
            </div>
            <div class="flex items-center gap-1 text-xs font-medium text-gray-700">
              <HawkBadge v-if="item.category">
                {{ common_store.get_category(item.category)?.name }}
              </HawkBadge>
              <HawkMembers
                v-if="item.assignees.length"
                :members="item.assignees"
                :has_avatar="true"
                type="badge"
                size="xs"
              />
              <template v-if="item.due_date">
                {{ $date(item.due_date, 'Do MMM, YYYY') }}
              </template>
            </div>
          </template>
          <template #right_section="{ item }">
            <div class="flex items-center gap-3">
              <HawkBadge
                v-if="item.status"
                class="flex"
                :style="`
                  color: ${item.color};
                  background-color: ${getStatusBackgroundColor(item)}
                `"
              >
                {{ item.status }}
              </HawkBadge>
            </div>
          </template>
        </PmListComponent>
      </div>
      <div v-if="state.current_tab.name" class="mt-6">
        <component
          :is="state.current_tab.create_component"
          :additional_queries="additional_queries"
          :attach_reference="attachReference"
          :restricted_items="restricted_items"
          :refetch_activity="triggerRefetchActivity"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex justify-end w-full">
        <HawkButton type="outlined" @click="$emit('close')">
          {{ $t('Done') }}
        </HawkButton>
      </div>
    </template>
  </HawkModalTemplate>
</template>

<style lang="scss" scoped>
:deep() {
  .forms-list-container {
    overflow-x: auto !important;
    table {
      width: 512px !important;
      max-width: 512px !important;
      min-width: unset !important;
    }
  }
}
</style>
