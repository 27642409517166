<script setup>
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { DynamicScroller } from 'vue-virtual-scroller';
import { useElementBounding } from '@vueuse/core';
import { saveAs } from 'file-saver';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useDocumentViewer } from '~/plans/composables/useDocumentViewer';
import HawkAttachmentViewer from '~/common/components/organisms/hawk-attachment-viewer.vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  show_file_size: {
    type: Boolean,
    default: false,
  },
  filename_truncate_length: {
    type: Number,
    default: 30,
  },
  created_at_truncate_length: {
    type: Number,
    default: 30,
  },
  can_delete: {
    default: true,
  },
  show_delete: {
    default: true,
  },
  can_download: {
    type: Boolean,
    default: true,
  },
  can_view: {
    type: Boolean,
    default: true,
  },
  is_loading: {
    default: false,
  },
  variant: {
    type: String,
    default: '',
  },
  additional_classes: {
    type: String,
    default: '',
  },
  grid_gap: {
    type: Number,
    default: 4,
  },
  image_dimensions: {
    type: Array,
    default: () => [164, 164], // [width in pixels, height in pixels]
  },
  enable_description: {
    type: Boolean,
    default: false,
  },
  use_virtualization: {
    type: Boolean,
    default: false,
  },
  close_viewer: {
    type: Boolean,
    default: false,
  },
  parent_rect_id: {
    type: String,
    default: 'task-details-left-section',
  },
  show_attachments_list_preview: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['download', 'delete', 'updateNotes', 'view', 'buttonClicked', 'update:close_viewer', 'attachmentClicked']);

const { setup_core } = useDocumentViewer({});
const common_store = useCommonStore();
const auth_store = useAuthStore();

const current_user = auth_store.logged_in_user_details?.user_id;

const NOTE_POPOVER_WIDTH = 352;

const state = reactive({
  file_downloading: null,
});

const form$ = ref(null);
const form_data = ref(null);
const attachment_index = ref(null);
const is_setting_up_document_viewer = ref(false);
const note_edit_index = ref(-1);
const scroller$ = ref(null);

const { x, y, top, right, bottom, left, width, height } = useElementBounding(scroller$);

if (!window?.Core)
  setup_core().then(() => is_setting_up_document_viewer.value = true);

const getExtension = filename => filename?.split('.')?.pop();

function getSupportedAttachment(file) {
  const extension = getExtension(file.file_name);
  if (!window.Core)
    return true;

  return window.Core.SupportedFileFormats.CLIENT.includes(extension?.toLowerCase());
}

const openAttachment = function (index, file) {
  if (!getURL(file))
    return;

  if (getSupportedAttachment(file)) {
    const extension = getExtension(file.file_name);
    attachment_index.value = index;
    emit('view', { ...file, extension });
  }
};

function getURL(attachment) {
  return attachment?.url || attachment?.service?.url;
}

function user_name(uid) {
  return common_store.get_user_or_team_name(uid);
}

function popoverClass(index) {
  // Returns tailwind classes to open popover so it doesn't clip
  const parentRect = document.getElementById(props.parent_rect_id)?.getBoundingClientRect?.();
  const triggerRect = document.getElementById(`note-edit-icon-${index}`).getBoundingClientRect();
  if ((triggerRect?.x - parentRect?.left) + NOTE_POPOVER_WIDTH < parentRect?.width)
    return 'left-0';
  return 'right-0';
}

async function handleNoteEdit(index) {
  note_edit_index.value = index;
  form_data.value = {
    notes: props.items[index]?.notes || '',
  };
  await nextTick();
  form$.value[0].elements$.notes.focus();
}

async function updateNotes() {
  const payload = {
    uid: props.items[note_edit_index.value].uid,
    notes: form_data.value.notes ? form_data.value.notes : null,
  };
  emit('updateNotes', payload);
  note_edit_index.value = -1;
}

async function downloadAttachment(file) {
  state.file_downloading = file?.url || file?.service?.url;
  const response = await fetch(file?.url || file?.service?.url);
  const response_blob = await response.blob();
  saveAs(response_blob, file?.file_name);
  state.file_downloading = null;
  emit('download', file);
}

const grid_item = computed(() => Math.trunc(width.value / 196));

watch(() => props.close_viewer, (newVal) => {
  if (newVal) {
    attachment_index.value = null;
    emit('update:close_viewer', false);
  }
});
</script>

<template>
  <div :key="is_setting_up_document_viewer" class="grid gap-y-2" :class="additional_classes">
    <template v-if="use_virtualization">
      <DynamicScroller
        ref="scroller$"
        class="h-full scrollbar"
        :items="items"
        key-field="uid"
        :prerender="1"
        :grid-items="grid_item"
        :item-secondary-size="184"
        :item-size="184"
        :min-item-size="184"
      >
        <template #default="{ item, index }">
          <div class="text-sm grid px-3" @click="emit('attachmentClicked', item)">
            <div class="relative rounded-lg group/attachment">
              <img
                v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
                class="object-cover rounded-lg"
                :class="`w-[${image_dimensions[0]}px] h-[${image_dimensions[1]}px]`"
                alt="thumbnail"
              >
              <div
                v-else
                :class="`w-[${image_dimensions[0]}px] h-[${image_dimensions[1]}px]`"
                class="flex items-center justify-center bg-gray-100"
              >
                <HawkFileIcon :file_name="item.file_name" />
              </div>
              <div
                v-if="getURL(item) && props.can_view"
                class="invisible group-hover/attachment:visible absolute w-full h-full top-0 left-0 bg-[#4a4e5566] text-white rounded-lg p-2 flex flex-col justify-between"
              >
                <div class="flex flex-1 justify-between">
                  <div class="flex">
                    <slot :name="`attachment-details-left-content-${item?.uid}`" :attachment="item" />
                    <HawkText :content="item.file_name" :length="8" />
                  </div>
                  <div class="flex">
                    <template v-if="props.can_download && getURL(item)">
                      <IconHawkDownloadTwo
                        v-if="state.file_downloading !== item.url
                          && state.file_downloading !== item.service?.url"
                        class="cursor-pointer"
                        @click.stop="downloadAttachment(item)"
                      />
                      <HawkLoader
                        v-else
                        container_class="!items-start"
                        :height="5"
                        :width="5"
                      />
                    </template>
                    <template v-if="show_delete">
                      <IconHawkTrashThree
                        v-if="props.can_delete || item?.owner?.uid === current_user"
                        class="ml-2 cursor-pointer"
                        @click.stop="emit('delete', item)"
                      />
                    </template>
                  </div>
                </div>
                <div class="flex items-center justify-center flex-1 p-3">
                  <IconHawkEye
                    v-if="getSupportedAttachment(item)"
                    class="cursor-pointer"
                    @click.stop="openAttachment(index, item)"
                  />
                </div>
                <HawkText class="flex-1" :length="50" :content="`${user_name(item.owner?.uid)} on ${$date(item.created_at, 'L_DATETIME_MED')}`" />
              </div>
            </div>
            <div v-if="enable_description" class="relative">
              <div
                class="flex items-center justify-center group/attachment"
              >
                <slot name="notes_text" :item="item">
                  <HawkText
                    :length="17"
                    :content="item.notes || item.file_name"
                    placement="top"
                  />
                </slot>
              </div>
            </div>
          </div>
        </template>
      </DynamicScroller>
    </template>
    <div
      v-else
      class="flex flex-wrap"
      :class="enable_description ? `gap-x-${grid_gap} gap-y-${grid_gap + 1}` : `gap-${grid_gap}`"
    >
      <div v-for="(item, index) in props.items" :key="index">
        <div class="text-sm" @click="emit('attachmentClicked', item)">
          <div class="relative border rounded-lg group/attachment">
            <img
              v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
              class="object-cover rounded-lg"
              :class="`w-[${image_dimensions[0]}px] h-[${image_dimensions[1]}px]`"
              alt="thumbnail"
            >
            <div
              v-else
              :class="`w-[${image_dimensions[0]}px] h-[${image_dimensions[1]}px]`"
              class="flex items-center justify-center bg-gray-100"
            >
              <HawkFileIcon :file_name="item.file_name" />
            </div>
            <div
              v-if="getURL(item) && props.can_view"
              class="invisible group-hover/attachment:visible absolute w-full h-full top-0 left-0 bg-[#4a4e5566] text-white rounded-lg flex"
              :class="[variant === 'small' ? 'justify-center' : 'flex-col justify-between p-2']"
            >
              <div v-if="variant === 'small'" class="flex items-center justify-center flex-1">
                <template v-if="props.can_download && getURL(item)">
                  <IconHawkDownloadTwo
                    v-if="state.file_downloading !== item.url
                      && state.file_downloading !== item.service?.url"
                    class="cursor-pointer"
                    @click.stop="downloadAttachment(item)"
                  />
                  <HawkLoader
                    v-else
                    container_class="!items-start"
                    :height="5"
                    :width="5"
                  />
                </template>
                <div
                  v-if="getSupportedAttachment(item)"
                  class="ml-2 cursor-pointer"
                  @click.stop="openAttachment(index, item)"
                >
                  <IconHawkEye />
                </div>
                <IconHawkTrashThree
                  v-if="show_delete && (props.can_delete || item?.owner?.uid === current_user)"
                  class="cursor-pointer ml-2"
                  @click.stop="emit('delete', item)"
                />
              </div>
              <template v-else>
                <div class="flex flex-1 justify-between">
                  <div class="flex">
                    <slot :name="`attachment-details-left-content-${item?.uid}`" :attachment="item" />
                    <HawkText :content="item.file_name" :length="8" />
                  </div>
                  <div class="flex">
                    <template v-if="props.can_download && getURL(item)">
                      <IconHawkDownloadTwo
                        v-if="state.file_downloading !== item.url
                          && state.file_downloading !== item.service?.url"
                        class="cursor-pointer"
                        @click.stop="downloadAttachment(item)"
                      />
                      <HawkLoader
                        v-else
                        container_class="!items-start"
                        :height="5"
                        :width="5"
                      />
                    </template>
                    <IconHawkTrashThree
                      v-if="show_delete && (props.can_delete || item?.owner?.uid === current_user)"
                      class="ml-2 cursor-pointer"
                      @click.stop="emit('delete', item)"
                    />
                  </div>
                </div>
                <div class="flex items-center justify-center flex-1">
                  <div
                    v-if="getSupportedAttachment(item)"
                    class="px-5 py-3 cursor-pointer"
                    @click="openAttachment(index, item)"
                  >
                    <IconHawkEye />
                  </div>
                </div>
                <HawkText class="flex-1" :length="50" :content="`${user_name(item.owner?.uid)} on ${$date(item.created_at, 'L_DATETIME_MED')}`" />
              </template>
            </div>
          </div>
          <div v-if="variant !== 'small' && enable_description" class="relative">
            <div
              v-if="note_edit_index === index"
              v-click-outside="() => note_edit_index = -1"
              class="absolute z-[2000] shadow-md top-2 p-4 rounded-lg bg-white border border-gray-200"
              :class="`w-[${NOTE_POPOVER_WIDTH}px] ${popoverClass(index)}`"
            >
              <Vueform
                ref="form$"
                v-model="form_data"
                sync
                size="sm"
                :display-errors="false"
                :should_validate_on_mount="false"
              >
                <TextareaElement
                  name="notes"
                  autocomplete="off"
                  :placeholder="$t('Add description')"
                >
                  <template #after>
                    <div class="mt-4 w-full flex items-center justify-between gap-4">
                      <HawkButton
                        type="outlined"
                        :block="true"
                        @click="note_edit_index = -1"
                      >
                        {{ $t('Cancel') }}
                      </HawkButton>
                      <HawkButton
                        :block="true"
                        @click="updateNotes"
                      >
                        {{ $t('Save') }}
                      </HawkButton>
                    </div>
                  </template>
                </TextareaElement>
              </Vueform>
            </div>
            <div
              class="flex items-center justify-center group/attachment"
              :class="{ invisible: note_edit_index === index }"
            >
              <slot name="notes_text" :item="item">
                <HawkText
                  :length="17"
                  :content="item.notes || item.file_name"
                  placement="top"
                />
              </slot>
              <div
                v-if="can_delete"
                class="mt-1 ml-1 p-1 hover:bg-gray-100 rounded-full cursor-pointer flex items-center"
                @click="handleNoteEdit(index)"
              >
                <icon-hawk-edit
                  :id="`note-edit-icon-${index}`"
                  class="text-primary w-3 h-3 invisible group-hover/attachment:visible"
                />
              </div>
            </div>
          </div>
          <HawkText v-if="variant === 'small'" :length="12" :content="item.file_name" class="text-xs flex w-full justify-center" />
        </div>
      </div>
    </div>
    <HawkAttachmentViewer
      v-if="props.items"
      :key="attachment_index"
      :attachment="props.items[attachment_index]"
      :has_next="!!props.items[attachment_index + 1]"
      :has_previous="props.items[attachment_index - 1]"
      :items="props.show_attachments_list_preview ? props.items : []"
      @setActiveAttachment="attachment_index = $event"
      @next="attachment_index++"
      @previous="attachment_index--"
      @download="downloadAttachment(props.items[attachment_index])"
      @delete="emit('delete', props.items[attachment_index]);attachment_index = null"
      @close="attachment_index = null"
    >
      <template #header-right-content>
        <slot name="header-right-content" :attachment="props.items[attachment_index]" />
      </template>
    </HawkAttachmentViewer>
  </div>
</template>
