<script setup>
const props = defineProps({
  show_legend: {
    type: Boolean,
    default: true,
  },
  notch_types: {
    type: Object,
    default: () => ({
      'default': {
        classes: 'bg-gray-200',
        info: 'Outside planned start/finish',
      },
      'green-filled': {
        classes: 'bg-success-500',
        info: 'Work done is equal or greater than required',
      },
      'yellow-filled': {
        classes: 'bg-warning-300',
        info: 'Work done is less than required',
      },
      'red-filled': {
        classes: '!bg-error-500',
        info: 'No work is done',
      },
      'green-outlined': {
        classes: 'border border-success-500',
        info: 'Work started early and is equal or greater than required',
      },
      'yellow-outlined': {
        classes: 'border border-warning-500',
        info: 'Work started early and is less than required',
      },
    }),
  },
  notch_data: {
    type: Array,
    required: true,
  },
});

const used_notch_types = computed(() => {
  const current_notch_types = [];
  for (const group of props.notch_data)
    for (const data_point of group.data_points)
      if (!current_notch_types.includes(props.notch_types[data_point.notch_type]))
        current_notch_types.push(props.notch_types[data_point.notch_type]);

  return current_notch_types;
});
</script>

<template>
  <div v-if="props.show_legend" class="bg-gray-25 rounded-lg border border-gray-200 flex flex-wrap gap-3 px-4 py-3 mb-6">
    <div v-for="notch_type in used_notch_types" :key="notch_type">
      <div class="flex gap-2 text-xs font-normal text-gray-600">
        <div
          class="h-4 w-1.5 bg-gray-100 rounded-full"
          :class="[notch_type.classes]"
        />
        {{ notch_type.info }}
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-6">
    <div v-for="group in notch_data" :key="group">
      <div class="flex justify-between mb-3">
        <div class="text-sm font-medium text-gray-900">
          {{ group.group_name }}
        </div>
        <div class="flex gap-3">
          <div
            v-for="info_item in group.info"
            :key="info_item.title"
            class="text-xs font-semibold text-gray-500"
          >
            {{ info_item.title }}:
            <span class="font-normal">
              {{ info_item.value }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-evenly gap-1">
        <div
          v-for="data_point in group.data_points"
          :key="data_point.id"
          v-tippy="data_point.tooltip"
          class="h-4 w-1.5 bg-gray-100 rounded-full"
          :class="[props.notch_types[data_point.notch_type].classes, data_point.extra_classes]"
        />
      </div>
    </div>
  </div>
</template>
