<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['closeSearch']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { $g, search_config, is_searching } = storeToRefs(project_management_store);

const form$ = ref(null);

function setCurrentMatchIndex(index) {
  const new_index = search_config.value.match_count ? index : -1;
  if (search_config.value.current_match_index === new_index && search_config.value.search_results[new_index])
    return $g.value.showTask(search_config.value.search_results[new_index]);

  search_config.value.current_match_index = new_index;
  $g.value.render();
}

function findPrevious() {
  if (!search_config.value.match_count)
    return;
  const next = search_config.value.current_match_index - 1;
  setCurrentMatchIndex(next < 0 ? search_config.value.match_count - 1 : next);
}

function findNext(event) {
  if (!search_config.value.match_count)
    return;
  if (event?.shiftKey) {
    findPrevious();
    return;
  }
  const next = search_config.value.current_match_index + 1;
  setCurrentMatchIndex(next === search_config.value.match_count ? 0 : next);
}

function closeSearch(is_close_clicked) {
  if (is_close_clicked)
    search_config.value.search_string = '';

  search_config.value.search_string = search_config.value.search_string.trim();
  if (!search_config.value.search_string) {
    search_config.value.search_string = '';
    emit('closeSearch');
  }
}

onMounted(() => {
  form$.value.elements$.search_string.input.focus();
});
</script>

<template>
  <Vueform
    ref="form$"
    v-model="search_config"
    size="sm"
    sync
    @keydown.esc="closeSearch(true)"
  >
    <TextElement
      v-click-outside="() => closeSearch(false)"
      name="search_string"
      :placeholder="$t('Search by name or ID')"
      class="w-64"
      :add-class="{
        inputContainer: '!border-transparent vueform-input-bottom-underline-focused',
        input_sm: search_config.search_string ? '!text-xs !font-medium' : '!text-xs !font-regular !text-gray-500',
        // inputContainer: '!border-x-transparent !border-t-transparent',
      }"
      :override-class="{
        inputContainer_focused: 'ring-0',
      }"
      @keyup.enter="findNext"
    >
      <template #addon-before>
        <IconHawkSearchMd class="w-5 h-5 text-gray-600" aria-hidden="true" />
      </template>
      <template #addon-after>
        <div v-if="is_searching" class="flex items-center ml-2">
          <IconHawkLoadingCircle class="w-4 h-4 ml-2 animate-spin" />
        </div>
        <div class="flex items-center ml-2">
          <template v-if="search_config?.match_count > 0">
            <span class="text-xs font-medium text-gray-900 whitespace-nowrap">
              {{ search_config.current_match_index + 1 }}/{{ search_config.match_count }}
            </span>
            <IconHawkChevronUp class="w-4 h-4 ml-2 text-black cursor-pointer" @click="findPrevious" />
            <IconHawkChevronDown class="w-4 h-4 ml-2 text-black cursor-pointer" @click="findNext" />
          </template>
          <IconHawkXClose class="w-4 h-4 ml-2 text-black cursor-pointer" @click="closeSearch(true)" />
        </div>
      </template>
    </TextElement>
  </Vueform>
</template>
