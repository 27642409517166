<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { useLayouts } from '~/project-management/composables/pm-layouts.composable';
import PmEditView from '~/project-management/components/pm-edit-view.vue';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();

const { set_active_view } = project_management_store;
const { is_pm_loading, pm_loading_message } = storeToRefs(project_management_store);

const { setLayout } = useLayouts();

const new_view_modal = useModal({
  component: PmEditView,
  attrs: {
    onClose() {
      new_view_modal.close();
    },
  },
});

const state = reactive({
  search_text: '',
  hovering_over_uid: '',
  view_value: {
    view: null,
  },
});

const views = computed(() => project_management_store?.views);
const active_view = computed(() => project_management_store?.active_view);
const views_menu_items = computed(() => {
  const lower_case_search_text = state.search_text.toLowerCase();
  return views.value
    .filter(view => view.data.label?.toLowerCase().includes(lower_case_search_text))
    .map(view => ({ ...view, value: view.uid, label: view.data?.label ?? view.uid }));
});
const is_an_exact_match_found = computed(() => {
  const lower_case_search_text = state.search_text.toLowerCase();
  return views.value.some(view => view.data.label?.toLowerCase() === lower_case_search_text);
});
const is_create_option_visible = computed(() => {
  return !is_an_exact_match_found.value && state.search_text.length;
});

function selectView(view_uid) {
  pm_loading_message.value = `${$t('Switching view')}...`;
  is_pm_loading.value = true;
  setTimeout(() => {
    set_active_view(view_uid);
    state.search_text = '';
    is_pm_loading.value = false;
    pm_loading_message.value = '';
  }, 100);
}

function editView(mode, label, view_data = {}) {
  new_view_modal.patchOptions({ attrs: { mode, label, view_data } });
  new_view_modal.open();
}

watch(() => active_view.value?.uid, () => {
  setLayout();
});
</script>

<template>
  <Vueform v-model="state.view_value" size="sm">
    <SelectElement
      :key="active_view.uid"
      :default="active_view.uid"
      :items="views_menu_items"
      :search="true"
      :native="false"
      :add-class="{
        select: {
          options: '!p-0 overflow-y-scroll',
          option: '!p-0 !bg-white',
          container: '!border-transparent',
          dropdown: '!-bottom-1 w-60 !-left-16',
        },
      }"
      :remove-class="{
        select: {
          dropdown: 'overflow-y-scroll',
        },
      }"
      :override-class="{
        select: {
          container_focused: 'ring-0',
          containerActive: 'vueform-input-bottom-underline-focused',
        },
      }"
      :can-clear="false"
      :can-deselect="false"
      name="view"
      class="w-44"
      input-type="search"
      autocomplete="off"
      @search-change="state.search_text = $event"
      @change="selectView($event)"
    >
      <template #single-label>
        <div class="flex items-center w-full px-3">
          <span class="text-xs font-regular text-gray-600">
            {{ $t("View") }}:&nbsp;
          </span>
          <span v-if="active_view.uid === '__default'" class="text-xs font-medium text-gray-900">
            {{ $t('Default') }}
          </span>
          <span v-else class="text-xs font-medium text-gray-900">
            {{ active_view.data.label }}
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <div
          class="flex justify-between items-center w-full py-3 h-[40px] text-gray-700 hover:text-gray-700"
          @mouseenter="state.hovering_over_uid = option.uid !== '__default' ? option.uid : ''"
          @mouseleave="state.hovering_over_uid = ''"
        >
          <span v-if="option.uid === '__default'" class="text-sm font-medium">
            {{ $t('Default') }}
          </span>
          <span v-else class="text-sm font-medium">
            {{ option.data.label }}
          </span>
          <span class="flex items-center">
            <div
              v-if="option.uid === state.hovering_over_uid"
              class="flex items-center ml-auto rounded-full hover:bg-gray-200 p-1.5"
              @click.stop="editView('edit', option.data.label, option)"
            >
              <IconHawkEdit class="text-primary w-4 h-4" />
            </div>
            <IconHawkCheck v-if="active_view.uid === option.uid" class="text-primary-500 cursor-pointer ml-1 w-5 h-5" />
          </span>
        </div>
      </template>
      <template v-if="is_create_option_visible" #before-list>
        <div
          class="hover:bg-gray-50 cursor-pointer text-sm rounded-lg font-semibold text-gray-700 p-2 m-1 break-words"
          @click="editView('add', state.search_text)"
        >
          {{ $t("Create view") }} "{{ state.search_text }}"
        </div>
        <div class="border-b border-solid border-gray-200" />
      </template>
      <template #no-options>
        <div class="text-sm font-regular px-2 pb-2 m-1">
          {{ $t("No matches") }}
        </div>
      </template>
    </SelectElement>
  </Vueform>
</template>
