<script setup>
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useModal } from 'vue-final-modal';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useChatStore } from '~/common/stores/chat.store.js';
import { highlightElement, waitForElement } from '~/common/utils/common.utils';
import HawkDatePickerModal from '~/common/components/organisms/hawk-datepicker-modal.vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

dayjs.extend(isBetween);

const $t = inject('$t');
const $date = inject('$date');

const project_management_store = useProjectManagementStore();
const auth_store = useAuthStore();
const chat_store = useChatStore();
const { set_active_task_uid } = project_management_store;
const { $g, active_schedule, active_schedule_data, pm_comments, is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  all_comments: [],
  filtered_comments: [],
  custom_range: [],
  left_menu_selection: 'all_comments',
  right_menu_selection: 'all_time',
});

const date_range_modal = useModal({
  component: HawkDatePickerModal,
  attrs: {
    options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
    onClose() {
      state.right_menu_selection = 'all_time';
      date_range_modal.close();
    },
    onOkay(range) {
      state.custom_range = range;
      state.filtered_attachments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isBetween(dayjs(range[0]), dayjs(range[1]), 'day', '[]'));
      date_range_modal.close();
    },
  },
});

const left_menu_items = computed(() => {
  return [
    {
      label: $t('All comments'),
      uid: 'all_comments',
      on_click: () => {
        state.filtered_comments = state.all_comments;
        state.left_menu_selection = 'all_comments';
      },
    },
    {
      label: $t('Mentions'),
      uid: 'mentions',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment =>
          comment.all_messages.some(message =>
            message.mentioned_user_list?.map?.(item => item.uid)?.includes?.(auth_store?.logged_in_user_details?.user_id),
          ),
        );
        state.left_menu_selection = 'mentions';
      },
    },
    {
      label: $t('Assigned to me'),
      uid: 'assigned_to_me',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter((comment) => {
          return $g.value.getTask(comment.id)?.assignees?.includes?.(auth_store?.logged_in_user_details?.user_id);
        });
        state.left_menu_selection = 'assigned_to_me';
      },
    },
  ];
});

const right_menu_items = computed(() => {
  return [
    {
      label: $t('All time'),
      uid: 'all_time',
      on_click: () => {
        state.filtered_comments = state.all_comments;
        state.right_menu_selection = 'all_time';
      },
    },
    {
      label: $t('Today'),
      uid: 'today',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isSame(dayjs(), 'day'));
        state.right_menu_selection = 'today';
      },
    },
    {
      label: $t('Yesterday'),
      uid: 'yesterday',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isSame(dayjs().subtract(1, 'day'), 'day'));
        state.right_menu_selection = 'yesterday';
      },
    },
    {
      label: $t('This week'),
      uid: 'this_week',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isSame(dayjs(), 'week'));
        state.right_menu_selection = 'this_week';
      },
    },
    {
      label: $t('Last week'),
      uid: 'last_week',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isSame(dayjs().subtract(1, 'week'), 'week'));
        state.right_menu_selection = 'last_week';
      },
    },
    {
      label: $t('This month'),
      uid: 'this_month',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isSame(dayjs(), 'month'));
        state.right_menu_selection = 'this_month';
      },
    },
    {
      label: $t('Last month'),
      uid: 'last_month',
      on_click: () => {
        state.filtered_comments = state.all_comments.filter(comment => dayjs(comment.recent_message.updated_at).isSame(dayjs().subtract(1, 'month'), 'month'));
        state.right_menu_selection = 'last_month';
      },
    },
    {
      label: $t('Custom'),
      uid: 'custom',
      on_click: () => {
        state.right_menu_selection = 'custom';
        date_range_modal.open();
      },
    },
  ];
});

const more_comments = computed(() => {
  const cnt = state.filtered_comments.reduce((acc, item) => acc + item.all_messages.length, 0);
  return cnt;
});

async function onClick(item) {
  set_active_task_uid(item.uid);
  try {
    await waitForElement('comments').then(async (element) => {
      setTimeout(() => {
        highlightElement(
          element,
          ['!-ml-3', '!pl-3', '!-mr-3', '!pr-5'],
          { behavior: 'smooth', block: 'end', inline: 'nearest' },
        );
      }, 200);
    });
  }
  catch (error) {
    logger.error('Error while waiting for comments element', error);
  }
}

watchEffect(() => {
  if (props.active === true) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);

    try {
      state.all_comments = pm_comments.value;

      state.all_comments = state.all_comments.map((comment) => {
        const current_activity_attachments = active_schedule.value.activities[comment.uid].attachments.filter(attachment => comment.recent_message.attachment_uids?.includes?.(attachment.uid));
        comment.recent_message.attachments = current_activity_attachments.slice(0, 3);
        if (current_activity_attachments.length > 3)
          comment.recent_message.trimmed_attachments = current_activity_attachments.length - 3;
        return comment;
      });

      state.filtered_comments = state.all_comments;
    }
    catch (error) {
      logger.error(error);
      state.all_comments = [];
      state.filtered_comments = [];
    }
  }
});

watch(() => props.active, () => {
  if (props.active) {
    state.left_menu_selection = 'all_comments';
    state.right_menu_selection = 'all_time';
  }
});
</script>

<template>
  <Teleport :to="is_fullscreen ? '#pm-fullscreen-container' : 'body'">
    <Transition name="sidebar-slide">
      <div v-if="active" class="slide-over fixed bg-white right-0 inset-y-0 z-[1000] w-[30vw]">
        <div class="relative flex flex-col flex-1 h-full bg-white shadow-xl">
          <div class="flex items-center justify-between mx-6 my-4">
            <div class="font-semibold text-gray-900 text-md">
              {{ $t('Comments') }}
            </div>
            <div class="ml-auto">
              <HawkButton icon type="text" class="!h-10 !w-10 ml-1" @click="emit('close')">
                <IconHawkXClose class="text-gray-500" />
              </HawkButton>
            </div>
          </div>
          <hr class="border-gray-300">
          <div class="px-6 pt-4 scrollbar">
            <div class="flex justify-between mb-2 -mx-3">
              <HawkMenu
                class="!border-0"
                :items="left_menu_items"
                additional_trigger_classes="!ring-0"
                additional_dropdown_classes="w-[200px]"
                position="fixed"
              >
                <template #trigger="{ open }">
                  <HawkButton type="text" size="xs">
                    {{ left_menu_items.find((menu_item) => menu_item.uid === state.left_menu_selection).label }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
              <HawkMenu
                class="!border-0"
                :items="right_menu_items"
                additional_trigger_classes="!ring-0"
                additional_dropdown_classes="w-[200px]"
                position="fixed"
              >
                <template #trigger="{ open }">
                  <HawkButton
                    v-tippy="state.right_menu_selection === 'custom' ? `${$date(state.custom_range[0], 'YYYY-MM-DD')} ${$t('to')} ${$date(state.custom_range[1], 'YYYY-MM-DD')}` : ''"
                    type="text"
                    size="xs"
                  >
                    {{ right_menu_items.find((menu_item) => menu_item.uid === state.right_menu_selection).label }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
            </div>
            <HawkIllustrations
              v-if="!state.filtered_comments?.length"
              type="no-data"
              for="comments"
            />
            <template v-else>
              <div v-for="(comment, i) in state.filtered_comments" :key="comment" class="">
                <div class="mb-2 text-sm font-semibold text-gray-400 cursor-pointer hover:text-gray-600 hover:underline hover:underline-offset-4" @click="onClick(comment)">
                  #{{ comment.wbs }} • {{ comment.text }}
                </div>
                <div class="flex">
                  <div class="flex items-center h-8">
                    <div v-if="comment.recent_message.is_unread" class="!w-2 !h-2 bg-red-500 rounded-lg absolute left-2" />
                  </div>
                  <HawkMembers
                    :members="comment.recent_message.user.id"
                    :has_avatar="true"
                    size="sm"
                    class="flex !items-start mr-3"
                  />
                  <div class="flex flex-col w-full">
                    <div class="flex items-center justify-between">
                      <HawkMembers
                        :members="comment.recent_message.user.id"
                        type="label"
                        :has_avatar="false"
                        name_classes="text-gray-900 text-sm font-semibold"
                      />
                      <div class="text-xs text-gray-600 font-regular">
                        {{ $date(comment.recent_message.updated_at, 'DD MMM, YYYY hh:mma') }}
                      </div>
                    </div>
                    <div class="text-sm text-gray-700 font-regular">
                      {{ DOMPurify.sanitize(comment.recent_message.text, { ALLOWED_TAGS: [] }) }}
                    </div>
                    <div class="flex mt-1 gap-x-2">
                      <div v-for="(item, index) in comment.recent_message.attachments" :key="index">
                        <img
                          v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
                          class="object-cover rounded-lg w-[32px] h-[32px]"
                          alt="thumbnail"
                        >
                        <div
                          v-else
                          class="flex items-center justify-center bg-gray-100 rounded-lg w-[32px] h-[32px]"
                        >
                          <HawkFileIcon :file_name="item.file_name" />
                        </div>
                      </div>
                      <div v-if="comment.recent_message?.trimmed_attachments" class="flex items-center text-sm text-gray-700 font-regular">
                        +{{ comment.recent_message?.trimmed_attachments }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="comment.all_messages.length - 1 > 0"
                  class="flex items-center gap-1 pt-2 text-sm font-semibold cursor-pointer text-primary-700 w-fit"
                  @click="onClick(comment)"
                >
                  {{ comment.all_messages.length - 1 }} {{ $t('more') }}
                  <IconHawkChevronRight class="inline" />
                </div>
                <hr v-if="i < state.filtered_comments.length - 1" class="my-4 -mx-6 border-b-2 border-gray-100">
              </div>
            </template>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.sidebar-slide-enter-active,
.sidebar-slide-leave-active {
  transition: transform 0.5s ease;
}

.sidebar-slide-enter-from,
.sidebar-slide-leave-to {
  transform: translateX(100%);
}
</style>
