<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useAttachments } from '~/project-management/composables/pm-attachments.composable.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import ChatCommentInput from '~/common/components/organisms/chat-comment-input.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'comment',
    validator: val => ['comment', 'reply'].includes(val),
  },
  activity_uid: {
    type: String,
    required: true,
  },
  comment: {
    type: Object,
    default: null,
  },
  chat_store: {
    type: Object,
    required: true,
  },
  comment_input_config: {
    type: Object,
    default: () => ({}),
  },
  user: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'close',
  'input',
  'reply',
  'send',
  'showMoreOptions',
]);

const $track_event = inject('$track_event');
const route = useRoute();

const project_management_store = useProjectManagementStore();
const { addAttachmentsToActivity } = useAttachments();

const { is_fullscreen } = storeToRefs(project_management_store);

const asset_id = computed(() => {
  return route.params.asset_id;
});

const append_mentions_to = computed(() => {
  return is_fullscreen.value
    ? document?.getElementById?.('pm-fullscreen-container')
    : null;
});

async function onAddAttachmentsClicked() {
  if (is_fullscreen.value) {
    await document.exitFullscreen();
    await new Promise(resolve => setTimeout(resolve, 10));
  }
}

async function uploadAttachments({ attachments }) {
  addAttachmentsToActivity(attachments);
}
</script>

<template>
  <ChatCommentInput
    :comment_input_config="comment_input_config"
    :type="type"
    :comment="comment"
    :chat_store="chat_store"
    :asset_id="asset_id"
    :user="user"
    :attachment_config="{ meta: { service: 'pm', id: activity_uid } }"
    :append_mentions_to="append_mentions_to"
    :add_attachments_click="onAddAttachmentsClicked"
    module="pm"
    @uploadAttachments="uploadAttachments"
    @commentSent="emit('send')"
    @input="$event => emit('input', $event)"
    @showMoreOptions="emit('showMoreOptions')"
    @close="emit('close')"
    @reply="emit('reply')"
    @keyup.enter.prevent.stop
  >
    <template #more_options>
      <slot name="more_options" />
    </template>
  </ChatCommentInput>
</template>
