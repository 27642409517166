<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  active_schedule: {
    type: Object,
  },
});

const emit = defineEmits(['close']);
const project_management_store = useProjectManagementStore();
const { active_schedule, schedules } = storeToRefs(project_management_store);
const { update_active_schedule } = project_management_store;

const form$ = ref(null);

const state = reactive({
  is_loading: false,
});

const computed_active_schedule = computed(() => {
  if (props?.active_schedule)
    return props.active_schedule;
  return active_schedule.value;
});

async function onRename() {
  state.is_loading = true;

  await update_active_schedule(
    computed_active_schedule.value.uid,
    { name: form$.value.data.schedule_name.trim() },
    true,
  );
  schedules.value = schedules.value.map((item) => {
    if (item.uid === computed_active_schedule.value.uid)
      return { ...item, name: form$.value.data.schedule_name.trim() };
    else
      return item;
  });
  emit('close');
  state.is_loading = false;
}
</script>

<template>
  <HawkModalContainer>
    <Vueform
      ref="form$"
      size="sm"
      class="max-w-[600px]"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              {{ $t("Rename") }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <TextareaElement
            :default="computed_active_schedule.name"
            name="schedule_name"
            class="w-full"
            :label="$t('Name')"
            @keydown.enter="onRename()"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="rename"
                :loading="state.is_loading"
                @click="onRename()"
              >
                {{ $t('Rename') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
